/* eslint-disable prettier/prettier */
/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Entry file.
 */
import {createMuiTheme} from '@material-ui/core/styles/';
import {ThemeProvider} from '@material-ui/styles';
import AWSAmplifyEvents from 'Components/stateful/AmplifyAuth';
import {theme} from 'Config/theme';
import {routerMiddleware} from 'connected-react-router';
import rootEpic from 'Epics/root';
import {createBrowserHistory} from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import createRootReducer from 'Reducers/root';
import {applyMiddleware, compose, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {createEpicMiddleware} from 'redux-observable';
import {getRoutes} from 'Routes/root';
import {dependencies} from 'Contexts/dependencyContext';

const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware({
    dependencies: dependencies
});

// use this version in createStore if you want to enable tracing
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ 
    trace: true, 
    traceLimit: 25 
}) || compose; 

const store = createStore(
    createRootReducer(history),
    undefined,
    composeWithDevTools(applyMiddleware(epicMiddleware, routerMiddleware(history)))
    //composeEnhancers(applyMiddleware(epicMiddleware, routerMiddleware(history)))
);

epicMiddleware.run(rootEpic);

ReactDOM.render(
    <div className="awsui">
        <ThemeProvider theme={createMuiTheme(theme)}>
            <Provider store={store}>
                <AWSAmplifyEvents>
                    {getRoutes(store, history)}
                </AWSAmplifyEvents>
            </Provider>
        </ThemeProvider>
    </div>,
    document.getElementById('app')
);
