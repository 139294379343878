/* eslint-disable prettier/prettier */
import React, { useRef, useEffect } from 'react';


export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}