import * as React from 'react';
import AWS from 'aws-sdk';
import config from '../config/aws';

export const dependencies: any = {
    mode: process.env.NODE_ENV || 'development',
    config: config,
    AWS: AWS
};

export const DependencyContext = React.createContext(dependencies);
