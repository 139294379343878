/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Renders the landing page.
 */
import React from 'react';
import Container from '@aws-prototyping/ui/components/Container';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { ApplicationState } from 'Actions/definition/root';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import FormComponentFactory from 'Utils/FormComponentFactory';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAdmissionSavedAction } from 'Actions/creator/ui';
import { putSubjectAction } from 'Actions/creator/formData';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import { offsetPreservingSortedObservations } from 'util/controlHelpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            overflowX: 'auto'
        },
        table: {},
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary
        }
    })
);

// builds a collection of expansion panels, each containing
// one day of daily observations fields

const DailyObservations = (classes, formRenderData, formData) => {
    const result = formData.eot_daily.questions.length == 0 ? [<div>none recorded</div>] : [];

    const obs = offsetPreservingSortedObservations(formData.eot_daily?.questions);

    for (let i = 0; i < obs.length; i++) {
        const observationControls = FormComponentFactory.controlsForInstrumentQuestions(
            'eot_daily',
            obs[i].index,
            formRenderData.eot_daily.questions,
            true
        );

        result.push(
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Day {i + 1} Observations</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TableContainer component={Paper} elevation={0}>
                        <Table className={classes.table} size="small" aria-label="Daily Observations">
                            <TableBody>
                                {observationControls.map((item, idx) => (
                                    <TableRow key={idx}>{item}</TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    return result;
};

const ReviewSubject = () => {
    const formData = useSelector((state: any) => state.formData);
    const formRenderData = useSelector((state: any) => state.formRenderData);
    const classes = useStyles({});
    const admissionControls = FormComponentFactory.controlsForInstrumentQuestions(
        'eot_icu_admis',
        -1,
        formRenderData.eot_icu_admis.questions,
        true
    );
    const mechVentilationControls = FormComponentFactory.controlsForInstrumentQuestions(
        'eot_start_mech_vent',
        -1,
        formRenderData.eot_start_mech_vent.questions,
        true
    );
    const ecmoVentilationControls = FormComponentFactory.controlsForInstrumentQuestions(
        'eot_start_ecmo',
        -1,
        formRenderData.eot_start_ecmo.questions,
        true
    );
    const outcomeControls = FormComponentFactory.controlsForInstrumentQuestions(
        'eot_final',
        -1,
        formRenderData.eot_final.questions,
        true
    );

    // @ts-ignore
    return (
        <div>
            <Container title={'Review - Subject - “' + `${formData.SubjectId}` + '”'}>
                <Container title={'Admission Details'}>
                    {Object.keys(formData.eot_icu_admis.questions).length == 0 ? (
                        'none recorded'
                    ) : (
                        <TableContainer component={Paper} elevation={0}>
                            <Table className={classes.table} size="small" aria-label="Admission Details">
                                <TableBody>
                                    {admissionControls.map((item, idx) => (
                                        <TableRow key={idx}>{item}</TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Container>
                <Container title={'Mechanical Ventilation Commencement Details'}>
                    {Object.keys(formData.eot_start_mech_vent.questions).length == 0 ? (
                        'none recorded'
                    ) : (
                        <TableContainer component={Paper} elevation={0}>
                            <Table className={classes.table} size="small" aria-label="Mech Ventilation Details">
                                <TableBody>
                                    {mechVentilationControls.map((item, idx) => (
                                        <TableRow key={idx}>{item}</TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Container>
                <Container title={'ECMO Ventilation Commencement Details'}>
                    {Object.keys(formData.eot_start_mech_vent.questions).length == 0 ? (
                        'none recorded'
                    ) : (
                        <TableContainer component={Paper} elevation={0}>
                            <Table className={classes.table} size="small" aria-label="ECMO Ventilation Details">
                                <TableBody>
                                    {ecmoVentilationControls.map((item, idx) => (
                                        <TableRow key={idx}>{item}</TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Container>
                <Container title={'Daily Observations'}>
                    {DailyObservations(classes, formRenderData, formData)}
                </Container>
                <Container title={'Outcome Details'}>
                    {Object.keys(formData.eot_final.questions).length == 0 ? (
                        'none recorded'
                    ) : (
                        <TableContainer component={Paper} elevation={0}>
                            <Table className={classes.table} size="small" aria-label="Outcome Details">
                                <TableBody>
                                    {outcomeControls.map((item, idx) => (
                                        <TableRow key={idx}>{item}</TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Container>
            </Container>
        </div>
    );
};

export default ReviewSubject;
