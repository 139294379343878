/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, Dispatch } from 'react';
import { ReactReduxContext, useSelector } from 'react-redux'
import { Question } from 'Actions/definition/formRenderData'
import * as _ from 'lodash'

export const useStore = (name: string, instrument: string) => {
  const selector = useSelector((state: any): Question => {
    const parentId = state.formRenderData[instrument].id
    const parentName = instrument
    const question = _(state.formRenderData[instrument]['questions']).find({ name: name })
    return { ...question, parentId: parentId, parentName: parentName }
  })
  const { store } = useContext(ReactReduxContext);
  const { getState, dispatch, subscribe } = store;
  const [storeState, setStoreState] = React.useState<Question | null>(selector);

  useEffect(() => subscribe(() => setStoreState(selector)));
  return [storeState, dispatch]
}