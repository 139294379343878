/* eslint-disable @typescript-eslint/camelcase */
import { Auth } from 'aws-amplify';

/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Config parameters related to AWS Cognito & Pinpoint resources.
 */
export default {
    customer: {
        name: 'Tonic'
    },
    auth: {
        identityPoolId: 'ap-southeast-2:62b9043e-1c8b-4da9-8555-83f151c8235d',
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_6iPBpbXs6',
        userPoolWebClientId: '4f1mmgsrcjgvm19nuu3mhvct6',
        jwtToken: async () => {
            const session = await Auth.currentSession();
            return session.idToken.jwtToken;
        },
        idToken: ''
    },
    pinPoint: {
        appId: 'f9a4f6bd13774fd0bc2a93319847d5f5',
        region: 'ap-southeast-2'
    },
    api: {
        endPoint: 'https://nq80g7q5x8.execute-api.ap-southeast-2.amazonaws.com/Prod/covid-subject'
    },
    region: 'ap-southeast-2'
};
