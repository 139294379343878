/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * ALl supported Log action types.
 */

export enum LogActions {
    GET_CLOUDWATCH_LOG_GROUPS = 'GET_CLOUDWATCH_LOG_GROUPS',
    GET_CLOUDWATCH_LOG_GROUPS_SUCCESS = 'GET_CLOUDWATCH_LOG_GROUPS_SUCCESS',
    GET_CLOUDWATCH_LOG_GROUPS_FAILURE = 'GET_CLOUDWATCH_LOG_GROUPS_FAILURE',
    GET_CLOUDWATCH_LOG_EVENTS = 'GET_CLOUDWATCH_LOG_EVENTS',
    GET_CLOUDWATCH_LOG_EVENTS_SUCCESS = 'GET_CLOUDWATCH_LOG_EVENTS_SUCCESS',
    GET_CLOUDWATCH_LOG_EVENTS_FAILURE = 'GET_CLOUDWATCH_LOG_EVENTS_FAILURE'
}

export interface CloudwatchLogStream {
    creationTime: number;
    arn: string;
    logStreamName: string;
    storedBytes: number;
}

export interface CloudwatchLogGroup extends AWS.CloudWatchLogs.DescribeLogGroupsResponse {
    logStreams?: CloudwatchLogStream[];
}

export type CloudwatchLogGroups = CloudwatchLogGroup[];

export interface LogGroups {
    cloudWatch?: CloudwatchLogGroups;
}

/**
 * A single cloudwatch log event
 */
export interface CloudwatchLogEvent {
    timestamp: number;
    message: string;
    ingestionTime: number;
}

/**
 * A collection of cloudwatch log events
 */
export interface CloudwatchLogEvents {
    err: Error;
    data: {
        events: CloudwatchLogEvent[];
        nextForwardToken: string;
        nextBackwardToken: string;
    };
}

/**
 * Action definition to get log groups
 */
export interface GetCloudwatchLogGroupAction {
    type: typeof LogActions.GET_CLOUDWATCH_LOG_GROUPS;
    payload: any;
}

/**
 * Action definition for a failure to get log groups
 */
export interface GetCloudwatchLogGroupSuccessAction {
    type: typeof LogActions.GET_CLOUDWATCH_LOG_GROUPS_SUCCESS;
    payload: any;
}

/**
 * Action definition for a failure to get log groups
 */
export interface GetCloudwatchLogGroupFailureAction {
    type: typeof LogActions.GET_CLOUDWATCH_LOG_GROUPS_FAILURE;
    payload: any;
}

/**
 * Action definition to get log events
 */
export interface GetCloudwatchLogEventsAction {
    type: typeof LogActions.GET_CLOUDWATCH_LOG_EVENTS;
    payload: any;
}

/**
 * Action definition for success log events
 */
export interface GetCloudwatchLogEventsSuccessAction {
    type: typeof LogActions.GET_CLOUDWATCH_LOG_EVENTS_SUCCESS;
    payload: any;
}

/**
 * Action definition for failed get log events
 */
export interface GetCloudwatchLogEventsFailureAction {
    type: typeof LogActions.GET_CLOUDWATCH_LOG_EVENTS_FAILURE;
    payload: any;
}

/**
 * Parameters to pass to AWS.cloudwatch.GetLogEvents
 */
export interface CloudwatchLogEventsParams {
    logGroupName: string;
    logStreamName: string;
    endTime?: number;
    limit?: number;
    nextToken?: string;
    startFromHead?: boolean;
    startTime?: number;
}

/**
 * All User Actions can be 'subclassed' to LogActionTypes.
 */
export type LogActionTypes =
    | GetCloudwatchLogGroupAction
    | GetCloudwatchLogGroupFailureAction
    | GetCloudwatchLogGroupSuccessAction
    | GetCloudwatchLogEventsAction
    | GetCloudwatchLogEventsSuccessAction
    | GetCloudwatchLogEventsFailureAction;
