/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Themes to use throughout the site.
 */
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

/**
 * Theme used by the amplify auth component on the login page.
 */
export const authTheme = {
    a: { color: '#FF9900' },
    button: { backgroundColor: '#FF9900' },
    formSection: {
        fontFamily: '"Amazon Ember", "Roboto", "Helvetica", "Arial", sans-serif',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)'
    },
    navButton: { backgroundColor: '#FF9900' },
    navItem: { fontWeight: 'bold' },
    navRight: {
        background: 'url(/img/logo-black.png) no-repeat',
        backgroundSize: '48px'
    },
    sectionHeader: {
        content: "url('/img/logo-black.png')",
        width: '200px'
    }
};

/**
 * Material-UI theme.
 *
 * Refer to: https://material-ui.com/customization/theming/
 */
export const theme: ThemeOptions = {
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: { xs: 0, lg: 1280, sm: 600, xl: 1920, md: 960 }
    },
    direction: 'ltr',
    mixins: {
        toolbar: {
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 48
            },
            '@media (min-width:600px)': { minHeight: 64 },
            minHeight: 56
        }
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '2px'
            }
        },
        MuiLink: {
            root: {
                color: '#0073bb'
            }
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        MuiMenuItem: {
            root: {
                border: '1px solid transparent',
                '&:hover': {
                    backgroundColor: '#f2f3f3',
                    border: '1px solid #879596'
                }
            }
        },
        MuiButton: {
            root: {
                fontSize: '.75rem'
            },
            text: {
                padding: 0
            }
        },
        MuiCheckbox: {
            root: {
                padding: 0
            }
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            label: {
                paddingRight: '.5rem'
            }
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            },
            root: {
                paddingTop: 4,
                paddingBottom: 4
            }
        },
        MuiTabs: {
            root: {
                minHeight: 'auto'
            },
            scroller: {
                borderBottom: '1px solid #aab7b8',
                padding: '.75rem 0'
            }
        },
        MuiTab: {
            root: {
                minHeight: 'auto',
                minWidth: 'auto',
                lineHeight: 'none',
                padding: '0 1rem',
                textTransform: 'none',
                '@media (min-width: 600px)': {
                    minWidth: 'auto'
                }
            },
            wrapper: {
                fontWeight: 700,
                fontSize: '.75rem'
            }
        },
        MuiInputLabel: {
            shrink: {
                transform: 'translate(0, 1.5px)'
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '.75rem',
                fontWeight: 'normal'
            },
            input: {
                fontSize: '.75rem',
                padding: '.2rem'
            }
        },
        MuiTableCell: {
            root: {
                fontSize: '.75rem',
                borderBottom: 'none',
                fontWeight: 'normal'
            },
            head: {
                fontWeight: 700,
                lineHeight: 'normal',
                userSelect: 'none'
            },
            sizeSmall: {
                padding: '9px 12px'
            }
        },
        MuiTableRow: {
            root: {
                '&.Mui-selected': {
                    border: '1px solid #0073bb',
                    backgroundColor: '#f1faff'
                },
                border: '1px solid transparent',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                width: 'auto !important'
            },
            head: {
                backgroundColor: '#fafafa'
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: 0,
                marginBottom: 0
            }
        }
        // MuiGrid: {
        //     container: {
        //         marginTop: '15px !important'
        //     }
        // }
    },
    palette: {
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            hover: 'rgba(0, 0, 0, 0.08)',
            hoverOpacity: 0.08,
            selected: 'rgba(0, 0, 0, 0.14)'
        },
        background: { paper: '#fff', default: '#f2f3f3' },
        common: { black: '#000', white: '#fff' },
        contrastThreshold: 3,
        divider: 'rgba(0, 0, 0, 0.12)',
        error: {
            contrastText: '#fff',
            dark: '#d32f2f',
            light: '#e57373',
            main: '#f44336'
        },
        grey: {
            '100': '#f5f5f5',
            '200': '#eeeeee',
            '300': '#e0e0e0',
            '400': '#bdbdbd',
            '50': '#fafafa',
            '500': '#9e9e9e',
            '600': '#757575',
            '700': '#616161',
            '800': '#424242',
            '900': '#212121',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161'
        },
        primary: {
            contrastText: '#fff',
            dark: 'rgb(53, 53, 53)',
            light: 'rgb(111, 111, 111)',
            main: '#232F3E'
        },
        secondary: {
            contrastText: '#fff',
            dark: 'rgb(154, 30, 0)',
            light: 'rgb(227, 86, 51)',
            main: '#EB5F07'
        },
        text: {
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)'
        },
        tonalOffset: 0.2,
        type: 'light'
    },
    props: {
        MuiButtonBase: {
            disableRipple: true // No more ripple, on the whole application
        },
        MuiListItem: {
            disableGutters: true
        }
    },
    shadows: [
        'none',
        '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
        '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
        '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
        '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
        '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
        '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
        '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
        '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
        '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
        '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
        '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
        '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
        '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
        '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
        '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
        '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
        '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
        '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
    ],
    shape: { borderRadius: 4 },
    spacing: 8,
    transitions: {
        duration: {
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
            short: 250,
            shorter: 200,
            shortest: 150,
            standard: 300
        },
        easing: {
            easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
            sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
        }
    },
    typography: {
        body1: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: '"Amazon Ember", "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.4
        },
        body2: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: '"Amazon Ember", "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 1.4
        },
        button: {
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: '"Amazon Ember", "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'uppercase'
        },
        caption: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontFamily: '"Amazon Ember", "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '12px',
            fontWeight: 400
        },
        fontFamily: '"Amazon Ember", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightRegular: 400
    },
    zIndex: {
        appBar: 1100,
        drawer: 1200,
        mobileStepper: 1000,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500
    }
};
