/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import userReducer from 'Reducers/user';
import { combineReducers } from 'redux';
import { formRenderDataReducer } from 'Reducers/formRenderData';
import { formDataReducer } from 'Reducers/formData';
import { uiReducer } from 'Reducers/ui';

/**
 * Root reducer.
 *
 * @param history history object to use when constructing the router.
 */
export default (history: History) =>
    combineReducers({
        router: connectRouter(history),
        user: userReducer,
        formRenderData: formRenderDataReducer,
        formData: formDataReducer,
        ui: uiReducer
    });
