/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'Utils/useStore'
import { usePrevious } from 'Utils/usePrevious'
import * as _ from 'lodash'
import { useSelector } from 'react-redux';
import { isDisabled, setIfExits, helperText } from 'Utils/controlHelpers'

const DEBOUNCE_TIME = 250;
const INPUT_TYPE = "value"

export interface EcmoDataProps extends MuiTextFieldProps {
  instrument: string;
  instrumentDataOffset: integer;
  name: string;
}

import TableCell from '@material-ui/core/TableCell';
import { initialState } from 'Reducers/formData';
import { modifySubjectAction } from "Actions/creator/ui";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  labelFocused: {},
  labelRoot: {
    color: "black",
    "&$labelFocused": {
      color: "blue"
    }
  },
  inputInput: {
    paddingTop: 10
  }

}));

export const NumberInput: React.FC<EcmoDataProps> = (props) => {

  const classes = useStyles();

  const answer = useSelector(
    state => (props.instrument in state.formData) ? 
      (props.instrumentdataoffset > -1 ? 
        state.formData[props.instrument]['questions'][props.instrumentdataoffset][props.name]
        : state.formData[props.instrument]['questions'][props.name]
      ) 
    : ''
  );

  const [question, dispatch] = useStore(props.name, props.instrument);

  const { name, data, parentId, id, label, units } = question;
  const { instrument } = props;
  const { review } = props;

  const [value, setValue] = useState(answer || data?.default || undefined)
  const prevValue = usePrevious(value)
  const [errorText, setErrorText] = React.useState<string | null>()
  const [error, setError] = useState<boolean>(false)
  const [hasSetIfExists, setHasSetIfExists] = useState<boolean>(false)



  useEffect(() => {
    if (prevValue !== value && !review) {
      dispatch({
        type: 'UPDATE',
        data: { name, value, instrument, instrumentdataoffset: props.instrumentdataoffset }
      })
      
      value && setIfExits(question, hasSetIfExists, setHasSetIfExists, dispatch, props.instrument)
    }

  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    dispatch(modifySubjectAction());

    _.debounce((e, data, units) => {
      const newValue = parseFloat(e.target.value);

      if (_(data).hasIn('validation')) {
        const { min, max } = data.validation

        if (_(newValue).inRange(min, max)) {
          setError(false)
          setValue(newValue.toString())
        } else {
          setError(true)
          setErrorText(`Please enter a value between ${min} ${units || ''} and ${max} ${units || ''}`)
        }
      } else {
        setValue(newValue.toString())
      }
    }, DEBOUNCE_TIME)(event, data, units)
  };


  return (
    review ? 
      <>
        <TableCell width="60%" component="th" scope="row" align="right">
        {`${parentId}.${id} ${label}`}
        </TableCell>
        <TableCell width="40%" align="left">{answer}</TableCell>
      </>
     :
    <FormControl
      component="fieldset"
      className={classes.root}
      disabled={isDisabled(question, answer)}
    >      
      <MuiTextField
        {...props}
        variant={"standard"}
        onChange={handleChange}
        label={`${parentId}.${id} ${label}`}
        placeholder="Enter observation here"
        required={data?.validation?.mustExist || false}
        defaultValue={answer || data?.default || undefined}
        type={INPUT_TYPE}
        InputProps={{
          endAdornment: units && <InputAdornment position="end">{units}</InputAdornment>,
          inputProps: {
            min: data?.validation?.min,
            max: data?.validation?.max,
            step: data?.validation?.step || 0.01,
          },
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
        }}
        error={error}

        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused
          }
        }}
        fullWidth
        margin="normal"
        helperText={helperText(question, error, errorText)}
      />
    </FormControl>
  )


}