/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Renders the header bar.
 */
import {makeStyles} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {connect} from 'react-redux';
import ButtonDropdown, {ButtonDropdownItem} from '@aws-prototyping/ui/components/ButtonDropdown';
import {ApplicationState} from 'Actions/definition/root';

const useStyles = makeStyles(theme => ({
    paddedChildren: {
        '& > *': {
            paddingLeft: theme.spacing(1)
        }
    },
    flexGrow: {
        flexGrow: 1
    }
}));

export interface HeaderProps {
    customerName: string;
    givenName: string;
    familyName: string;
    className?: string;
    menuItems: ButtonDropdownItem[];
}

const Header = ({ customerName, givenName, familyName, menuItems, className = '' }: HeaderProps) => {
    const classes = useStyles({});

    return (
        <AppBar className={className} position="static" elevation={0}>
            <Toolbar className={classes.paddedChildren}>
                <img alt="Logo" src="/img/logo-white.png" height="64px" />
                <Typography variant="h5" className={classes.flexGrow}>
                    {customerName}
                </Typography>
                <ButtonDropdown content={`${givenName} ${familyName}`} items={menuItems} />
            </Toolbar>
        </AppBar>
    );
};

Header.defaultProps = {
    customerName: 'Title',
    givenName: 'User',
    familyName: '',
    items: []
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        givenName: state.user.attributes ? state.user.attributes.given_name : '',
        familyName: state.user.attributes ? state.user.attributes.family_name : ''
    };
};

export default connect(mapStateToProps, null)(Header);
