/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * All User related action creator definitions.
 */

import {
    RefreshSignInAction,
    SignInAction,
    SignInFailureAction,
    SignOutAction,
    UserActions
} from 'Actions/definition/user';
import { Analytics } from 'aws-amplify';

/** pinpoint events */
const APPLICATION_AUTHENTICATION_EVENT = 'authentication';

/**
 * Emits an action indicating a successful sign in in.
 *
 * @param data payload.
 */
export const signInAction = (data: any): SignInAction => {
    Analytics.record({
        attributes: { action: UserActions.SIGNIN_SUCCESS, username: data.username },
        name: APPLICATION_AUTHENTICATION_EVENT
    });
    return { type: UserActions.SIGNIN_SUCCESS, payload: data };
};

/**
 * Emits an action indicating sign in in failure.
 *
 * @param data payload.
 */
export const signInFailureAction = (data: any): SignInFailureAction => {
    return { type: UserActions.SIGNIN_FAILURE, payload: data };
};

/**
 * Emits an action indicating successful sign in refresh.
 *
 * @param data payload.
 */
export const refreshSignInAction = (data: any): RefreshSignInAction => {
    Analytics.record({
        attributes: { action: UserActions.REFRESH_SIGNIN_SUCCESS, username: data.username },
        name: APPLICATION_AUTHENTICATION_EVENT
    });
    return { type: UserActions.REFRESH_SIGNIN_SUCCESS, payload: data };
};

/**
 * Emits an action indicating successful sign out.
 *
 * @param data payload.
 */
export const signOutAction = (data: any): SignOutAction => {
    Analytics.record({
        attributes: { action: UserActions.SIGNOUT_SUCCESS, username: data.username },
        name: APPLICATION_AUTHENTICATION_EVENT
    });
    return { type: UserActions.SIGNOUT_SUCCESS, payload: data };
};
