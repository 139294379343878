/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';
import * as _ from 'lodash';
import { useStore } from 'Utils/useStore';
import { isDisabled } from 'Utils/controlHelpers';

import TableCell from '@material-ui/core/TableCell';

import { initialState } from 'Reducers/formData';
import { modifySubjectAction } from "Actions/creator/ui";

export interface EcmoDataProps extends MuiSwitchProps {
  instrument: string;
  name: string;
}

const useStyles = makeStyles({
  root: {
    // marginLeft: '10px',
  },
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[300],
    }
  },
  formControl: {
  },
  formHelperText: {
    fontSize: '10px'
  },
  checked: {},
  track: {}
})

export const Switch: React.FC<EcmoDataProps> = (props) => {
  const classes = useStyles();

  // const answers = useSelector(state => state['formData'][props.instrument]['questions']);
  // const answer = answers[props.name]

  const answers = useSelector(
    state => (props.instrument in state.formData) ? 
      (props.instrumentdataoffset > -1 ? 
        state.formData[props.instrument]['questions'][props.instrumentdataoffset] 
        : state.formData[props.instrument]['questions']
      ) 
    : initialState[props.instrument]['questions']
  );
  const answer = (props.name in answers ? answers[props.name] : false) ? 1 : 0;

  const [question, dispatch] = useStore(props.name, props.instrument);

  const { name, data, parentId, id, label, units, comments } = question;
  const { instrument } = props;
  const { review } = props;

  // const [storeState, dispatch] = useStore(props.name, props.instrument);
  const [value, setValue] = useState((answer || data?.default || false) ? 1 : 0)

  useEffect(() => {
    if (!review) {
      dispatch({
        type: 'UPDATE',
        data: { name, value, instrument, instrumentdataoffset: props.instrumentdataoffset }
      })
    }
  }, [value])

  const handleChange = (event) => {
    event.persist();
    setValue(event.target.checked ? 1 : 0);
    dispatch(modifySubjectAction());
  };

  const isChecked = (value: any): boolean => {
    return (value > 0);
  };

  const disabled = isDisabled(question, answers)

  return (
    review ? 
      <>
        <TableCell width="60%" component="th" scope="row" align="right">
        {`${parentId}.${id} ${label}`}
        </TableCell>
        <TableCell width="40%" align="left">{answer ? 'Yes' : 'No'}</TableCell>
      </>
    :
    <FormControl component="fieldset">
      <FormLabel disabled={disabled} classes={{ root: classes.formControl }} component="label" >
        {`${parentId}.${id} ${label}`}
      </FormLabel>
      <MuiSwitch
        disabled={disabled}
        defaultValue={(answer || data?.default || undefined) == undefined ? undefined : ((answer || data?.default) ? 1 : 0)}
        size="small"
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          checked: classes.checked,
          track: classes.track,
        }}
        {...props}
        checked={isChecked(value)}
        onChange={handleChange}
      />
      {comments?.length && <FormHelperText classes={{ root: classes.formHelperText }}>{comments[0]}</FormHelperText>}

    </FormControl>
  )

}