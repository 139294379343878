/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Provides a two-pane layout including a full-size header, sidebar & main content section.
 */
import {Box, makeStyles, Theme} from '@material-ui/core';
import React, {ReactNode} from 'react';
import Header from 'Components/stateful/Header';
import Sidebar from '@aws-prototyping/ui/components/Sidebar';
import config from 'Config/aws';
import SideNavItems from 'Menu/SideNav';
import HeaderMenuItems from 'Menu/Header';
import Breadcrumbs from '@aws-prototyping/ui/components/Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
    flashbarContainer: {
        margin: theme.spacing(-4, -4, 2, -4)
    },
    breadcrumbsContainer: {
        marginBottom: theme.spacing(2)
    },
    header: {
        height: '65px'
    },
    sidebar: {
        height: 'calc(100vh - 65px)'
    }
}));

interface TwoPaneProps {
    children: ReactNode;
}

const TwoPane = ({ children }: TwoPaneProps) => {
    const classes = useStyles({});
    const customer = { ...config.customer };

    return (
        <div>
            <Header className={classes.header} customerName={''} menuItems={HeaderMenuItems} />
            <Sidebar className={classes.sidebar} header={{ text: customer.name, href: '/' }} items={SideNavItems}>
                <Breadcrumbs className={classes.breadcrumbsContainer} rootPath={'Home'} />
                <Box>{children}</Box>
            </Sidebar>
        </div>
    );
};

export default TwoPane;
