/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import Amplify from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import awsconfig from 'Config/aws';
import TwoPane from 'Layouts/TwoPane';

import LandingPage from 'Views/LandingPage';
import Admission from 'Views/Admission';
import MechanicalVentilation from 'Views/MechanicalVentilation';
import ECMOVentilation from 'Views/ECMOVentilation';
import Observations from 'Views/Observations';
import ReviewSubject from 'Views/ReviewSubject';
import CompleteOutcome from 'Views/CompleteOutcome';

import React from 'react';
import { Switch } from 'react-router';
import { Store } from 'redux';
import SecureRoute from 'Components/stateful/Route/SecureRoute';
import { History as BrowserHistory } from 'history';

/**
 * Renders the appropriate view, given the property config.
 *
 * @param store Redux store.
 * @param history history object.
 */
export const getRoutes = (store: Store, history: BrowserHistory) => {
    Amplify.configure({
        Analytics: {
            AWSPinpoint: {
                appId: awsconfig.pinPoint.appId,
                region: awsconfig.pinPoint.region
            },
            autoSessionRecord: true,
            disabled: false
        },
        Auth: {
            identityPoolId: awsconfig.auth.identityPoolId,
            region: awsconfig.auth.region,
            oauth: {
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: 'http://localhost:8080/',
                redirectSignOut: 'http://localhost:8080/',
                responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
        }
    });

    return (
        <ConnectedRouter history={history}>
            <Switch>
                {/* Do not add the key attribute to the routes below as it will force a re-mount of all stateful components! */}
                <SecureRoute exact={true} path="/" layout={TwoPane} component={LandingPage} />
                <SecureRoute exact={true} path="/admission" layout={TwoPane} component={Admission} />
                <SecureRoute exact={true} path="/complete" layout={TwoPane} component={CompleteOutcome} />
                <SecureRoute exact={true} path="/ecmo" layout={TwoPane} component={ECMOVentilation} />
                <SecureRoute exact={true} path="/mech" layout={TwoPane} component={MechanicalVentilation} />
                <SecureRoute exact={true} path="/obs" layout={TwoPane} component={Observations} />
                <SecureRoute exact={true} path="/review" layout={TwoPane} component={ReviewSubject} />
            </Switch>
        </ConnectedRouter>
    );
};
