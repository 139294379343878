/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * All Log related action creator definitions.
 */

import {
    SetInstitutionIdAction,
    ClearInstitutionIdAction,
    SetSubjectIdAction,
    ClearSubjectIdAction,
    FormDataActions,
    GetSubjectAction,
    GetSubjectSuccessAction,
    GetSubjectFailureAction,
    PutSubjectAction,
    PutSubjectSuccessAction,
    PutSubjectFailureAction,
    AddDailyObservationAction,
    AddSubjectAction
} from 'Actions/definition/formData';

/**
 * Emits an action setting the active institutionId
 */
export const setInstitutionIdAction = (institutionId): SetInstitutionIdAction => {
    return { type: FormDataActions.SET_INSTITUTION_ID, institutionId };
};

/**
 * Emits an action setting the active subjectId
 */
export const clearInstitutionIdAction = (): ClearInstitutionIdAction => {
    return { type: FormDataActions.CLEAR_INSTITUTION_ID };
};

/**
 * Emits an action clearing the active institutionId
 */
export const setSubjectIdAction = (subjectId): SetSubjectIdAction => {
    return { type: FormDataActions.SET_SUBJECT_ID, subjectId };
};

/**
 * Emits an action clearing the active subjectId
 */
export const clearSubjectIdAction = (): ClearSubjectIdAction => {
    return { type: FormDataActions.SET_SUBJECT_ID };
};

/**
 * Emits an action indicating a request to fetch study subject data
 */
export const getSubjectAction = (institutionId: string, subjectId: string): GetSubjectAction => {
    return { type: FormDataActions.GET_SUBJECT, institutionId, subjectId };
};

export const getSubjectSuccessAction = (subject): GetSubjectSuccessAction => {
    return { type: FormDataActions.GET_SUBJECT_SUCCESS, subject };
};

export const getSubjectFailureAction = (error): GetSubjectFailureAction => {
    return { type: FormDataActions.GET_SUBJECT_FAILURE, error };
};

/**
 * Emits an action indicating a request to write (put) subject data
 */
export const putSubjectAction = (subject): PutSubjectAction => {
    return { type: FormDataActions.PUT_SUBJECT, subject };
};

export const putSubjectSuccessAction = (subject): PutSubjectSuccessAction => {
    return { type: FormDataActions.PUT_SUBJECT_SUCCESS, subject };
};

export const putSubjectFailureAction = (error): PutSubjectFailureAction => {
    return { type: FormDataActions.PUT_SUBJECT_FAILURE, error };
};

export const addDailyObservationAction = (): AddDailyObservationAction => {
    return { type: FormDataActions.ADD_DAILY_OBSERVATION };
};

export const addSubjectAction = (subject): AddSubjectAction => {
    return { type: FormDataActions.ADD_SUBJECT, subject };
};
