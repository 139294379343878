/* eslint-disable prettier/prettier */
/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * All supported UI action types.
 */
export enum UIActions {
    NO_OP = 'NO_OP',
    GET_SUBJECT_IDS = 'GET_SUBJECT_IDS',
    SET_ACTIVE_SUBJECT_ID = 'SET_ACTIVE_SUBJECT_ID',
    GET_SUBJECT_IDS_SUCCESS = 'GET_SUBJECT_IDS_SUCCESS',
    GET_SUBJECT_IDS_FAILURE = 'GET_SUBJECT_IDS_FAILURE',
    SET_ADMISSION_SAVED = 'SET_ADMISSION_SAVED',
    SET_MECHVENT_SAVED = 'SET_MECHVENT_SAVED',
    SET_ECMOVENT_SAVED = 'SET_ECMOVENT_SAVED',
    SET_SUBJECTCOMPLETE_SAVED = 'SET_SUBJECTCOMPLETE_SAVED',
    ADD_NEW_SUBJECT = 'ADD_NEW_SUBJECT',
    MODIFY_SUBJECT = 'MODIFY_SUBJECT',
    SET_SUBJECT_SAVED = 'SET_SUBJECT_SAVED',
    SET_DAILY_OBS_SAVED = 'SET_DAILY_OBS_SAVED',
    ADD_DAILY_OBS = 'ADD_DAILY_OBS'    
}

export interface UI {
    subjectIds: string[] | null;
    activeSubjectId: string | null;
    error: {} | null;
    hasError: boolean;
    admissionSaved: boolean;
    mechVentilationSaved: boolean;
    ecmoVentilationSaved: boolean;
    subjectCompleteSaved: boolean;
    subjectSaved: boolean;
}

/**
 * Action definitions for subjects etc
 */
export interface NoOpAction {
    type: typeof UIActions.NO_OP;
}

export interface GetSubjectIdsAction {
    type: typeof UIActions.GET_SUBJECT_IDS,
    institutionId
}

export interface GetSubjectIdsSuccessAction {
    type: typeof UIActions.GET_SUBJECT_IDS_SUCCESS,
    subjectIds
}


export interface SetActiveSubjectIdAction {
    type: typeof UIActions.SET_ACTIVE_SUBJECT_ID,
    activeSubjectId
}

export interface SetAdmissionSavedAction {
    type: typeof UIActions.SET_ADMISSION_SAVED
}

export interface SetMechVentilationSavedAction {
    type: typeof UIActions.SET_MECHVENT_SAVED
}

export interface SetECMOVentilationSavedAction {
    type: typeof UIActions.SET_ECMOVENT_SAVED
}

export interface SetSubjectCompleteSavedAction {
    type: typeof UIActions.SET_SUBJECTCOMPLETE_SAVED
}

export interface GetSubjectIdsFailureAction {
    type: typeof UIActions.GET_SUBJECT_IDS_FAILURE,
    error
}

export interface AddNewSubjectAction {
    type: typeof UIActions.ADD_NEW_SUBJECT
}

export interface ModifySubjectAction {
    type: typeof UIActions.MODIFY_SUBJECT
}

export interface SetSubjectSavedAction {
    type: typeof UIActions.SET_SUBJECT_SAVED
}

export interface SetDailyObsSavedAction {
    type: typeof UIActions.SET_DAILY_OBS_SAVED
}

export interface AddDailyObsAction {
    type: typeof UIActions.ADD_DAILY_OBS
}

/**
 * All User Actions can be 'subclassed' to UIActionTypes.
 */
export type UIActionTypes =
    | NoOpAction
    | GetSubjectIdsAction
    | GetSubjectIdsSuccessAction
    | SetActiveSubjectIdAction
    | GetSubjectIdsFailureAction
    | SetAdmissionSavedAction
    | SetMechVentilationSavedAction
    | SetECMOVentilationSavedAction
    | SetSubjectCompleteSavedAction
    | AddNewSubjectAction
    | ModifySubjectAction
    | SetSubjectSavedAction
    | SetDailyObsSavedAction
    | AddDailyObsAction

