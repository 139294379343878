/* eslint-disable prettier/prettier */
/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * ALl supported FormData action types.
 */
export enum FormDataActions {
    NO_OP = 'NO_OP',
    UPDATE = 'UPDATE',
    SET_INSTITUTION_ID = 'SET_INSTITUTION_ID',
    CLEAR_INSTITUTION_ID = 'CLEAR_INSTITUTION_ID',
    SET_SUBJECT_ID = 'SET_SUBJECT_ID',
    CLEAR_SUBJECT_ID = 'CLEAR_SUBJECT_ID',
    GET_SUBJECT = 'GET_SUBJECT',
    GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS',
    GET_SUBJECT_FAILURE = 'GET_SUBJECT_FAILURE',
    PUT_SUBJECT = 'PUT_SUBJECT',
    PUT_SUBJECT_SUCCESS = 'PUT_SUBJECT_SUCCESS',
    PUT_SUBJECT_FAILURE = 'PUT_SUBJECT_FAILURE',
    ADD_DAILY_OBSERVATION = 'ADD_DAILY_OBSERVATION',
    ADD_SUBJECT = 'ADD_SUBJECT'
}

export interface Instrument {
    id: string;
    name: string;
    questions: {};
}

export interface FormData {
    institutionId: string | null;
    subjectId: string | null;
    [instrument: string]: Instrument;
}

/**
 * Action definition to sign in.
 */
export interface NoOpAction {
    type: typeof FormDataActions.NO_OP;
}

export interface UpdateAction {
    type: typeof FormDataActions.UPDATE;
    data: { name: string, instrument: string, value: string | [] | boolean }
}

export interface SetInstitutionIdAction {
    type: typeof FormDataActions.SET_INSTITUTION_ID;
    institutionId: string;
}

export interface ClearInstitutionIdAction {
    type: typeof FormDataActions.CLEAR_INSTITUTION_ID;
}

export interface SetSubjectIdAction {
    type: typeof FormDataActions.SET_SUBJECT_ID;
    subjectId: string;
}

export interface ClearSubjectIdAction {
    type: typeof FormDataActions.CLEAR_SUBJECT_ID;
}

export interface GetSubjectAction {
    type: typeof FormDataActions.GET_SUBJECT,
    institutionId,
    subjectId
}

export interface GetSubjectSuccessAction {
    type: typeof FormDataActions.GET_SUBJECT_SUCESS,
    subject
}

export interface GetSubjectFailureAction {
    type: typeof FormDataActions.GET_SUBJECT_FAILURE,
    error
}

export interface PutSubjectAction {
    type: typeof FormDataActions.PUT_SUBJECT,
    subject
}

export interface PutSubjectSuccessAction {
    type: typeof FormDataActions.PUT_SUBJECT_SUCESS,
    subject
}

export interface PutSubjectFailureAction {
    type: typeof FormDataActions.PUT_SUBJECT_FAILURE,
    error
}

export interface AddDailyObservationAction {
    type: typeof FormDataActions.ADD_DAILY_OBSERVATION
}

export interface AddSubjectAction {
    type: typeof FormDataActions.ADD_SUBJECT,
    subject
}

/**
 * All User Actions can be 'subclassed' to FormDataActionTypes.
 */
export type FormDataActionTypes =
    | NoOpAction
    | UpdateAction
    | SetInstitutionIdAction
    | ClearInstitutionIdAction
    | SetSubjectIdAction
    | ClearSubjectIdAction
    | PutSubjectAction
    | PutSubjectSuccessAction
    | PutSubjectFailureAction
    | GetSubjectAction
    | GetSubjectSuccessAction
    | GetSubjectFailureAction
    | AddDailyObservationAction
    | AddSubjectAction
