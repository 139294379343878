/* eslint-disable prettier/prettier */

import {
  UI,
  UIActions,
  UIActionTypes,
} from 'Actions/definition/ui'

import { UserActions } from 'Actions/definition/user';
import { FormDataActions } from "Actions/definition/formData";

const initialState: UI = {
  subjectIds: null,
  activeSubjectId: null,
  error: null,
  hasError: false,
  admissionSaved: false,
  mechVentilationSaved: false,
  ecmoVentilationSaved: false,
  subjectCompleteSaved: false,
  subjectSaved: true
}

export function uiReducer(
  ui: UI = initialState,
  action: UIActionTypes
): UI {
  switch (action.type) {
    case UIActions.NO_OP:
      return ui;

    // clear any user specific state
    case UserActions.SIGNOUT_SUCCESS:
      return {
        ...initialState
      };
      
    // ensure ui state subject list is updated - no need to refetch as second request
    case FormDataActions.PUT_SUBJECT_SUCCESS: {
      ui.subjectIds.push(action.subject.SubjectId);
      return {
        ...ui
      };
    }

    case UIActions.SET_ACTIVE_SUBJECT_ID:
      return {
        ...ui,
        admissionSaved: false,
        mechVentilationSaved: false,
        ecmoVentilationSaved: false,
        subjectCompleteSaved: false,
        subjectSaved: true,
        activeSubjectId: action.activeSubjectId
      };

    case UIActions.SET_ADMISSION_SAVED:
      return {
        ...ui,
        admissionSaved: true,
        subjectSaved: true
      }

    case UIActions.SET_MECHVENT_SAVED:
      return {
        ...ui,
        mechVentilationSaved: true,
        subjectSaved: true
      }

    case UIActions.SET_ECMOVENT_SAVED:
      return {
        ...ui,
        ecmoVentilationSaved: true,
        subjectSaved: true
      }

    case UIActions.SET_SUBJECTCOMPLETE_SAVED:
      return {
        ...ui,
        subjectCompleteSaved: true,
        subjectSaved: true
      }
      
    case UIActions.ADD_NEW_SUBJECT:
      return {
        ...ui,
        subjectSaved: false
      }
    
    case UIActions.MODIFY_SUBJECT:
      return {
        ...ui,
        subjectSaved: false
      }
    case UIActions.SET_SUBJECT_SAVED:
      return {
        ...ui,
        subjectSaved: true
      }
    case UIActions.SET_DAILY_OBS_SAVED:
      return {
        ...ui,
        subjectSaved: true
      }

    case UIActions.ADD_DAILY_OBS:
      return {
        ...ui,
        subjectSaved: false
      }
      
    case UIActions.GET_SUBJECT_IDS_SUCCESS:
      return {
        ...ui,
        subjectIds: action.subjectIds
      };

    case UIActions.GET_SUBJECT_IDS_FAILURE:
      return {
        ...ui,
        error: { ...action }
      };

    default:
      return ui;
  }
}