/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * All Log related action creator definitions.
 */

import {
    GetCloudwatchLogGroupSuccessAction,
    GetCloudwatchLogGroupFailureAction,
    GetCloudwatchLogGroupAction,
    LogActions,
    GetCloudwatchLogEventsAction,
    GetCloudwatchLogEventsSuccessAction,
    CloudwatchLogEventsParams,
    GetCloudwatchLogEventsFailureAction,
    CloudwatchLogGroups
} from 'Actions/definition/log';

/**
 * Emits an action indicating a request to fetch log groups
 *
 * @param data payload.
 */
export const getCloudwatchLogGroupAction = (data: any): GetCloudwatchLogGroupAction => {
    return { type: LogActions.GET_CLOUDWATCH_LOG_GROUPS, payload: data };
};

/**
 * Emits an action indicating a successful request to fetch log groups
 *
 * @param data payload.
 */
export const getLogGroupSuccessAction = (
    cloudwatchLogGroups: CloudwatchLogGroups
): GetCloudwatchLogGroupSuccessAction => {
    return { type: LogActions.GET_CLOUDWATCH_LOG_GROUPS_SUCCESS, payload: cloudwatchLogGroups };
};

/**
 * Emits an action indicating a failed request to fetch log groups
 *
 * @param data payload.
 */
export const getLogGroupFailureAction = (data: any): GetCloudwatchLogGroupFailureAction => {
    return { type: LogActions.GET_CLOUDWATCH_LOG_GROUPS_FAILURE, payload: data };
};

/**
 * Emits an action indicating a request to fetch a cloudwatch log events record
 *
 * @param data payload.
 */
export const getLogEvents = (data: CloudwatchLogEventsParams): GetCloudwatchLogEventsAction => {
    return { type: LogActions.GET_CLOUDWATCH_LOG_EVENTS, payload: data };
};

/**
 * Emits an action indicating a request to fetch a cloudwatch log events record failed
 *
 * @param data payload.
 */
export const getLogEventsFailure = (data: any): GetCloudwatchLogEventsFailureAction => {
    return { type: LogActions.GET_CLOUDWATCH_LOG_EVENTS_FAILURE, payload: data };
};

/**
 * Emits an action indicating a request to fetch a cloudwatch log events record was successful
 *
 * @param data payload.
 */
export const getLogEventsSuccess = (data: any): GetCloudwatchLogEventsSuccessAction => {
    return { type: LogActions.GET_CLOUDWATCH_LOG_EVENTS_SUCCESS, payload: data };
};
