/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * All Log related action creator definitions.
 */

import {
    AddNewSubjectAction,
    AddDailyObsAction,
    GetSubjectIdsAction,
    GetSubjectIdsSuccessAction,
    SetActiveSubjectIdAction,
    GetSubjectIdsFailureAction,
    SetAdmissionSavedAction,
    SetMechVentilationSavedAction,
    SetECMOVentilationSavedAction,
    SetSubjectCompleteSavedAction,
    SetDailyObsSavedAction,
    ModifySubjectAction,
    SetSubjectSavedAction,
    UIActions
} from 'Actions/definition/ui';

// --
/**
 * Emits an action indicating a request to fetch study subject ids
 */
export const getSubjectIdsAction = (institutionId: string): GetSubjectIdsAction => {
    return { type: UIActions.GET_SUBJECT_IDS, institutionId };
};

export const getSubjectIdsSuccessAction = (subjectIds: []): GetSubjectIdsSuccessAction => {
    return { type: UIActions.GET_SUBJECT_IDS_SUCCESS, subjectIds };
};

export const getSubjectIdsFailureAction = (error): GetSubjectIdsFailureAction => {
    return { type: UIActions.GET_SUBJECT_IDS_FAILURE, error };
};

// --

export const setAdmissionSavedAction = (): SetAdmissionSavedAction => {
    return { type: UIActions.SET_ADMISSION_SAVED };
};

export const setMechVentilationSavedAction = (): SetMechVentilationSavedAction => {
    return { type: UIActions.SET_MECHVENT_SAVED };
};

export const setECMOVentilationSavedAction = (): SetECMOVentilationSavedAction => {
    return { type: UIActions.SET_ECMOVENT_SAVED };
};

export const setSubjectCompleteSavedAction = (): SetSubjectCompleteSavedAction => {
    return { type: UIActions.SET_SUBJECTCOMPLETE_SAVED };
};

export const addNewSubjectAction = (): AddNewSubjectAction => {
    return { type: UIActions.ADD_NEW_SUBJECT };
}

export const modifySubjectAction = (): ModifySubjectAction => {
    return { type: UIActions.MODIFY_SUBJECT };
}

export const setSubjectSavedAction = (): SetSubjectSavedAction => {
    return { type: UIActions.SET_SUBJECT_SAVED };
}

export const setDailyObsSavedAction = (): SetDailyObsSavedAction => {
    return { type: UIActions.SET_DAILY_OBS_SAVED };
}

export const addDailyObsAction = () : AddDailyObsAction => {
    return { type: UIActions.ADD_DAILY_OBS };
}

// -- active subject setter

export const setActiveSubjectIdAction = (activeSubjectId: string): SetActiveSubjectIdAction => {
    return { type: UIActions.SET_ACTIVE_SUBJECT_ID, activeSubjectId };
};
