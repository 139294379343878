/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Renders the landing page.
 */
import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { ApplicationState } from 'Actions/definition/root';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import FormComponentFactory from 'Utils/FormComponentFactory';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@aws-prototyping/ui/components/Container';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setECMOVentilationSavedAction } from 'Actions/creator/ui';
import { putSubjectAction } from 'Actions/creator/formData';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            overflowX: 'auto'
        }
    })
);

const ECMOVentilation = () => {
    const subject = useSelector((state: any) => state.formData);
    const formRenderData = useSelector((state: any) => state.formRenderData);
    const classes = useStyles({});
    const history = useHistory();
    const dispatch = useDispatch();
    const controls = FormComponentFactory.controlsForInstrumentQuestions(
        'eot_start_ecmo',
        -1,
        formRenderData.eot_start_ecmo.questions
    );

    // @ts-ignore
    return (
        <div>
            <Container title={'ECMO Ventilation Details - “' + `${subject.SubjectId}` + '”'}>
                <Box m={1} pl={7} pr={7}>
                    <Grid container justify={'center'} spacing={3} direction={'row'}>
                        {controls.map((item, idx) => (
                            <Grid key={idx} style={{ minWidth: '400px' }} item xs={12}>
                                {item}
                            </Grid>
                        ))}
                        <Grid item xs={4}>
                            <Button
                                size={'large'}
                                fullWidth={true}
                                variant={'contained'}
                                onClick={() => {
                                    dispatch(setECMOVentilationSavedAction());
                                    dispatch(putSubjectAction(subject));
                                    history.push('/');
                                }}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};

export default ECMOVentilation;
