/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Renders the landing page.
 */
import React, { useState } from 'react';
import Container from '@aws-prototyping/ui/components/Container';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormComponentFactory from 'Utils/FormComponentFactory';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { setECMOVentilationSavedAction } from 'Actions/creator/ui';
import { useDispatch, useSelector } from 'react-redux';
import { putSubjectAction } from 'Actions/creator/formData';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { setDailyObsSavedAction } from 'Actions/creator/ui';
import QueryString from 'query-string';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            overflowX: 'auto'
        }
    })
);

export interface IdealBodyweightDialogProps {
    open: boolean;
    patientHeight: number;
    volumeKgValue: number;
    onClose: (value: number, selection:string) => void;
}

// this calculation is from the ECMOCARD schema author
const calculateIdealBodyWeight = (height: number, sex: string) => {
    let result = 0;
    const baseValue = Math.abs(0.91 * (height - 152.4));

    switch(sex) {
        case 'male':
            result = baseValue + 50;
            break;

        case 'female':
            result = baseValue + 45.5;
            break;

        default:
            // TODO: should generate error
            break;
    }

    return Math.round(result);
}

// my kingdom for decent built-in handling of decimals...
const round = (value: number, decimals: number) => {
    return value ? Number(Math.round(value+'e'+decimals)+'e-'+decimals) : 0;
}

// for the given total tidal volume, patient sex & height, returns the tidal volume
// in ml per Kg of ideal patient body weight
const roundedTidalVolumeIBW = (volume: number, patientHeight: number, patientSex: string) => {
    const rawValue = Number(volume/calculateIdealBodyWeight(patientHeight, patientSex));
    return round(rawValue, 1);
}

const IdealBodyweightDialog = (props: IdealBodyweightDialogProps) => {
    const classes = useStyles();
    const { onClose, patientHeight, volumeKgValue, open } = props;
    const [volume, setVolume] = useState<number>(undefined);
  
    const clickCancelButton = () => onClose(-1, 'cancel');
    const clickFemalePatientButton = () => onClose(volume, 'female');
    const clickMalePatientButton = () => onClose(volume, 'male');

    return (
      <Dialog aria-labelledby={'simple-dialog-title'} open={open}>
        <DialogTitle id={'simple-dialog-title'}>Calculate Tidal Volume ml/Kg based on Ideal Body Wieght</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To calculate the tidal volume in ml/Kg, enter the highest tidal volume in ml, then select the patient sex below to use this value in the daily observation form
                </DialogContentText>
                <TextField
                    autoFocus
                    margin={'dense'}
                    id={'tidalvol'}
                    label={'Highest Tidal Volume in ml'}
                    type={'number'}
                    value={volume == null ? '' : volume}
                    onChange={e => setVolume(e.target.value)}
                    fullWidth
                />
                <Box display={'flex'} flexDirection={'row'}>
                    <Typography style={{minWidth: '130px'}} variant={'caption'} display={'block'} gutterBottom>
                        <br/>
                    </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                    <Typography style={{minWidth: '130px'}} variant={'caption'} display={'block'} gutterBottom>
                        {
                        `- tidal volume if male:`
                        }
                    </Typography>
                    <Typography style={{minWidth: '70px'}} align={'right'} variant={'caption'} display={'block'} gutterBottom>
                        {
                        `${roundedTidalVolumeIBW(volume, patientHeight, 'male')}ml/Kg`
                        }
                    </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                    <Typography style={{minWidth: '130px'}} variant={'caption'} display={'block'} gutterBottom>
                    {
                    `- tidal volume if female:`
                    }
                    </Typography>
                    <Typography style={{minWidth: '70px'}} align={'right'} variant={'caption'} display={'block'} gutterBottom>
                        {
                        `${roundedTidalVolumeIBW(volume, patientHeight, 'female')}ml/Kg`
                        }
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={clickFemalePatientButton} variant={'outlined'} size={'small'} color={'primary'}>
                    Female Patient
                </Button>
                <Button onClick={clickMalePatientButton} variant={'outlined'} size={'small'} color={'primary'}>
                    Male Patient
                </Button>
                <Button onClick={clickCancelButton} variant={'outlined'} size={'small'} color={'primary'}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
  }

const Observations = () => {
    // pull the observation to edit/add from the instrumentDataOffset query param
    const location = useLocation();
    const queryParams = QueryString.parse(location.search);
    const { instrumentDataOffset } = queryParams;

    const subject = useSelector((state: any) => state.formData);
    const formRenderData = useSelector((state: any) => state.formRenderData);

    const classes = useStyles({});
    const history = useHistory();
    const dispatch = useDispatch();
    const controls = FormComponentFactory.controlsForInstrumentQuestions(
        'eot_daily',
        instrumentDataOffset,
        formRenderData.eot_daily.questions
    );
    const [openBodyweightDialog, setOpenBodyweightDialog] = React.useState(false);
    const [volumeKgValue, setVolumeKgValue] = React.useState(null);
    const patientHeight = Number(subject?.eot_icu_admis?.questions?.ia_height);

    let tidalControl = null;

    const handleClickOpenBodyweightDialog = () => {
        setOpenBodyweightDialog(true);
    };
    
    // get the field document element for tidalvol and push value
    // returned from the calculation helper dialog into it
    const handleCloseBodyweightDialog = (volume: number, selection: string) => {
        setOpenBodyweightDialog(false);
        if (volume > 0) {
            // HACK
            const mlKg = roundedTidalVolumeIBW(volume, patientHeight, selection);
            document.getElementsByName('ef_tidalvol')[0].value = mlKg;
        }
    };

    // @ts-ignore
    return (
        <div>
            <Container title={`Daily Observation ${instrumentDataOffset == -1 ? '(NEW)' : ''} - “` + `${subject.SubjectId}` + '”'}>
                <Box m={1} pl={7} pr={7}>
                    <Grid container justify={'center'} spacing={3} direction={'row'}>
                        {controls.map(item => (
                            <Grid style={{ minWidth: '400px' }} item xs={12}>
                                <Box display={item.props.name === 'ef_tidalvol' ? 'flex' : ''} flexDirection={item.props.name === 'ef_tidalvol' ? 'row' : ''}>
                                    <Grid item xs={item.props.name === 'ef_tidalvol' ? 10 : 12}>
                                        {item}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {item.props.name === 'ef_tidalvol' ?
                                        <Box alignItems={'right'} paddingTop={'9px'} paddingLeft={'9px'}>
                                            <Button size={'small'} fullWidth={true} variant={'outlined'} color={'primary'} onClick={handleClickOpenBodyweightDialog}>
                                                calculate ml/Kg
                                            </Button>
                                        </Box>
                                        : ''}
                                        {item.props.name === 'ef_tidalvol' ?
                                        <IdealBodyweightDialog patientHeight={patientHeight} volumeKgValue={volumeKgValue} open={openBodyweightDialog} onClose={handleCloseBodyweightDialog} />
                                        : ''}
                                        {(() => {
                                            if (item.props.name === 'ef_tidalvol') {
                                                tidalControl = item;
                                            }
                                        })()}
                                    </Grid>
                                </Box>                               
                            </Grid>
                        ))}
                        <Grid item xs={4}>
                            <Button
                                size={'large'}
                                fullWidth={true}
                                variant={'contained'}
                                onClick={() => {
                                    dispatch(setDailyObsSavedAction());                                    
                                    dispatch(putSubjectAction(subject));
                                    history.push('/');
                                }}
                            >
                              {instrumentDataOffset > -1 ? "Save" : "Add"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};

export default Observations;
