/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * User reducer for managing user state.
 */
import { handleActions } from 'redux-actions';
import { UserActions } from 'Actions/definition/user';

const intialState = {};

const userReducer = handleActions(
    {
        [UserActions.SIGNIN_SUCCESS]: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        [UserActions.REFRESH_SIGNIN_SUCCESS]: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        [UserActions.SIGNOUT_SUCCESS]: (state, action) => {
            return {
                ...intialState
            };
        },
        [UserActions.SIGNIN_FAILURE]: (state, action) => {
            return {
                ...state
            };
        }
    },
    intialState
);

export default userReducer;
