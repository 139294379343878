/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * ALl supported User action types.
 */
export enum UserActions {
    SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
    REFRESH_SIGNIN_SUCCESS = 'REFRESH_SIGNIN_SUCCESS',
    SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS',
    SIGNIN_FAILURE = 'SIGNIN_FAILURE'
}

export interface User {
    attributes: { [key: string]: string };
}

/**
 * Action definition to sign in.
 */
export interface SignInAction {
    type: typeof UserActions.SIGNIN_SUCCESS;
    payload: any;
}

/**
 * Action definition for a failure to sign in.
 */
export interface SignInFailureAction {
    type: typeof UserActions.SIGNIN_FAILURE;
    payload: any;
}

/**
 * Action definition to refresh sign in.
 */
export interface RefreshSignInAction {
    type: typeof UserActions.REFRESH_SIGNIN_SUCCESS;
    payload: any;
}

/**
 * Action definition to sign out.
 */
export interface SignOutAction {
    type: typeof UserActions.SIGNOUT_SUCCESS;
    payload: any;
}

/**
 * All User Actions can be 'subclassed' to UserActionTypes.
 */
export type UserActionTypes = SignInAction | SignInFailureAction | RefreshSignInAction | SignOutAction;
