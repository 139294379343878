/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from 'react';
import MuiAutoComplete, { AutocompleteProps as MuiAutoCompleteProps } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { FormDataDataQuestionDropdownSelect } from 'Actions/definition/formRenderData'
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText'
import * as _ from 'lodash';
import { useStore } from 'Utils/useStore'
import { useSelector } from 'react-redux';
import { isDisabled, setIfExits } from 'Utils/controlHelpers'

import TableCell from '@material-ui/core/TableCell';

import { initialState } from 'Reducers/formData';
import { modifySubjectAction } from "Actions/creator/ui";

export interface EcmoDataProps extends MuiAutoCompleteProps {
  instrument: string;
  name: string;
}

const useStyles = makeStyles({
  root: {
  },
})


export const AutoComplete: React.FC<EcmoDataProps> = (props) => {
  const classes = useStyles();

  let maxValues = 200;
  const [storeState, dispatch] = useStore(props.name, props.instrument);
  const [valueCount, setValueCount] = useState<number>(0)
  const [timestamp, setTimestamp] = useState<number>(0)
  const [name, setName] = useState<string>(storeState.name)
  const [hasSetIfExists, setHasSetIfExists] = useState<boolean>(false)
  const { review } = props

  //const answers = useSelector(state => state.formData[props.instrument].questions);
  const answers = useSelector(
    state => (props.instrument in state.formData) ? 
      (props.instrumentdataoffset > -1 ? 
        state.formData[props.instrument]['questions'][props.instrumentdataoffset] 
        : state.formData[props.instrument]['questions']
      ) 
    : initialState[props.instrument]['questions']
  );
  const initialValues = answers[name];
  const [values, setValues] = useState<string | []>(initialValues ? initialValues : (storeState?.data?.type === FormDataDataQuestionDropdownSelect.SINGLE ? null : []))

  useEffect(() => {
    if (!review) {
      dispatch({
        type: 'UPDATE',
        data: { name, value: values, instrument: props.instrument, instrumentdataoffset: props.instrumentdataoffset }
      })

      // values && setIfExits(storeState, hasSetIfExists, setHasSetIfExists, dispatch, props.instrument)
    }
  }, [values, name])

  const handleChange = (event, newValue, reason) => {

    if (_(storeState).has('data.validation.max')) {
      maxValues = storeState?.data?.validation.max;
    }
    
    dispatch(modifySubjectAction());
    
    switch (reason) {
      case 'select-option':
        if (valueCount < maxValues) {
          Array.isArray(newValue) &&
            setValues(values.concat(newValue[newValue.length - 1].value))

          typeof newValue === 'object' && !Array.isArray(newValue) &&
            setValues(newValue.value)

          typeof newValue === 'string' &&
            setValues(newValue)

          setValueCount(valueCount => valueCount + 1)
          setTimestamp(Date.now())
        }
        break;

      case 'remove-option':
        Array.isArray(newValue) &&
          setValues(newValue)

        typeof newValue === 'object' && !Array.isArray(newValue) &&
          setValues(newValue.map(value => _(storeState?.data?.options).find({ label: value }).value))

        typeof newValue === 'string' &&
          setValues(newValue)

        setValueCount(valueCount => valueCount - 1)
        setTimestamp(Date.now())

        break;

      case 'clear':
        setValues(Array.isArray(newValue) ? [] : null)

        setValueCount(0)
        setTimestamp(Date.now())
        break;
      default:
        console.log('unknown reason', reason)
    }

  };

  return (
    review ? 
      <>
        <TableCell width="60%" component="th" scope="row" align="right">
        {storeState?.parentId}.{storeState?.id} {storeState?.label}
        </TableCell>
        <TableCell width="40%" align="left">{values ? storeState?.data?.options.map(option => (values.includes(option.value) && <>{option.label}<br/></>)) : ''}</TableCell>
      </>
    :
    <div>
      <MuiAutoComplete
        {...props}
        disabled={isDisabled(storeState, answers)}
        size={"medium"}
        multiple={storeState?.data?.type === FormDataDataQuestionDropdownSelect.SINGLE ? false : true}
        options={storeState?.data?.options}
        getOptionLabel={option => option && option.label ? option.label : storeState?.data?.options.filter(o => o.value === option).map(o => o.label)[0]}
        getOptionSelected={(option, value) => value.includes(option.value)}
        value={values}
        renderTags={(value: string[], getTagProps) => {

          if (_(storeState).has('data.validation.max')) {
            maxValues = storeState?.data?.validation.max;
          }

          return value.map((option: string, index: number) => (
            (index < maxValues) && <Chip variant="outlined" label={storeState?.data?.options.filter(o => o.value === option).map(o => o.label)[0]} {...getTagProps({ index })} />
          ))
        }}
        renderInput={(params) => <TextField {...params} label={`${storeState.parentId}.${storeState.id} ${storeState.label} ${(storeState?.data?.validation?.mustExist || false) ? '*': ''}`} variant="standard" />}
        onChange={handleChange}
      />

      {storeState.comments?.length && <FormHelperText>{storeState?.comments[0]}</FormHelperText>}
    </div>
  )

}