/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import { Auth, Hub } from 'aws-amplify';
import { Component, ReactNode, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { refreshSignInAction, signInAction, signInFailureAction, signOutAction } from 'Actions/creator/user';
import { ApplicationState } from 'Actions/definition/root';
import { DependencyContext } from 'Contexts/dependencyContext';
import { RefreshSignInAction, SignInAction, SignInFailureAction, SignOutAction } from 'Actions/definition/user';
import { getCloudwatchLogGroupAction } from 'Actions/creator/log';
import { GetCloudwatchLogGroupAction } from 'Actions/definition/log';

/** AmplifyAuth Actions */
export const AMPLIFY_SIGNIN = 'signIn';
export const AMPLIFY_SIGNOUT = 'signOut';
export const AMPLIFY_SIGNIN_FAILURE = 'signIn_failure';

export interface AWSAmplifyEventsProps {
    children: ReactNode;
    refreshSignIn: (data: any) => RefreshSignInAction;
    signin: (data: any) => SignInAction;
    signinFailure: (data: any) => SignInFailureAction;
    signout: (data: any) => SignOutAction;
    getCloudwatchLogGroup: (data: any) => GetCloudwatchLogGroupAction;
}

/**
 * Manage all AWS AmplifyAuth events
 *
 * @class AWSAmplifyEvents
 * @extends {Component}
 */
const AWSAmplifyEvents = ({
    children,
    refreshSignIn,
    signin,
    signinFailure,
    signout,
    getCloudwatchLogGroup
}: AWSAmplifyEventsProps) => {
    const { appSyncClient, config, AWS } = useContext(DependencyContext);

    useEffect(() => {
        const onAuthEvent = (payload: any) => {
            const authPayload = payload.payload;

            switch (authPayload.event) {
                case AMPLIFY_SIGNIN_FAILURE:
                    signinFailure(authPayload);
                    break;
                case AMPLIFY_SIGNIN:
                    Auth.currentUserCredentials().then(async credentials => {
                        /** store our new credentials **/
                        AWS.config.update({ credentials });

                        signin({
                            attributes: {
                                ...payload.attributes,
                                group: payload.signInUserSession
                                    ? payload.signInUserSession.idToken.payload['cognito:groups']
                                    : []
                            },
                            ...authPayload.data,
                            credentials
                        });
                        getCloudwatchLogGroup({});
                    });
                    break;
                case AMPLIFY_SIGNOUT:
                    signout(authPayload.data);
                    break;
                default:
                    break;
            }
        };

        Hub.listen('auth', onAuthEvent);

        Auth.currentAuthenticatedUser()
            .then(payload => {
                Auth.currentUserCredentials().then(credentials => {
                    /** store our new credentials **/
                    AWS.config.update({ credentials });

                    refreshSignIn({
                        attributes: {
                            ...payload.attributes,
                            group: payload.signInUserSession
                                ? payload.signInUserSession.idToken.payload['cognito:groups']
                                : []
                        },
                        credentials,
                        ...payload
                    });
                    getCloudwatchLogGroup({});
                });
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    return children;
};

const mapStateToProps = (state: ApplicationState): object => {
    return {
        congnitoSubId: state.user.attributes ? state.user.attributes.sub : ''
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            refreshSignIn: e => refreshSignInAction(e),
            signin: e => signInAction(e),
            signinFailure: e => signInFailureAction(e),
            signout: e => signOutAction(e),
            getCloudwatchLogGroup: e => getCloudwatchLogGroupAction(e)
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AWSAmplifyEvents as any);
