/* eslint-disable prettier/prettier */
/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Renders the landing page.
 */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Container from '@aws-prototyping/ui/components/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import awsConfig from 'Config/aws';
import { offsetPreservingSortedObservations } from 'util/controlHelpers';
import { useHistory } from "react-router-dom";

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { 
    getSubjectAction,
    putSubjectAction,
    setInstitutionIdAction,
    addDailyObservationAction,
    addSubjectAction
} from "Actions/creator/formData";

import { getSubjectIdsAction, setActiveSubjectIdAction, addNewSubjectAction, setSubjectSavedAction, addDailyObsAction } from "Actions/creator/ui";
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'util';

import { red, yellow, green } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '18px',
            marginTop: theme.spacing(3),
            overflowX: 'auto',
            padding: '0 4px',
            fontSize: '0.7rem'
        },
        table: {},
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0
        },
        badgeNotCompleted: {            
            marginRight: theme.spacing(3),
            backgroundColor: red[100],
            right: -3,
            top: 13,
            border: `1px solid white`
        },
        badgeSemiCompleted: {            
            marginRight: theme.spacing(3),
            backgroundColor: yellow[100],
            right: -3,
            top: 13,
            border: `1px solid white`,
            padding: '0 4px',
            fontSize: '0.7rem'
        },
        badgeCompleted: {            
            marginRight: theme.spacing(3),
            backgroundColor: green[100],
            right: -3,
            top: 13,
            border: `1px solid white`,
            padding: '0 4px',
            fontSize: '0.7rem'
        },
        badgeProgress: {            
            width: "100%"
        }
    })
);

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// test data
const sampleSubject = {
    "InstitutionId": "<unset>",
    "dataSourceGroup": "<unset>",
    "LastModifiedBy": "",
    "SubjectId": "subj_",
    "Version": 4,
    "eot_icu_admis": {
      "id": "1",
      "name": "eot_icu_admis",
      "questions": {}
    },
    "eot_start_mech_vent": {
      "id": "2",
      "name": "eot_start_mech_vent",
      "questions": {}
    },
    "eot_start_ecmo": {
      "id": "3",
      "name": "eot_start_ecmo",
      "questions": {}
    },
    "eot_daily": {
      "id": "4",
      "name": "eot_daily",
      "questions": []
    },
    "eot_final": {
      "id": "5",
      "name": "eot_final",
      "questions": {}
    },
    "uiRelease": awsConfig.uiRelease
};

const LandingPage = () => {
    const classes = useStyles({});
    const history = useHistory();
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const formData = useSelector(state => state.formData);
    const formRenderData = useSelector(state => state.formRenderData);
    const [activeSubjectId, setActiveSubjectId] = useState(ui.activeSubjectId);
    const [subjectSaved, setSubjectSaved] = useState(ui.subjectSaved);
    const userLogin = useSelector(state => state.user?.signInUserSession?.idToken?.jwtToken);
    const username = useSelector(state => state.user?.username);

    useEffect(() => {
        if (userLogin) {
            dispatch(setInstitutionIdAction(username));
            dispatch(getSubjectIdsAction(username));
        }
    }, [userLogin, username]);

    useEffect(() => {
        // KLUDGE: to refresh the list after we return from the admission save action
        //         - because it's async, the put subject success action may come
        //           after this initially reloads so the list would be stale
        //         - this is a kludge because hooking off activeSubectId changing is a
        //           side effect
        //         - will fix when ui state management is refactored next week
        if (activeSubjectId) {
            dispatch(getSubjectIdsAction(username));
        }
    }, [activeSubjectId]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // for the pilot deployment we'll use the DAGs that were provided by UQ

    const dataSourceGroupForInstitutionId = (institutionId) => {
        const clinicMappings = {
            'cnu': 'clinic_02',
            'snu': 'clinic_03',
            'fip': 'clinic_04',
            'buc': 'clinic_05',
            'uuh': 'clinic_06',
            'uop': 'clinic_07',
            'qmh': 'clinic_08',
            'tkh': 'clinic_10',
            'pch': 'clinic_11',
            'ukr': 'clinic_12',
            'hcb': 'clinic_13',
            'qeu': 'clinic_14',
            'hhc': 'clinic_15'
        };

        return (institutionId in clinicMappings ? clinicMappings[institutionId] : 'clinic_01');
    }

    const findMaxSubjectId = (subjectIds: []) => {
        let maxSubjectId = 0;
        const suffixLength = 4;

        subjectIds.map((subjectId) => {
            if (subjectId.length > suffixLength) {
                const subjectDigits = subjectId.slice(-suffixLength);
                const parsedSubjectId = Number(subjectDigits);

                if (!isNaN(parsedSubjectId) && parsedSubjectId > maxSubjectId) {
                    maxSubjectId = parsedSubjectId;
                }
            }

            return subjectId;
        });

        return maxSubjectId;
    }

    const getCompletePercentage = (activeSubjectId, formData, formRenderData, section) => {
        if(!(section in formData)) {
          return null;
        }
        if(!('questions' in formData[section])) {
          return null;
        }
        
        if (!activeSubjectId) {
          return null;
        }  
        if (section === 'eot_daily') {
          return null
        } else if (section === 'eot_start_mech_vent') {
          if (!formData.eot_start_mech_vent?.questions?.mv_datestart) {
            return null;
          }
        } else if (section === 'eot_start_ecmo') {
          if (!formData.eot_start_ecmo?.questions?.e_datestart) {
            return null;
          }
        }
      
        let counterMandatory = 0;
        let counterProvided = 0;
        for (const key of Object.keys(formRenderData[section]['questions'])) {
          const question = formRenderData[section]['questions'][key];
            
          if (question.data?.validation?.mustExist
            // && question.data?.type != FormDataDataQuestionTypes.YESNO
            ) {
            counterMandatory++;
            const fieldName = question['name'];
            if (fieldName in formData[section].questions) {
              counterProvided++;
            }
          }          
        }
      
        if (counterMandatory > 0) {
          const result = {
            'percentage': Math.round(counterProvided / counterMandatory * 100),
            'answered': counterProvided,
            'total': counterMandatory
          }
          return result;        
        }
        
        return null;
      };

    const showProgress = (section) => {
        const progress = getCompletePercentage(activeSubjectId, formData, formRenderData, section);

        if (isNull(progress)) {
            return {
                'classes' : classes.badgeNotCompleted,
                'percentage': 0
            };
        }

        const percentage = progress['percentage'];
        
        let badgeClass = null;
        
        if (percentage > 0 && percentage < 100) {
            badgeClass = classes.badgeSemiCompleted;
        } else if (percentage >= 100) {
            badgeClass = classes.badgeCompleted;;
        } else {
            badgeClass = classes.badgeNotCompleted;;
        }
        
        return {
            'classes' : badgeClass,
            'percentage': percentage+'%'
        };
    }

    const handleClose = (value, history) => {
        setAnchorEl(null);
        history.push('/obs');
    };

    const handleSave = async (event) => {                
        await dispatch(putSubjectAction(formData));        
        await dispatch(setInstitutionIdAction(username));
        await dispatch(getSubjectIdsAction(username));
        dispatch(setSubjectSavedAction());
        setSubjectSaved(true);
    };

    const handleDeleteObs = async(index) => {
        formData.eot_daily?.questions.splice(index, 1);
        await dispatch(putSubjectAction(formData));        
    }

    const subjectOnChange = (event, value) => {
        // todo: refactor this
        setActiveSubjectId(value);        
        setSubjectSaved(true);
        dispatch(setActiveSubjectIdAction(value));        
        if(!isNull(value)) {
            dispatch(getSubjectAction(username, value)); 
        } else {
            dispatch(getSubjectIdsAction(username));    
        }
    }

    const newSubjectOnClick = async (event) => {
        if (ui.subjectIds != null) {
            const newSubject = {...sampleSubject };
            const nextSubjectIndex = findMaxSubjectId(ui.subjectIds) + 1;
            const newSubjectId = nextSubjectIndex.toString().padStart(4, '0');

            newSubject.SubjectId = (username + '-' + newSubjectId);
            newSubject.InstitutionId = username;
            newSubject.dataSourceGroup = dataSourceGroupForInstitutionId(newSubject.InstitutionId); // TODO: get this value from oauth2 account details
            newSubject.LastModifiedBy = 'webgui:' + window.navigator.userAgent;

            await dispatch(addSubjectAction(newSubject));
            await dispatch(getSubjectIdsAction(username));
            await dispatch(setActiveSubjectIdAction(newSubject.SubjectId));
            await dispatch(addNewSubjectAction());
            history.push('/admission');
        }
    }

    const DailyObservations = (classes, formRenderData, formData) => {
        const result = formData.eot_daily?.questions.length == 0 ? [<div>none recorded</div>] : [];

        const obs = offsetPreservingSortedObservations(formData.eot_daily?.questions);

        for (let i = 0; i < obs.length; i++) {    
            result.push(
                <TableRow key={i}>
                    <TableCell>
                        Day {i + 1} ({moment(obs[i].ef_dateobs).format('YYYY-MM-DD')})
                    </TableCell>
                    <TableCell align="right">
                        <Tooltip title="Edit">
                            <IconButton aria-label="Edit" onClick={(event) => {
                                history.push(`/obs?instrumentDataOffset=${obs[i].index}`);
                            }}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={(event) => {
                                if (confirm("Are you sure to delete?")) {
                                    handleDeleteObs(obs[i].index);
                                }
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            );
        }
    
        return result;
    };

    // @ts-ignore
    return (
        <div> 
            <Container title="Subject Options">
                <Box m={1} pl={1} pr={1}>
                    <Grid container alignItems={'center'} justify={'center'} spacing={1} direction={'row'}>
                        <Grid item xs={10} align={'center'}>
                            <Box display={'flex'} maxWidth={600} alignItems={'center'}>
                                <Box flexGrow={1}>
                                    <Autocomplete
                                        id="subject-selector"
                                        options={ui.subjectIds == null ? [] : ui.subjectIds}
                                        getOptionLabel={(option) => option}
                                        onChange={subjectOnChange}
                                        value={ui.activeSubjectId}
                                        renderInput={(params) => <TextField {...params} label="Select Subject" variant="standard" />}
                                    />
                                </Box>                                
                                <Box alignItems={'center'} paddingTop={'9px'} paddingLeft={'9px'}>
                                    <Button
                                        size={'medium'}
                                        variant={'outlined'}
                                        fullWidth={false}
                                        onClick={newSubjectOnClick}
                                    >
                                        Admit New Subject
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={10} align={'center'}>
                            <Box maxWidth={600} display={activeSubjectId == null ? 'block' : 'none'}>
                                <Typography variant={'subtitle1'} display={'block'} gutterBottom>
                                    To modify an existing subject record, select from the drop-down list above,
                                    or admit a new patient to the study by selecting the 'Admit New Subject'
                                    button
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={10} align={'center'}>
                        {!subjectSaved && 
                            <Box maxWidth={600} display={activeSubjectId == null ? 'none' : 'block'}>
                                <Alert 
                                    severity={'info'}
                                    action={
                                        <Button color={'inherit'} size={'small'} onClick={handleSave}>
                                        {'Save now'}   
                                        </Button>
                                    }                                        
                                >
                                    There is currently unsaved subject data
                                </Alert>
                            </Box>
                        }
                        {!subjectSaved && 
                            <br/>
                        }
                        </Grid>

                        <Grid item xs={10} align={'center'}>
                            <Box maxWidth={600} display={activeSubjectId == null ? 'none' : 'block'}>
                                <Badge badgeContent={getCompletePercentage(activeSubjectId, formData, formRenderData, 'eot_icu_admis')?.percentage == 0 ? 0 : showProgress('eot_icu_admis')['percentage']}
                                    className={classes.badgeProgress}
                                    classes={{ badge: showProgress('eot_icu_admis')['classes'] }}
                                    display={getCompletePercentage (activeSubjectId, formData, formRenderData, 'eot_icu_admis') == 0 ? 'none' : 'block'}
                                    >

                                    <Button
                                        disabled={activeSubjectId == null}
                                        fullWidth={true}                                
                                        size={'large'}
                                        variant={'contained'}
                                        onClick={() => history.push('/admission')}
                                    >
                                        {'Patient Intake Data'}
                                    </Button>
                                </Badge>
                                <Typography variant={'caption'} display="block" gutterBottom>
                                    {
                                        activeSubjectId == null || !formData.eot_icu_admis?.questions?.ia_dateicu ? 'no data entered' :
                                        `Admitted: ${formData.eot_icu_admis?.questions.ia_dateicu}`
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                        
                        <Grid item xs={10} align={'center'}>
                            <Box maxWidth={600} display={activeSubjectId == null ? 'none' : 'block'}>
                                <Grid container alignItems={'center'} justify={'center'} spacing={1} direction={'row'}>
                                    <Grid item xs={6} align={'center'}>
                                        <Box maxWidth={295}>
                                            <Badge badgeContent={getCompletePercentage(activeSubjectId, formData, formRenderData, 'eot_start_mech_vent')?.percentage == 0 ? 0 : showProgress('eot_start_mech_vent')['percentage']} 
                                                className={classes.badgeProgress} 
                                                classes={{ badge: showProgress('eot_start_mech_vent')['classes'] }} 
                                                >

                                                <Button
                                                    disabled={activeSubjectId == null}
                                                    fullWidth={true}
                                                    size={'large'}
                                                    variant={'contained'}
                                                    onClick={() => history.push('/mech')}
                                                >
                                                    Mechanical Ventilation                                                
                                                </Button>
                                            </Badge>
                                            <br/>
                                            <Typography variant={'caption'} display="block" gutterBottom>
                                                {
                                                    activeSubjectId == null || !formData.eot_start_mech_vent?.questions?.mv_datestart ? 'no data entered' :
                                                    `Commenced: ${formData.eot_start_mech_vent?.questions?.mv_datestart}`
                                                }                                        
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} align={'center'}>
                                        <Box maxWidth={295}>
                                            <Badge badgeContent={getCompletePercentage(activeSubjectId, formData, formRenderData, 'eot_start_ecmo')?.percentage == 0 ? 0 : showProgress('eot_start_ecmo')['percentage']} 
                                                className={classes.badgeProgress} 
                                                classes={{ badge: showProgress('eot_start_ecmo')['classes'] }} 
                                                >
                                                <Button
                                                    disabled={activeSubjectId == null}
                                                    fullWidth={true}
                                                    size={'large'}
                                                    variant={'contained'}
                                                    onClick={() => history.push('/ecmo')}
                                                >
                                                    ECMO Ventilation                                                
                                                </Button>
                                            </Badge>
                                            <br/>
                                            <Typography variant={'caption'} display="block" gutterBottom>
                                            {
                                                activeSubjectId == null || !formData.eot_start_ecmo?.questions?.e_datestart ? 'no data entered' :
                                                `Commenced: ${moment(formData.eot_start_ecmo?.questions?.e_datestart).format('YYYY-MM-DD')}`
                                            }
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={10} align={'center'}>
                            <Box maxWidth={600} display={activeSubjectId == null ? 'none' : 'block'}>                                
                                {activeSubjectId != null &&
                                <ExpansionPanel style={{boxShadow: 'none'}}>
                                    <ExpansionPanelSummary style={{padding: '0px', marginTop: '-12px', marginBottom: '-12px'}} expandIcon={<ExpandMoreIcon />}>
                                        <Button
                                            disabled={activeSubjectId == null}
                                            fullWidth={true}
                                            size={'large'}
                                            variant={'contained'}
                                            onClick={() => {
                                                dispatch(addDailyObservationAction());
                                                dispatch(addDailyObsAction()); // Update the view
                                                history.push('/obs?instrumentDataOffset=-1');
                                            }}
                                        >
                                            Add Daily Observation
                                        </Button>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {formData.eot_daily?.questions.length > 0 &&
                                        <TableContainer component={Paper} elevation={0}>
                                            <Table className={classes.table} size="small" aria-label="Daily Observations">
                                                <TableBody>
                                                {DailyObservations(classes, formRenderData, formData)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        }
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                }
                            </Box>                        
                            <Typography variant={'caption'} display="block" gutterBottom>
                                {
                                    activeSubjectId == null || !formData.eot_daily?.questions.length > 0 ? 'no data entered' :
                                    `Last observation: ${formData.eot_daily?.questions[formData.eot_daily.questions.length-1]?.ef_dateobs} (total: ${formData.eot_daily?.questions.length})`
                                }
                            </Typography>
                        </Grid>

                        <Grid item xs={10} align={'center'}>
                            <Box maxWidth={600} display={activeSubjectId == null ? 'none' : 'block'}>
                                <Badge badgeContent={getCompletePercentage(activeSubjectId, formData, formRenderData, 'eot_final')?.percentage == 0 ? 0 : showProgress('eot_final')['percentage']}
                                    className={classes.badgeProgress}
                                    classes={{ badge: showProgress('eot_final')['classes'] }}
                                    >
                                    <Button
                                        disabled={activeSubjectId == null}
                                        fullWidth={true}
                                        size={'large'}
                                        variant={'contained'}
                                        onClick={() => history.push('/complete')}>
                                        Complete Outcome                                    
                                    </Button>
                                </Badge>
                                <Typography variant={'caption'} display="block" gutterBottom>
                                    {
                                        activeSubjectId == null || !formData.eot_final?.questions?.f_dateicu ? 'no data entered' :
                                        `Date of discharge: ${formData.eot_final?.questions?.f_dateicu}`
                                    }
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={10} align={'center'}>
                            <Box maxWidth={600} display={activeSubjectId == null ? 'none' : 'block'}>
                                <Button
                                    disabled={activeSubjectId == null}
                                    fullWidth={true}
                                    size={'large'}
                                    variant={'contained'}
                                    onClick={() => history.push('/review')}>
                                    Review Subject
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
};

export default LandingPage;
