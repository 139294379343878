/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect, Dispatch } from 'react';
import Radio, { RadioProps as MuiRadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'Utils/useStore'
import { useSelector } from 'react-redux';
import { isDisabled } from 'Utils/controlHelpers';
import { uuid } from 'uuidv4';
import * as _ from 'lodash'

import TableCell from '@material-ui/core/TableCell';

import { initialState } from 'Reducers/formData';
import { modifySubjectAction } from "Actions/creator/ui";

const DEBOUNCE_TIME = 250;

export interface EcmoDataProps extends MuiRadioProps {
  instrument: string;
  name: string;
}


const useStyles = makeStyles((theme) => ({
  radio: {
    marginRight: '-15px',
    '&$checked': {
      color: green[500]
    }
  },
  checked: {}

}));

export const RadioInput: React.FC<EcmoDataProps> = (props) => {

  const classes = useStyles();

  // const answers = useSelector(state => state['formData'][props.instrument]['questions']);
  const answers = useSelector(
    state => (props.instrument in state.formData) ? 
      (props.instrumentdataoffset > -1 ? 
        state.formData[props.instrument]['questions'][props.instrumentdataoffset] 
        : state.formData[props.instrument]['questions']
      ) 
    : initialState[props.instrument]['questions']
  );
  const answer = props.name in answers ? answers[props.name] : false;
  const [question, dispatch] = useStore(props.name, props.instrument);
  const { name, data, parentId, id, label, units } = question;
  const { instrument } = props;
  const { review } = props;

  const [value, setValue] = useState(answer || data?.default || undefined)
  const [errorText, setErrorText] = React.useState<string | null>()
  const [error, setError] = React.useState<boolean | null>(false)

  useEffect(() => {
    if (!review) {
      dispatch({
        type: 'UPDATE',
        data: { name, value, instrument, instrumentdataoffset: props.instrumentdataoffset }
      })
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    
    dispatch(modifySubjectAction());

    _.debounce((e) => {
      const val = e.target.value;
      setError(false)
      setValue(val)
    }, DEBOUNCE_TIME)(event)
  };

  const helperText = (): string => {
    if (error)
      return errorText

    if (question?.comments?.length)
      return question?.comments[0]
  }

  const isChecked = (option: any): boolean => {
    let retVal = false
    if (!value && (option.value === data?.default)) {
      retVal = true;
    } else {
      if (value === option.value)
        return true
    }

    return retVal;
  }

  const disabled = isDisabled(question, answers)

  return (
    review ? 
      <>
        <TableCell width="60%" component="th" scope="row" align="right">
        {`${parentId}.${id} ${label}`}
        </TableCell>
        <TableCell width="40%" align="left">{data.options?.map(option => (parseInt(option.value) == value ? option.name : ''))}</TableCell>
      </>
    :
    <FormControl
      key={uuid()}
      component="fieldset"
      disabled={disabled}
      className={classes.root}
    >
      <FormLabel component="legend">{`${parentId}.${id} ${label}`} </FormLabel>
      <FormHelperText >{helperText()}</FormHelperText>
      <RadioGroup
        name={name}
        row={data.options.length <= 3 || false}
        onChange={handleChange}>
        {
          data.options?.map(option => {
            return (
              <FormControlLabel
                key={uuid()}
                value={option.value}
                label={option.name}
                control={<Radio
                  disableRipple={true}
                  size='small'
                  checked={isChecked(option)}
                  classes={{ root: classes.radio, checked: classes.checked }}
                />}
              />
            )
          })
        }
      </RadioGroup>
    </FormControl>
  )


}