/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import React from 'react';
import { Switch } from 'Components/stateful/Switch';
import { TextInput } from 'Components/stateful/TextInput';
import { NumberInput } from 'Components/stateful/NumberInput';
import { IntegerInput } from 'Components/stateful/IntegerInput';
import { DateTimeInput } from 'Components/stateful/DateTimeInput';
import { RadioInput } from 'Components/stateful/RadioInput';
import { AutoComplete } from 'Components/stateful/AutoComplete';

interface InputFactoryMethodParams {
    instrument: string;
    instrumentDataOffset: -1;
    name: string;
}

// -- generates input components for given REDCAP types

export default class REDCapFormFactory {
    static registry = {
        DATE: REDCapFormFactory.newDATE,
        NUMBER: REDCapFormFactory.newNUMBER,
        INTEGER: REDCapFormFactory.newINTEGER,
        DROPDOWN: REDCapFormFactory.newDROPDOWN,
        YESNO: REDCapFormFactory.newYESNO,
        RADIO: REDCapFormFactory.newRADIO,
        TEXT: REDCapFormFactory.newTEXT
    };

    static newNUMBER(props: InputFactoryMethodParams) {
        return <NumberInput {...props} />;
    }
    static newINTEGER(props: InputFactoryMethodParams) {
        return <IntegerInput {...props} />;
    }
    static newDATE(props: InputFactoryMethodParams) {
        return <DateTimeInput {...props} />;
    }
    static newDROPDOWN(props: InputFactoryMethodParams) {
        return <AutoComplete {...props} />;
    }
    static newYESNO(props: InputFactoryMethodParams) {
        return <Switch {...props} />;
    }
    static newRADIO(props: InputFactoryMethodParams) {
        return <RadioInput {...props} />;
    }
    static newTEXT(props: InputFactoryMethodParams) {
        return <TextInput {...props} />;
    }

    static controlForType(
        instrument: string,
        instrumentDataOffset: int,
        name: string,
        controlType: string,
        forReview?: boolean
    ) {
        const instrumentdataoffset = instrumentDataOffset;
        const review = forReview ? 1 : 0;
        return REDCapFormFactory.registry[controlType]({ instrument, instrumentdataoffset, name, review });
    }

    static controlsForInstrumentQuestions(
        instrument: string,
        instrumentDataOffset: int,
        questions: {},
        forReview?: boolean
    ) {
        const controls = Object.keys(questions).map(key => {
            return REDCapFormFactory.controlForType(
                instrument,
                instrumentDataOffset,
                questions[key].name,
                questions[key].type,
                forReview
            );
        });
        return controls;
    }
}
