/* eslint-disable prettier/prettier */
/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * ALl supported User action types.
 */
export enum FormRenderDataActions {
    NO_OP = 'NO_OP'
}

export enum FormDataDataQuestionTypes {
    DESCRIPTIVE = 'DESCRIPTIVE',
    RADIO = 'RADIO',
    FILE = 'FILE',
    YESNO = 'YESNO',
    TEXT = 'TEXT',
    INTEGER = 'INTEGER',
    NUMBER = 'NUMBER',
    DROPDOWN = 'DROPDOWN',
    DATE = 'DATE'
}

export enum FormDataDataQuestionOperators {
    AND = 'AND',
    OR = 'OR'
}

export enum FormDataDataQuestionDropdownSelect {
    SINGLE = 'SINGLE',
    MULTI = 'MULTI'
}

export interface QuestionData {
    validation?: {
        min?: number | string;
        max?: number | string;
        mustExist?: boolean;
        format?: string;
    };
    default?: string | boolean;
    type?: FormDataDataQuestionDropdownSelect,
    options?: unknown;
    setIfExists?: [{
        id: string;
        name: string;
        value: string | boolean;
    }];
}

export interface Question {
    id: string;
    parentId?: string;
    parentName?: string;
    label: string;
    name: string;
    comments?: string[];
    type: FormDataDataQuestionTypes;
    predecessors?: string[];
    predecessorHasValue?: [
        {
            name: string;
            value: boolean | string | number;
            operator?: FormDataDataQuestionOperators;
        }
    ];
    exclusiveTo?: string[];
    relatedTo?: string[];
    placeholder?: string;
    units?: string;
    data?: QuestionData;
}

export interface Questions {
    [id: string]: Question;
}

export interface Instrument {
    id: string;
    name: string;
    header?: string;
    comments?: string;
    questions: Questions;
}

export interface FormRenderData {
    [instrument: string]: Instrument;
}

/**
 * Action definition to sign in.
 */
export interface NoOpAction {
    type: typeof FormRenderDataActions.NO_OP;
}

/**
 * All User Actions can be 'subclassed' to UserActionTypes.
 */
export type FormRenderDataActionTypes = NoOpAction;
