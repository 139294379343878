/* eslint-disable prettier/prettier */

import {
  FormData,
  FormDataActions,
  FormDataActionTypes,
} from 'Actions/definition/formData';

import { UserActions } from 'Actions/definition/user';

export const initialState: FormData = {
  'InstitutionId': null,
  'dataSourceGroup': null,
  'SubjectId': null,
  'eot_icu_admis': {
    id: '1',
    name: 'eot_icu_admis',
    questions: {}
  },
  'eot_start_mech_vent': {
    id: '2',
    name: 'eot_start_mech_vent',
    questions: {}
  },
  'eot_start_ecmo': {
    id: '3',
    name: 'eot_start_ecmo',
    questions: {}
  },
  'eot_daily': {
    id: '4',
    name: 'eot_daily',
    questions: []
  },
  'eot_final': {
    id: '5',
    name: 'eot_final',
    questions: {}
  }
};

export function formDataReducer(
  formData: FormData = initialState,
  action: FormDataActionTypes
): FormData {
  switch (action.type) {
    case FormDataActions.NO_OP:
      return formData;
      break;

    // clear any user specific state
    case UserActions.SIGNOUT_SUCCESS:
      return {
        ...initialState
      };

    case FormDataActions.GET_SUBJECT_SUCCESS:
      return {
          ...action.subject
      };

    case FormDataActions.PUT_SUBJECT_SUCCESS:
      action.subject.Version++;
      return {
        ...action.subject
      };

    case FormDataActions.UPDATE: {
      const instrument = action.data.instrument
      const name = action.data.name;
      const value = action.data.value;
      
      if (!(instrument in formData)) {
        formData[instrument] = initialState[instrument];
      }
      const questions = (instrument === 'eot_daily' ? [...formData[instrument]['questions']] : { ...formData[instrument]['questions'] });

      if (instrument === 'eot_daily') {             
        const instrumentdataoffset = action.data.instrumentdataoffset   
        if (instrumentdataoffset > -1) {
          questions[instrumentdataoffset][name] = value;
        } else {
          questions[questions.length-1][name] = value;
        }
        
      }
      else {
        questions[name] = value;
      }

      return {
        ...formData,
        [instrument]: {
          ...formData[instrument],
          'questions': questions
        }
      }};

    case FormDataActions.SET_INSTITUTION_ID:
      return {
        ...formData,
        InstitutionId: action.institutionId
      };
      break;

    case FormDataActions.CLEAR_INSTITUTION_ID:
      return {
        ...formData,
        InstitutionId: null
      };
      break;

    case FormDataActions.SET_SUBJECT_ID:
      return {
        ...formData,
        SubjectId: action.subjectId
      };
      break;

    case FormDataActions.CLEAR_SUBJECT_ID:
      return {
        ...formData,
        SubjectId: null
      };
      break;

    case FormDataActions.ADD_DAILY_OBSERVATION:
      let result = { ...formData };
      const questions = 'eot_daily' in formData ? formData.eot_daily.questions : [];
      result.eot_daily = {
        id: '4',
        name: 'eot_daily',
        questions: [...questions, {}]
      };
      return result;

    case FormDataActions.ADD_SUBJECT:
      return { ...action.subject };

    default:
      return formData;
  }
}
