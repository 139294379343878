/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'Utils/useStore'
import { usePrevious } from 'Utils/usePrevious'
import * as _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux';
import { isDisabled, setIfExits } from 'Utils/controlHelpers'

import TableCell from '@material-ui/core/TableCell';

import { initialState } from 'Reducers/formData';
import { modifySubjectAction } from "Actions/creator/ui";

const DEBOUNCE_TIME = 250;
const INPUT_TYPE = "date"

export interface EcmoDataProps extends MuiTextFieldProps {
  instrument: string;
  name: string;
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  labelRoot: {
    color: "black",
    "&$labelFocused": {
      color: "blue"
    },
    // paddingBottom: 10
  },
  labelFocused: {},
  inputRoot: {
    paddingTop: 10
  },
  inputInput: {
  }
}));

export const DateTimeInput: React.FC<EcmoDataProps> = (props) => {

  const classes = useStyles();

  //const answers = useSelector(state => state.formData[props.instrument].questions);
  const answers = useSelector(
    state => (props.instrument in state.formData) ? 
      (props.instrumentdataoffset > -1 ? 
        state.formData[props.instrument]['questions'][props.instrumentdataoffset] 
        : state.formData[props.instrument]['questions']
      ) 
    : initialState[props.instrument]['questions']
  );

  const answer = answers[props.name];
  const [question, dispatch] = useStore(props.name, props.instrument);

  const { name, data, parentId, id, label, units } = question;
  const { instrument } = props;
  const { review } = props;

  const [value, setValue] = useState(answer || data?.default || moment().format('YYYY-MM-DD'))
  const prevValue = usePrevious(value)

  const [errorText, setErrorText] = React.useState<string | null>()
  const [error, setError] = React.useState<boolean | null>(false)
  const [hasSetIfExists, setHasSetIfExists] = useState(false)

  useEffect(() => {
    if (prevValue !== value && !review) {
      dispatch({
        type: 'UPDATE',
        data: { name, value, instrument, instrumentdataoffset: props.instrumentdataoffset }
      })
      
      value && setIfExits(question, hasSetIfExists, setHasSetIfExists, dispatch, props.instrument)
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    dispatch(modifySubjectAction());

    _.debounce((e) => {
      const newValue = e.target.value;
      setError(false)
      setValue(newValue)
    }, DEBOUNCE_TIME)(event)
  };

  const helperText = (): string => {
    if (error)
      return errorText

    if (question?.comments?.length)
      return question?.comments[0]
  }

  return (
    review ? 
      <>
        <TableCell width="60%" component="th" scope="row" align="right">
        {`${parentId}.${id} ${label}`}
        </TableCell>
        <TableCell width="40%" align="left">{answer}</TableCell>
      </>
     :
    <FormControl
      component="fieldset"
      className={classes.root}
      disabled={isDisabled(question, answers)}
    >

      <MuiTextField
        {...props}
        variant={"standard"}
        onChange={handleChange}
        defaultValue={moment(answer).format('YYYY-MM-DD') || moment(data?.default).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD')}
        label={`${parentId}.${id} ${label}`}
        placeholder="Enter observation here"
        required={data?.validation?.mustExist || false}
        type={INPUT_TYPE}
        InputProps={{
          endAdornment: units && <InputAdornment position="end">{units}</InputAdornment>,
          inputProps: {
            min: data?.validation?.min,
          },
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          }
        }}
        error={error}
        InputLabelProps={
          {
            shrink: true,
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
        fullWidth
        margin="normal"
        helperText={helperText()}
      />
    </FormControl>    
  )


}