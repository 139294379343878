/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Public route which requires authentication.
 */
import Amplify from 'aws-amplify';
import { ForgotPassword, RequireNewPassword, SignIn, withAuthenticator } from 'aws-amplify-react';
import awsconfig from 'Config/aws';
import { authTheme } from 'Config/theme';
import React, { Component, ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { I18n } from 'aws-amplify';

const authConfig = awsconfig.auth;

Amplify.configure({
    mandatorySignIn: true,
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    identityPoolId: authConfig.identityPoolId,
    userPoolWebClientId: authConfig.userPoolWebClientId,
    oauth: {
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:8080/',
        redirectSignOut: 'http://localhost:8080/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
});

interface SecureRouteProps {
    component: ReactNode;
    layout: ReactNode;
}

const authScreenLabels = {
    en: {
        Username: 'Institution',
        'Enter your username': 'Enter your institution name'
    }
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

/**
 * Renders a page comprising of a layout which contains a component only if the user is authenticated. If unauthenticated,
 * the user is redirected to a login page via the withAuthenticator HoC.
 *
 * @param Component component view to render.
 * @param Layout layout stateful.
 * @param rest properties.
 */
const SecureRoute = ({ component: Component, layout: Layout, ...rest }: SecureRouteProps) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    // @ts-ignore
                    <Layout>
                        {/*
                        // @ts-ignore */}
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
};

export default withAuthenticator(
    SecureRoute,
    false,
    [<SignIn key={undefined} />, <RequireNewPassword key={undefined} />],
    null,
    authTheme
);
