/* eslint-disable prettier/prettier */

import moment from 'moment';

export const helperText = (question, error, errorText): string => {
  let retVal: string;
  const { comments } = question;

  if (error)
    retVal = errorText
  else if (comments?.length)
    retVal = comments[0]

  return retVal;
}

export const isDisabled = (question, answers) => {
  const ENABLE_CONTROL = false
  const DISABLE_CONTROL = true

  let retVal = ENABLE_CONTROL;

  if (!_(question).has('predecessors'))
    return retVal;

  question['predecessors'].map(predecessorName => {
    let predecessorType = null;
    let predecessorValue = null;

    if (_(answers).has(predecessorName)) {
      predecessorType = typeof answers[predecessorName]
      predecessorValue = answers[predecessorName];
    }
    else {
      predecessorType = null;
      predecessorValue = '';
    }

    switch (predecessorType) {
      case 'number':
        retVal = retVal || (predecessorValue == 1 ? ENABLE_CONTROL : DISABLE_CONTROL)
        break;
      case 'boolean':
          retVal = retVal || (predecessorValue ? ENABLE_CONTROL : DISABLE_CONTROL)
          break;
      case 'string':
        retVal = retVal && (predecessorValue.length ? ENABLE_CONTROL : DISABLE_CONTROL)
        break;
      case 'object':
        // remember typeof(null) === object
        retVal = retVal || (predecessorValue !== null ? ENABLE_CONTROL : DISABLE_CONTROL)
        break;
      default:
        retVal = DISABLE_CONTROL;
        console.log(`[Unknown predecessor type] ${question.name} -> ${predecessorValue} of type ${predecessorType}`)
    }
  })
  return retVal;
}

export const setIfExits = (storeState, hasSetIfExists, setHasSetIfExists, dispatch, instrument): void => {
  if (!hasSetIfExists && _(storeState).has('data.setIfExists')) {
    storeState.data.setIfExists.map(el => {
      const { name, value } = el;
      setHasSetIfExists(true);
      dispatch({
        type: 'UPDATE',
        data: { name, value, instrument }
      })
    })

  }
}

// immutably sort the array of daily observations,
// tracking each day's original offset
export const offsetPreservingSortedObservations = (observations: []): [] => {
  let obs = JSON.parse(JSON.stringify(observations));
  for (let idx = 0; idx < obs.length; idx++) {
    obs[idx].index = idx;
  }
  obs.sort((a: any, b: any) => {
    if (moment(a.ef_dateobs).isAfter(b.ef_dateobs, 'day')) {
      return 1;
    }
    else if (moment(b.ef_dateobs).isAfter(a.ef_dateobs, 'day')) {
      return -1;
    }
    else {
      return 0;
    }
  });
  return obs;
}
