/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prettier/prettier */

import React, { useState, useEffect } from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useStore } from 'Utils/useStore'
import * as _ from 'lodash'
import { useSelector } from 'react-redux';
import { isDisabled, setIfExits } from 'Utils/controlHelpers'

import TableCell from '@material-ui/core/TableCell';

import { initialState } from 'Reducers/formData';
import { modifySubjectAction } from "Actions/creator/ui";

const DEBOUNCE_TIME = 250;
const INPUT_TYPE = "value"

export interface EcmoDataProps extends MuiTextFieldProps {
  instrument: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 15
  }

}));

export const TextInput: React.FC<EcmoDataProps> = (props) => {

  const classes = useStyles();

  const answer = useSelector(
    state => (props.instrument in state.formData) ? 
      (props.instrumentdataoffset > -1 ? 
        state.formData[props.instrument]['questions'][props.instrumentdataoffset]
        : state.formData[props.instrument]['questions']
      ) 
    : initialState[props.instrument]['questions']
  );

  const [question, dispatch] = useStore(props.name, props.instrument);
  const [value, setValue] = useState(null)
  const [hasSetIfExists, setHasSetIfExists] = useState(false)

  const { name, data, parentId, id, label, units } = question;
  const { instrument } = props;
  const { review } = props;

  useEffect(() => {
    if (!review) {
      dispatch({
        type: 'UPDATE',
        data: { name, value, instrument, instrumentdataoffset: props.instrumentdataoffset }
      })
      
      value && setIfExits(question, hasSetIfExists, setHasSetIfExists, dispatch, props.instrument)
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    dispatch(modifySubjectAction());
    
    _.debounce((e) => {
      setValue(e.target.value)
    }, DEBOUNCE_TIME)(event)
  };

  return (
    review ? 
      <>
        <TableCell width="60%" component="th" scope="row" align="right">
        {`${parentId}.${id} ${label}`}
        </TableCell>
        <TableCell width="40%" align="left">{answer[props.name]}</TableCell>
      </>
     :
    <FormControl
      component="fieldset"
      className={classes.root}
      disabled={isDisabled(question, answer)}
    >

      <MuiTextField
        id={name}
        classes={{ root: classes.root }}
        {...props}
        variant={"standard"}
        onChange={handleChange}
        label={`${parentId}.${id} ${label}`}
        placeholder="Enter observation here"
        required={data?.validation?.mustExist || false}
        defaultValue={answer[props.name] || data?.default || undefined}
        type={INPUT_TYPE}
        InputProps={{
          endAdornment: units && <InputAdornment position="end">{units}</InputAdornment>
        }}
        InputLabelProps={{
          shrink: true,
          classes: { root: classes.formControl }
        }}
        fullWidth
        // margin="normal"
        helperText={question?.comments?.length ? question?.comments[0] : ''}
      />
    </FormControl>
  )


}