/* eslint-disable prettier/prettier */

import {
  FormRenderData,
  FormRenderDataActions,
  FormRenderDataActionTypes,
  FormDataDataQuestionTypes,
  FormDataDataQuestionOperators,
  FormDataDataQuestionDropdownSelect
} from 'Actions/definition/formRenderData'

import { UserActions } from 'Actions/definition/user';

const date = new Date()
const initialState: FormRenderData = {
  'eot_icu_admis': {
    id: '1',
    name: 'Admission',
    header: 'EOT ICU Admis',
    questions: {
      '0': {
        id: '',
        label: 'Date of hospital admission',
        name: 'daily_dsstdat',
        units: 'dd/mm/yyyy',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            mustExist: true,
            format: 'YYYY-MM-DD',
            min: '2019-12-14'
          }
        }
      },
      '1': {
        id: '0',
        label: 'Date of ICU admission',
        name: 'ia_dateicu',
        units: 'dd/mm/yyyy',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            mustExist: true,
            format: 'YYYY-MM-DD',
            min: '2019-12-14'
          }
        }
      },
      '2': {
        id: '1',
        label: 'Height on admission to ICU',
        name: 'ia_height',
        units: 'cm',
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            mustExist: true,
            min: 30,
            max: 300
          }
        }
      },
      '3': {
        id: '2',
        name: 'ia_weight',
        label: 'Weight on admission to ICU',
        units: 'Kgs',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            mustExist: true,
            min: 30,
            max: 400
          }
        }
      },
      '4': {
        id: '3',
        name: 'ia_arthyper',
        label: 'Arterial Hypertension',
        comments: ['The chronic use of therapy for the indication of blood pressure-lowering, prior to hospital admission.'],
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true }
        },
        relatedTo: ['4b.2', '4b.3']
      },
      '5': {
        id: '3a',
        name: 'ia_hyprthrpy',
        label: 'Chronic anti-hypertensive therapy',
        type: FormDataDataQuestionTypes.YESNO,
        predecessors: ['ia_arthyper'] //1.3
      },
      '6': {
        id: '3b',
        name: 'ia_hyprthrpyc',
        label: 'Class of ongoing anti-hypertensive therapy',
        comments: ['Select maximum of 3'],
        type: FormDataDataQuestionTypes.DROPDOWN,
        predecessors: ['ia_hyprthrpy'], //1.3a
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          validation: {
            max: 3
          },
          options: [
            { value: '1', label: 'Diuretics' },
            { value: '2', label: 'Calcium channel blockers' },
            { value: '3', label: 'ACE inhibitors' },
            { value: '4', label: 'Angiotensin II receptor antagonists' },
            { value: '5', label: 'Renin inhibitors' },
            { value: '6', label: 'Beta-blockers' },
            { value: '7', label: 'Alpha-blockers' },
            { value: '8', label: 'Vasodilators' },
            { value: '9', label: 'Aldosterone receptor antagonist' },
            { value: '10', label: 'Alpha-2 adrenergic receptor agonists' },
          ]
        }
      },
      '7': {
        id: '4',
        name: 'ia_gastroco',
        label: 'Gastrointestinal and Pancreatic Comorbidities',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: {
            mustExist: true 
          }
        }
      },
      '8': {
        id: '5',
        name: 'ia_hepaticco',
        label: 'Hepatic And Biliary Comorbidities',
        type: FormDataDataQuestionTypes.YESNO,
        relatedTo: ['4b.5', '4b.6'],
        data: {
          validation: {
            mustExist: true
          }
        }
      },
      '9': {
        id: '6',
        name: 'ia_haemaco',
        label: 'Haematologic And Spleen Comorbidities',
        type: FormDataDataQuestionTypes.YESNO,
        relatedTo: ['4b.9'],
        data: {
          validation: { mustExist: true }
        }
      },
      '10': {
        id: '7',
        name: 'ia_immco',
        label: 'Immunological and transplant comorbidities',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true }
        }
      },
      '11': {
        id: '8',
        name: 'ia_endoco',
        label: 'Endocrinological comorbidities',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true }
        }
      },
      '12': {
        id: '9',
        name: 'ia_genitco',
        label: 'Genito-Urinary comorbidities',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true }
        }
      },
      '13': {
        id: '10',
        name: 'ia_alcohol',
        label: 'Chronic alcohol abuse',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true },
          default: false
        }
      },
      '14': {
        id: '11',
        name: 'ia_drug',
        label: 'Intravenous drugs abuse',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true },
          default: false
        }
      },
      '15': {
        id: '12',
        name: 'ia_immcompet',
        label: 'Immuno-Competent',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          validation: { mustExist: true },
          default: true
        }
      },
      '16': {
        id: '13',
        name: 'ia_apache',
        label: 'Apache II Score',
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 0,
            max: 71
          },
          setIfExists: [{
            id: '1.13a',
            name: 'ia_apache_yn',
            value: true
          }]
        }
      },
      '17': {
        id: '14',
        name: 'ia_sofa',
        label: 'Sofa Score',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 24
          },
          setIfExists: [{
            id: '1.14a',
            name: 'ia_sofa_yn',
            value: true
          }]
        }
      },
      '18': {
        id: '15',
        name: 'ia_artph',
        label: 'Worst arterial pH in the last 6 Hours',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 6.5,
            max: 7.6
          },
          setIfExists: [{
            id: '1.15a',
            name: 'ia_artph_yn',
            value: true
          }]
        }
      },
      '19': {
        id: '16',
        name: 'ia_artppo',
        label: 'Worst arterial Partial pressure of Oxygen in the last 6h',
        units: 'mmHg',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 10,
            max: 500
          },
          setIfExists: [{
            id: '1.16a',
            name: 'ia_artppo_yn',
            value: true
          }]
        }
      },
      '20': {
        id: '17',
        name: 'ia_artco2',
        label: 'Worst arterial Partial pressure of Carbon Dioxide in the last 6h',
        units: 'mmHg',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 10,
            max: 100
          },
          setIfExists: [{
            id: '1.17a',
            name: 'ia_artppo_yn',
            value: true
          }]
        }
      },
      '21': {
        id: '18',
        name: 'ia_arthco3',
        label: 'Worst arterial HCO3 in the last 6h',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 1,
            max: 50
          },
          setIfExists: [{
            id: '1.18a',
            name: 'ia_arthco3_yn',
            value: true
          }]
        }
      },
      '22': {
        id: '18u',
        name: 'ia_arthco3_u',
        label: 'HCO3 units',
        type: FormDataDataQuestionTypes.RADIO,
        predecessors: ['ia_arthco3'], //18
        data: {
          validation: {
            mustExist: true
          },
          default: '1',
          options: [
            { name: 'mEq/L', value: '1' },
            { name: 'mmol/L', value: '2' }
          ]
        }
      },
      '23': {
        id: '19',
        name: 'ia_artbe',
        label: 'Worst arterial Base excess in the 6h',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: -50,
            max: 50
          },
          setIfExists: [{
            id: '1.19a',
            name: 'ia_artbe_yn',
            value: true
          }]
        }
      },
      '24': {
        id: '20',
        name: 'ia_lactate',
        label: 'Worst Lactate in the last 6h',
        type: FormDataDataQuestionTypes.TEXT,
        data: {
          setIfExists: [{
            id: '1.20a',
            name: 'ia_lactate_yn',
            value: true
          }]
        }
      },
      '25': {
        id: '21',
        name: 'ia_ferritin',
        label: 'FERRITIN (ng/mL)',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 1000
          },
          setIfExists: [{
            id: '1.21a',
            name: 'ia_ferritin_yn',
            value: true
          }]
        }
      },
      '26': {
        id: '22',
        name: 'ia_ddimer',
        label: 'D-dimer in the last 12 hours',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 15000
          },
          setIfExists: [{
            id: '1.22a',
            name: 'ia_ddimer_yn',
            value: true
          }]
        }
      },
      '27': {
        id: '22b',
        name: 'ia_ddimer_u',
        label: 'D-dimer unit of measurement',
        type: FormDataDataQuestionTypes.RADIO,
        predecessors: ['ia_ddimer'], //22
        data: {
          default: '1',
          options: [
            { name: 'ng/mL', value: '1' },
            { name: 'mcg/mL', value: '2' }
          ]
        }
      },
      '28': {
        id: '23',
        name: 'ia_cardtroptype',
        label: 'Type of Cardiac troponin (up to 2)',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {          
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Cardiac troponin T' },
            { value: '2', label: 'Cardiac troponin I' },
            { value: '3', label: 'High-sensitivity Troponin T' },
            { value: '4', label: 'High-sensitivity Troponin I' },
          ],
          setIfExists: [{
            id: '1.23a',
            name: 'ia_cardtrop_yn',
            value: '1'
          }]
        }
      },
      '29': {
        id: '23b',
        name: 'ia_cardtropt1',
        label: 'Troponin T',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 150
          }        
        }
      },
      '30': {
        id: '23c',
        name: 'ia_cardtropi',
        label: 'Troponin I',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 150
          }
        }
      },
      '31': {
        id: '23d',
        name: 'ia_cardtropt2',
        label: 'High-sensitivity Troponin T',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 150
          }
        }
      },
      '32': {
        id: '23e',
        name: 'ia_cardtrop_u',
        label: 'Troponin Unit of measurement',
        type: FormDataDataQuestionTypes.RADIO,
        data: {
          default: '1',
          options: [
            { value: '1', name: 'ng/mL' },
            { value: '2', name: 'ng/L' }
          ]
        }
      },
      '33': {
        id: '24',
        name: 'ia_cardbnp',
        label: 'BNP',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 1000
          },
          setIfExists: [{
            id: '1.24a',
            name: 'ia_cardbnp_yn',
            value: '1'
          }]
        }
      },
      '34': {
        id: '25',
        name: 'ia_cutan_yn',
        label: 'At any time post-ICU admission and until ICU discharge, did the patient present new cutaneous manifestations?',
        type: FormDataDataQuestionTypes.RADIO,
        data: {
          default: '1',
          options: [
            { name: 'Yes', value: '1' },
            { name: 'No', value: '0' },
            { name: 'Not available', value: '3' }
          ]
        }
      },
      '35': {
        id: '25a',
        name: 'ia_cutantype',
        label: 'Type of cutaneous manifestations (up to 3)',
        comments: ['Select maximum of 3'],
        type: FormDataDataQuestionTypes.DROPDOWN,
        predecessors: ['ia_cutan_yn'],
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          validation: {
            max: 3
          },
          options: [
            { value: '1', label: 'Bullae' },
            { value: '2', label: 'Macules' },
            { value: '3', label: 'Nodules' },
            { value: '4', label: 'Papules' },
            { value: '5', label: 'Plaques' },
            { value: '6', label: 'Purpura' },
            { value: '7', label: 'Pustules' },
            { value: '9', label: 'Rash' },
            { value: '10', label: 'Scale' },
            { value: '11', label: 'Urticaria' },
            { value: '12', label: 'Vesicles' },
            { value: '13', label: 'Other' },
          ]
        }
      },
      '36': {
        id: '25o',
        name: 'ia_cutanspec',
        label: 'Other, please specify',
        type: FormDataDataQuestionTypes.TEXT
      },
      '37': {
        id: '25b',
        name: 'ia_cutanreg',
        label: 'Involved regions (up to 3)',
        comments: ['Select maximum of 3'],
        type: FormDataDataQuestionTypes.DROPDOWN,
        predecessorHasValue: [{
          name: 'ia_cutan_yn',
          value: '1'
        }],
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          validation: {
            max: 3
          },
          options: [
            { value: '1', label: 'Face' },
            { value: '2', label: 'Trunk' },
            { value: '3', label: 'Upper limbs' },
            { value: '4', label: 'Hands' },
            { value: '5', label: 'Lower limbs' },
            { value: '6', label: 'Feet' },
          ]
        }
      }
    }
  },
  'eot_start_mech_vent': {
    id: '2',
    header: 'EOT Start Mech Vent',
    name: 'eot_start_mech_vent',
    questions: {
      '1': {
        id: '1',
        name: 'mv_datestart',
        label: 'Date Of Start Of Mechanical Ventilation',
        type: FormDataDataQuestionTypes.DATE,

        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          }
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '2': {
        id: '2',
        name: 'mv_intsite',
        label: 'Site Of Intubation',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          default: '2',
          options: [
            { value: '1', label: 'Outside Hospital' },
            { value: '2', label: 'Intensive Care Unit' },
            { value: '3', label: 'Emergency Department' },
            { value: '4', label: 'Hospital Ward' },
            { value: '5', label: 'Different Hospital, Then Patient Was Transferred' },
            { value: '6', label: 'Other' }
          ]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '3': {
        id: '3',
        name: 'mv_inttype',
        label: 'Type Of Intubation',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'Elective' },
            { value: '2', label: 'Emergent' }
          ]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '4': {
        id: '4',
        name: 'mv_cardarrest',
        label: 'CARDIAC ARREST',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '5': {
        id: '5',
        name: 'mv_ventprior',
        label: 'Ventilatory Support Before Intubation',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'High-Flow Oxygen Ventilation' },
            { value: '2', label: 'Mask Non-Invasive Ventilation' },
            { value: '3', label: 'Full Face-Mask Non-Invasive Ventilation' },
            { value: '4', label: 'Helmet Non-Invasive Ventilation' },
            { value: '5', label: 'Simple Face Mask Oxygen Therapy' },
            { value: '6', label: 'Venturi Mask Oxygen Therapy' },
            { value: '7', label: 'Non Re-Breather Face Mask Oxygen Therapy' },
            { value: '8', label: 'Nasal Prongs Oxygen Therapy' },
          ]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '6': {
        id: '6',
        name: 'mv_artph',
        label: 'Worst Arerial pH in the last 6 hours before start of MV',
        comments: ['Numbers between 6.5 and 7.6'],
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 6.5,
            max: 7.6
          },
          setIfExists: [{
            id: '2.6a',
            name: 'mv_artph_yn',
            value: true
          }]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '7': {
        id: '7',
        name: 'mv_artppo',
        label: 'Worst arterial partial pressure of O2',
        comments: ['in the 6 hours before start of MV (between 20 and 500)'],
        units: 'mmHg',
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 20,
            max: 500
          },
          setIfExists: [{
            id: '2.7a',
            name: 'mv_artppo_yn',
            value: true
          }]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '8': {
        id: '8',
        name: 'mv_artco2',
        label: 'Worst arterial partial pressure of CO2 ',
        units: 'mmHg',
        comments: ['In the 6 hours before start of MV (between 10 and 100)'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 10,
            max: 100
          },
          setIfExists: [{
            id: '2.8a',
            name: 'mv_artco2_yn',
            value: true
          }]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '9': {
        id: '9',
        name: 'ia_arthco3',
        label: 'Worst arterial HCO3',
        comments: ['In the 6 hours before start of MV'],
        type: FormDataDataQuestionTypes.NUMBER,
        predecessors: ['daily_invasive_prtrt'], //9
        data: {
          validation: {
            min: 1,
            max: 50
          },
          setIfExists: [{
            id: '2.9a',
            name: 'mv_arthco3_yn',
            value: true
          }]
        }
      },
      '10': {
        id: '9u',
        name: 'mv_arthco3_u',
        label: 'HCO3 units',
        type: FormDataDataQuestionTypes.RADIO,
        predecessors: ['ia_arthco3'], //9
        data: {
          validation: {
            mustExist: true
          },
          default: '1',
          options: [
            { name: 'mEq/L', value: '1' },
            { name: 'mmol/L', value: '2' }
          ]
        }
      },
      '11': {
        id: '10',
        name: 'mv_artbe',
        label: 'Worst Aterial base excess ',
        comments: ['In the 6 hours before start of MV'],
        units: 'mmol/L',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: -50,
            max: 50
          },
          setIfExists: [{
            id: '2.10a',
            name: 'mv_artbe_yn',
            value: true
          }]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '12': {
        id: '11',
        name: 'mv_lactate',
        label: 'Worst Lactate',
        comments: ['In the 6 hours before start of MV'],
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '2.11a',
            name: 'mv_lactate_yn',
            value: true
          }]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '13': {
        id: '12',
        name: 'mv_usecrrt',
        label: 'Use Of Continuous Renal Replacement Therapy Before Start MV',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '14': {
        id: '13',
        name: 'mv_usevaso',
        label: 'Use Of Vasoactive Drugs Before Start MV',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '15': {
        id: '14',
        name: 'mv_cardiac',
        label: 'Use Of Cardiac Assist Devices Before Start MV',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '16': {
        id: '15',
        name: 'mv_antib',
        label: 'Antibiotic Before Start MV',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          validation: {
            max: 5
          }, 
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Amikacin' },
            { value: '2', label: 'Amoxicillin' },
            { value: '3', label: 'Amoxicillin + Clavulanate' },
            { value: '4', label: 'Ampicillin' },
            { value: '5', label: 'Ampicillin + Sulbactam' },
            { value: '6', label: 'Atovaquone' },
            { value: '7', label: 'Azithromycin' },
            { value: '8', label: 'Aztreonam' },
            { value: '9', label: 'Bacampicillin' },
            { value: '10', label: 'Bacitracin' },
            { value: '11', label: 'Capreomycin' },
            { value: '12', label: 'Carbenicillin indanyl sodium' },
            { value: '13', label: 'Cefaclor' },
            { value: '14', label: 'Cefadroxil' },
            { value: '15', label: 'Cefamandole' },
            { value: '16', label: 'Cefazolin' },
            { value: '17', label: 'Cefdinir' },
            { value: '18', label: 'Cefditoren' },
            { value: '19', label: 'Cefepime' },
            { value: '20', label: 'Cefixime' },
            { value: '21', label: 'Cefmetazole' },
            { value: '22', label: 'Cefonicid' },
            { value: '23', label: 'Cefoperazone' },
            { value: '24', label: 'Cefotaxime' },
            { value: '25', label: 'Cefotetan' },
            { value: '26', label: 'Cefoxitin' },
            { value: '27', label: 'Cefpodoxime Proxetil' },
            { value: '28', label: 'Cefprozil' },
            { value: '29', label: 'Ceftazidime' },
            { value: '30', label: 'Ceftibuten' },
            { value: '31', label: 'Ceftizoxime' },
            { value: '32', label: 'Ceftriaxone' },
            { value: '33', label: 'Cefuroxime' },
            { value: '34', label: 'Cephalexin' },
            { value: '35', label: 'Cephalothin' },
            { value: '36', label: 'Cephapirin' },
            { value: '37', label: 'Cephradine' },
            { value: '38', label: 'Chloramphenicol' },
            { value: '39', label: 'Cinoxacin' },
            { value: '40', label: 'Ciprofloxacin' },
            { value: '41', label: 'Clarithromycin' },
            { value: '42', label: 'Clindamycin' },
            { value: '43', label: 'Cloxacillin' },
            { value: '44', label: 'Colistimethate' },
            { value: '45', label: 'Cycloserine' },
            { value: '46', label: 'Daptomycin' },
            { value: '47', label: 'Demeclocycline' },
            { value: '48', label: 'Dicloxacillin' },
            { value: '49', label: 'Dirithromycin' },
            { value: '50', label: 'Doripenem' },
            { value: '51', label: 'Doxycycline' },
            { value: '52', label: 'Enoxacin' },
            { value: '53', label: 'Ertapenem' },
            { value: '54', label: 'Erythromycin' },
            { value: '55', label: 'Fosfomycin' },
            { value: '56', label: 'Gatifloxacin' },
            { value: '57', label: 'Gemifloxacin' },
            { value: '58', label: 'Gentamicin' },
            { value: '59', label: 'Grepafloxacin' },
            { value: '60', label: 'Imipenem/Cilastatin' },
            { value: '61', label: 'Imiquimod' },
            { value: '62', label: 'Kanamycin' },
            { value: '63', label: 'Levofloxacin' },
            { value: '64', label: 'Lincomycin' },
            { value: '65', label: 'Linezolid' },
            { value: '66', label: 'Lomefloxacin' },
            { value: '67', label: 'Loracarbef' },
            { value: '68', label: 'Mafenide' },
            { value: '69', label: 'Meropenem' },
            { value: '70', label: 'Methenamine hippurate' },
            { value: '71', label: 'Methicillin' },
            { value: '72', label: 'Metronidazole' },
            { value: '73', label: 'Mezlocillin' },
            { value: '74', label: 'Minocycline' },
            { value: '75', label: 'Moxifloxacin' },
            { value: '76', label: 'Mupirocin' },
            { value: '77', label: 'Nafcillin' },
            { value: '78', label: 'Nalidixic Acid' },
            { value: '79', label: 'Neomycin' },
            { value: '80', label: 'Netilmicin' },
            { value: '81', label: 'Nitrofurantoin' },
            { value: '82', label: 'Nitrofurazone' },
            { value: '83', label: 'Norfloxacin' },
            { value: '84', label: 'Novobiocin' },
            { value: '85', label: 'Ofloxacin' },
            { value: '86', label: 'Oxacillin' },
            { value: '87', label: 'Oxytetracycline' },
            { value: '88', label: 'Penicillin' },
            { value: '89', label: 'Piperacillin' },
            { value: '90', label: 'Piperacillin + Tazobactam' },
            { value: '91', label: 'Podofilox' },
            { value: '92', label: 'Polymyxin B' },
            { value: '93', label: 'Quinupristin + Dalfopristin' },
            { value: '94', label: 'Retapamulin' },
            { value: '95', label: 'Rifapentine' },
            { value: '96', label: 'Rifaximin' },
            { value: '97', label: 'Saturated Solution of Potassium Iodide (SSKI)' },
            { value: '98', label: 'Sparfloxacin' },
            { value: '99', label: 'Spectinomycin' },
            { value: '100', label: 'Streptomycin' },
            { value: '101', label: 'Sulfadiazine' },
            { value: '102', label: 'Sulfamethoxazole' },
            { value: '103', label: 'Sulfisoxazole' },
            { value: '104', label: 'Sulphur, precipitated in petrolatum' },
            { value: '105', label: 'TCA (trichloroacetic acid), BCA (bichloroacetic acid)' },
            { value: '106', label: 'Teicoplanin' },
            { value: '107', label: 'Telavancin' },
            { value: '108', label: 'Telithromycin' },
            { value: '109', label: 'Terbinafine' },
            { value: '110', label: 'Tetracycline' },
            { value: '111', label: 'Ticarcillin' },
            { value: '112', label: 'Ticarcillin + Clavulanic Acid' },
            { value: '113', label: 'Tigecycline' },
            { value: '114', label: 'Tobramycin' },
            { value: '115', label: 'Trimethoprim' },
            { value: '116', label: 'Trimethoprim + Sulfamethoxazole' },
            { value: '117', label: 'Trovafloxacin' },
            { value: '118', label: 'Vancomycin' }
          ]
        },
        predecessorHasValue: [{
          name: 'daily_invasive_prtrt',
          value: true
        }]
      },
      '17': {
        id: '16',
        name: 'eot_start_mech_vent_complete',
        label: 'Complete?',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          default: '1',
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'Incomplete' },
            { value: '2', label: 'Unverified' },
            { value: '3', label: 'Complete' }
          ]
        }
      },
    }
  },
  'eot_start_ecmo': {
    id: '3',
    header: 'EOT Start ECMO',
    name: 'eot_start_ecmo',
    questions: {
      '1': {
        id: '1',
        name: 'e_datestart',
        label: 'Date Of Start Of ECMO',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          }
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '2': {
        id: '2',
        name: 'e_excel',
        label: 'Is this patient enrolled in the EXCEL study?',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '3': {
        id: '3',
        name: 'e_excelno',
        label: 'If Yes, what is the patients EXCEL study number',
        type: FormDataDataQuestionTypes.TEXT,
        predecessorHasValue: [
          {
            name: 'e_excel',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: 1,
            operator: FormDataDataQuestionOperators.AND
          }
        ]
      },
      '4': {
        id: '4',
        name: 'e_cannloc',
        label: 'Location Of ECMO Cannulation',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,          
          options: [
            { value: '1', label: 'Same Hospital' },
            { value: '2', label: 'Other Hospital, Then Patient Was Retrieved And Transferred' }
          ]
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '5': {
        id: '5',
        name: 'e_centrif',
        label: 'Type and Manufacturer of centrifugal blood pump driven circuit',
        type: FormDataDataQuestionTypes.TEXT,
        predecessor: ['daily_ecmo_prtrt']
      },
      '6': {
        id: '6',
        name: 'e_oxygenator',
        label: 'Type and Manufacturer of low-resistance oxygenator',
        type: FormDataDataQuestionTypes.TEXT,
        predecessor: ['daily_ecmo_prtrt']
      },
      '7': {
        id: '7',
        name: 'e_type',
        label: 'Type Of ECMO',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,          
          options: [
            { value: '1', label: 'Venous-Venous' },
            { value: '2', label: 'Venous-Arterial' }
          ]
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '8': {
        id: '8',
        name: 'e_artperacc',
        label: 'DRAINAGE CANNULA INSERTION SITE',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,          
          options: [
            { value: '1', label: 'Left femoral vein' },
            { value: '2', label: 'Left internal jugular vein' },
            { value: '3', label: 'Right femoral vein' },
            { value: '4', label: 'Right internal jugular vein' }
          ]
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '9': {
        id: '9',
        name: 'e_venperacc',
        label: 'RETURN CANNULA INSERTION SITE',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,          
          options: [
            { value: '1', label: 'Left femoral vein' },
            { value: '2', label: 'Left internal jugular vein' },
            { value: '3', label: 'Right femoral vein' },
            { value: '4', label: 'Right internal jugular vein' },
            { value: '5', label: 'Left femoral artery' },
            { value: '6', label: 'Right femoral artery' }
          ]
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '10': {
        id: '10',
        name: 'e_cardarr',
        label: ' CARDIAC ARREST BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '11': {
        id: '11',
        name: 'e_prone',
        label: 'Use Of Prone Position Before Start Of ECMO?',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '12': {
        id: '12',
        name: 'e_neuroblock',
        label: 'Use Of Neuromuscolar Blockade Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '13': {
        id: '13',
        name: 'e_recman',
        label: 'Use Of Recruitment Manoeuvres Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '14': {
        id: '14',
        name: 'e_inhaledno',
        label: 'Use Of Inhaled Nitric Oxide Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '15': {
        id: '15',
        name: 'e_bicarb',
        label: 'USE OF BICARBONATE BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '16': {
        id: '16',
        name: 'e_vent',
        label: 'Ventilatory Mode Before Start Of ECMO',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,          
          options: [
            { value: '1', label: 'Synchronized Intermittent Mandatory Ventilation - Volume-Controlled (SIMV-V)' },
            { value: '2', label: 'Synchronized Intermittent Mandatory Ventilation - Pressure-Controlled (SIMV-P)' },
            { value: '3', label: 'Volume Controlled Ventilation' },
            { value: '4', label: 'Pressure Controlled Ventilation' },
            { value: '5', label: 'Pressure Regulated Volume Control (PRVC)' },
            { value: '6', label: 'Airway Pressure Release Ventilation (APRV)' },
            { value: '7', label: 'Pressure Support Ventilation (PSV)' },
            { value: '8', label: 'Volume Support Ventilation (VSV)' },
            { value: '9', label: 'High Frequency Oscillatory (HFO)' },
            { value: '10', label: 'Bylevel Positive Airway Pressure (BiPAP)' },
            { value: '11', label: 'Continuous Positive Airway Pressure (CPAP)' },
            { value: '12', label: 'Proportional Assist Ventilation (PAV)' },
            { value: '13', label: 'Neurally Adjusted Ventilatory Assist (NAVA)' },
            { value: '20', label: 'Other, please specify' }
          ]
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '17': {
        id: '16.1',
        name: 'e_vent_o',
        label: 'Other ventilatory mode, specify',
        type: FormDataDataQuestionTypes.TEXT,
        data: {
          setIfExists: [{
            id: '16',
            name: 'e_vent',
            value: '20'
          }]
        }
      },
      '18': {
        id: '17a',
        name: 'e_ifo2_yn',
        label: 'Inspiratory fraction of oxygen in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '19': {
        id: '17',
        name: 'e_ifo2',
        label: 'HIGHEST INSPIRATORY FRACTION OF OXYGEN IN THE 6 HOURS BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {            
            min: 21,
            max: 100
          }
        },
        predecessor: ['e_ifo2_yn']
      },



      '20': {
        id: '18a',
        name: 'e_resp_yn',
        label: 'Respiratory rate in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '21': {
        id: '18',
        name: 'e_resp',
        label: 'HIGHEST RESPIRATORY RATE IN THE 6 HOURS BEFORE START OF ECMO (breaths/min)',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'breaths/min',
        data: {
          validation: {            
            min: 2,
            max: 60
          }
        },
        predecessor: ['e_resp_yn']
      },
      '22': {
        id: '19a',
        name: 'e_tidalvol_yn',
        label: 'Tidal volume in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '23': {
        id: '19',
        name: 'e_tidalvol',
        label: 'HIGHEST TIDAL VOLUME (ml/Kg of Ideal Body Weight)',
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {            
            min: 1,
            max: 14
          }
        },
        predecessor: ['e_tidalvol_yn']
      }
      ,
      '24': {
        id: '20a',
        name: 'e_peep_yn',
        label: 'Positive end expiratory pressure in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '25': {
        id: '20',
        name: 'e_peep',
        label: 'HIGHEST POSITIVE END EXPIRATORY PRESSURE IN THE 6 HOURS BEFORE START OF ECMO (cmH2O)',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'cmH2O',
        data: {
          validation: {            
            min: 0,
            max: 25
          }
        },
        predecessor: ['e_peep_yn']
      },
      '26': {
        id: '21a',
        name: 'e_peekairp_yn',
        label: 'Peak airway pressure in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '27': {
        id: '21',
        name: 'e_peekairp',
        label: 'HIGHEST PEAK AIRWAY PRESSURE IN THE 6 HOURS BEFORE START OF ECMO (cmH2O)',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'cmH2O',
        data: {
          validation: {            
            min: 0,
            max: 85
          }
        },
        predecessor: ['e_peekairp_yn']
      },
      '28': {
        id: '22a',
        name: 'e_platairp_yn',
        label: 'Airway plateau pressure in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '29': {
        id: '22',
        name: 'e_platairp',
        label: ' HIGHEST AIRWAY PLATEAU PRESSURE IN THE 6 HOURS BEFORE START OF ECMO (cmH2O)',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'cmH2O',
        data: {
          validation: {            
            min: 0,
            max: 50
          }
        },
        predecessor: ['e_platairp_yn']
      },
      '30': {
        id: '23a',
        name: 'e_artph_yn',
        label: 'Aterial pH in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '31': {
        id: '23',
        name: 'e_artph',
        label: 'WORST ARTERIAL pH IN THE 6 HOURS BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.NUMBER,
        units: 'pH',
        data: {
          validation: {            
            min: 6.5,
            max: 7.6
          }
        },
        predecessor: ['e_artph_yn']
      },
      '32': {
        id: '24a',
        name: 'e_artppo_yn',
        label: 'Arterial partial pressure O2 in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '33': {
        id: '24',
        name: 'e_artppo',
        label: 'WORST ARTERIAL PARTIAL PRESSURE OF OXYGEN IN THE 6 HOURS BEFORE START OF ECMO (mmHg)',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'mm Hg',
        data: {
          validation: {            
            min: 20,
            max: 500
          }
        },
        predecessor: ['e_artppo_yn']
      },
      '34': {
        id: '25a',
        name: 'e_artco2_yn',
        label: 'Arterial partial pressure CO2 in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '35': {
        id: '25',
        name: 'e_artco2',
        label: 'WORST ARTERIAL PARTIAL PRESSURE OF CARBON DIOXIDE IN THE 6 HOURS BEFORE START OF ECMO (mmHg)',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'mm Hg',
        data: {
          validation: {            
            min: 20,
            max: 500
          }
        },
        predecessor: ['e_artco2']
      },
      '36': {
        id: '26a',
        name: 'e_arthco3_yn',
        label: 'Arterial HCO3- in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '37': {
        id: '26',
        name: 'e_arthco3',
        label: 'WORST ARTERIAL HCO3- IN THE 6 HOURS BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {            
            min: 1,
            max: 50
          },
          setIfExists: [{
            id: '26a',
            name: 'e_arthco3_yn',
            value: true
          }]
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '38': {
        id: '26u',
        name: 'e_arthco3_u',
        label: 'HCO3- units',
        type: FormDataDataQuestionTypes.RADIO,
        predecessors: ['e_arthco3'],
        data: {
          validation: {
            mustExist: true
          },
          default: '1',
          options: [
            { name: 'mEq/L', value: '1' },
            { name: 'mmol/L', value: '2' }
          ]
        }
      },
      '39': {
        id: '27a',
        name: 'e_artbe_yn',
        label: 'Arterial Base excess in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '40': {
        id: '27',
        name: 'e_artbe',
        label: 'WORST ARTERIAL Base excess IN THE 6 HOURS BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'mmol/L',
        data: {
          validation: {            
            min: -50,
            max: 50
          }
        },
        predecessor: ['e_artbe_yn']
      },
      '41': {
        id: '28a',
        name: 'e_lactate_yn',
        label: 'Lactate in the last 6hrs available',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '42': {
        id: '28',
        name: 'e_lactate',
        label: 'WORST Lactate IN THE 6 HOURS BEFORE START OF ECMO',
        type: FormDataDataQuestionTypes.INTEGER,
        units: 'mmol/L',
        predecessor: ['e_lactate_yn']
      },
      '43': {
        id: '29',
        name: 'e_usecrrt',
        label: 'Use Of Continuous Renal Replacement Therapy Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '44': {
        id: '30',
        name: 'e_usevaso',
        label: 'Use Of Vasoactive Drugs Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '45': {
        id: '31',
        name: 'e_cardiac',
        label: 'Use Of Cardiac Assist Device Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '46': {
        id: '32',
        name: 'e_antibiot',
        label: 'Use Of Antibiotics Before Start Of ECMO',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessor: ['daily_ecmo_prtrt']
      },
      '47': {
        id: '33.1',
        name: 'e_antib',
        label: 'Antibiotic Before Start ECMO',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {          
          validation: {
            max: 5
          },          
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Amikacin' },
            { value: '2', label: 'Amoxicillin' },
            { value: '3', label: 'Amoxicillin + Clavulanate' },
            { value: '4', label: 'Ampicillin' },
            { value: '5', label: 'Ampicillin + Sulbactam' },
            { value: '6', label: 'Atovaquone' },
            { value: '7', label: 'Azithromycin' },
            { value: '8', label: 'Aztreonam' },
            { value: '9', label: 'Bacampicillin' },
            { value: '10', label: 'Bacitracin' },
            { value: '11', label: 'Capreomycin' },
            { value: '12', label: 'Carbenicillin indanyl sodium' },
            { value: '13', label: 'Cefaclor' },
            { value: '14', label: 'Cefadroxil' },
            { value: '15', label: 'Cefamandole' },
            { value: '16', label: 'Cefazolin' },
            { value: '17', label: 'Cefdinir' },
            { value: '18', label: 'Cefditoren' },
            { value: '19', label: 'Cefepime' },
            { value: '20', label: 'Cefixime' },
            { value: '21', label: 'Cefmetazole' },
            { value: '22', label: 'Cefonicid' },
            { value: '23', label: 'Cefoperazone' },
            { value: '24', label: 'Cefotaxime' },
            { value: '25', label: 'Cefotetan' },
            { value: '26', label: 'Cefoxitin' },
            { value: '27', label: 'Cefpodoxime Proxetil' },
            { value: '28', label: 'Cefprozil' },
            { value: '29', label: 'Ceftazidime' },
            { value: '30', label: 'Ceftibuten' },
            { value: '31', label: 'Ceftizoxime' },
            { value: '32', label: 'Ceftriaxone' },
            { value: '33', label: 'Cefuroxime' },
            { value: '34', label: 'Cephalexin' },
            { value: '35', label: 'Cephalothin' },
            { value: '36', label: 'Cephapirin' },
            { value: '37', label: 'Cephradine' },
            { value: '38', label: 'Chloramphenicol' },
            { value: '39', label: 'Cinoxacin' },
            { value: '40', label: 'Ciprofloxacin' },
            { value: '41', label: 'Clarithromycin' },
            { value: '42', label: 'Clindamycin' },
            { value: '43', label: 'Cloxacillin' },
            { value: '44', label: 'Colistimethate' },
            { value: '45', label: 'Cycloserine' },
            { value: '46', label: 'Daptomycin' },
            { value: '47', label: 'Demeclocycline' },
            { value: '48', label: 'Dicloxacillin' },
            { value: '49', label: 'Dirithromycin' },
            { value: '50', label: 'Doripenem' },
            { value: '51', label: 'Doxycycline' },
            { value: '52', label: 'Enoxacin' },
            { value: '53', label: 'Ertapenem' },
            { value: '54', label: 'Erythromycin' },
            { value: '55', label: 'Fosfomycin' },
            { value: '56', label: 'Gatifloxacin' },
            { value: '57', label: 'Gemifloxacin' },
            { value: '58', label: 'Gentamicin' },
            { value: '59', label: 'Grepafloxacin' },
            { value: '60', label: 'Imipenem/Cilastatin' },
            { value: '61', label: 'Imiquimod' },
            { value: '62', label: 'Kanamycin' },
            { value: '63', label: 'Levofloxacin' },
            { value: '64', label: 'Lincomycin' },
            { value: '65', label: 'Linezolid' },
            { value: '66', label: 'Lomefloxacin' },
            { value: '67', label: 'Loracarbef' },
            { value: '68', label: 'Mafenide' },
            { value: '69', label: 'Meropenem' },
            { value: '70', label: 'Methenamine hippurate' },
            { value: '71', label: 'Methicillin' },
            { value: '72', label: 'Metronidazole' },
            { value: '73', label: 'Mezlocillin' },
            { value: '74', label: 'Minocycline' },
            { value: '75', label: 'Moxifloxacin' },
            { value: '76', label: 'Mupirocin' },
            { value: '77', label: 'Nafcillin' },
            { value: '78', label: 'Nalidixic Acid' },
            { value: '79', label: 'Neomycin' },
            { value: '80', label: 'Netilmicin' },
            { value: '81', label: 'Nitrofurantoin' },
            { value: '82', label: 'Nitrofurazone' },
            { value: '83', label: 'Norfloxacin' },
            { value: '84', label: 'Novobiocin' },
            { value: '85', label: 'Ofloxacin' },
            { value: '86', label: 'Oxacillin' },
            { value: '87', label: 'Oxytetracycline' },
            { value: '88', label: 'Penicillin' },
            { value: '89', label: 'Piperacillin' },
            { value: '90', label: 'Piperacillin + Tazobactam' },
            { value: '91', label: 'Podofilox' },
            { value: '92', label: 'Polymyxin B' },
            { value: '93', label: 'Quinupristin + Dalfopristin' },
            { value: '94', label: 'Retapamulin' },
            { value: '95', label: 'Rifapentine' },
            { value: '96', label: 'Rifaximin' },
            { value: '97', label: 'Saturated Solution of Potassium Iodide (SSKI)' },
            { value: '98', label: 'Sparfloxacin' },
            { value: '99', label: 'Spectinomycin' },
            { value: '100', label: 'Streptomycin' },
            { value: '101', label: 'Sulfadiazine' },
            { value: '102', label: 'Sulfamethoxazole' },
            { value: '103', label: 'Sulfisoxazole' },
            { value: '104', label: 'Sulphur, precipitated in petrolatum' },
            { value: '105', label: 'TCA (trichloroacetic acid), BCA (bichloroacetic acid)' },
            { value: '106', label: 'Teicoplanin' },
            { value: '107', label: 'Telavancin' },
            { value: '108', label: 'Telithromycin' },
            { value: '109', label: 'Terbinafine' },
            { value: '110', label: 'Tetracycline' },
            { value: '111', label: 'Ticarcillin' },
            { value: '112', label: 'Ticarcillin + Clavulanic Acid' },
            { value: '113', label: 'Tigecycline' },
            { value: '114', label: 'Tobramycin' },
            { value: '115', label: 'Trimethoprim' },
            { value: '116', label: 'Trimethoprim + Sulfamethoxazole' },
            { value: '117', label: 'Trovafloxacin' },
            { value: '118', label: 'Vancomycin' },
            { value: '999', label: 'Other' },
          ]
        },
        predecessorHasValue: [{
          name: 'e_antibiot',
          value: true
        }]
      },
      '48': {
        id: '34',
        name: 'eot_start_ecmo_complete',
        label: 'Complete?',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          default: '1',
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '0', label: 'Incomplete' },
            { value: '1', label: 'Unverified' },
            { value: '2', label: 'Complete' }
          ]
        }
      }

    }
  },
  'eot_daily': {
    id: '4',
    header: 'DAILY CASE RECORD FORM (complete one form on admission to ICU, and daily up to ECMO discontinuation or till death)',
    name: 'eot_daily',
    questions: {
      '1': {
        id: '1',
        name: 'ef_dateobs',
        label: 'Date of observation',
        comments: ['Only enter date in DD/MM/YYYY format from 14/12/2019'],
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          }
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: 1,
            operator: FormDataDataQuestionOperators.OR
          }

        ]
      },
      '2': {
        id: '2',
        name: 'ef_position',
        label: 'Patient Position in the last 24h',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'Supine' },
            { value: '2', label: 'Prone' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR

          }

        ]
      },
      '3': {
        id: '3',
        name: 'ef_flow',
        label: 'Highest ECMO flow rate in the last 24h',
        units: 'L/Min',
        type: FormDataDataQuestionTypes.NUMBER,
        predecessor: ['daily_ecmo_prtrt']
      },
      '4': {
        id: '4',
        name: 'ef_gasflow',
        label: 'Highest ECMO gas flow rate in the last 24h',
        units: 'L/Min',
        type: FormDataDataQuestionTypes.NUMBER,
        predecessor: ['daily_ecmo_prtrt']
      },
      '5': {
        id: '5',
        name: 'ef_ecmocirc',
        label: 'ECMO circuit change in the last 24h',
        type: FormDataDataQuestionTypes.YESNO,
        predecessor: ['daily_ecmo_prtrt'],
        data: {
          default: false
        }
      },
      '6': {
        id: '6',
        name: 'ef_neuroblock',
        label: 'USE OF NEUROMUSCOLAR BLOCKADE IN THE LAST 24h',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          },
          {
            name: 'daily_neuro_cmtrt',
            value: '',
            operator: FormDataDataQuestionOperators.AND
          }
        ]
      },
      '7': {
        id: '7',
        name: 'ef_recman',
        label: 'USE OF RECRUITMENT MANOEUVRES in the last 24h',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '8': {
        id: '8',
        name: 'ef_inhaledno',
        label: 'USE OF INHALED NITRIC OXIDE IN THE LAST 24h',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          },
          {
            name: 'daily_nitritc_cmtrt',
            value: '',
            operator: FormDataDataQuestionOperators.AND
          }
        ]
      },
      '9': {
        id: '9',
        name: 'ef_vent',
        label: 'MOST FREQUENT VENTILATORY MODE in the last 24h',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          default: '1',
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'Synchronized Intermittent Mandatory Ventilation - volume-Controlled (SIMV-V)' },
            { value: '2', label: 'Synchronized Intermittent Mandatory Ventilation - pressure-Controlled (SIMV-P)' },
            { value: '3', label: 'volume Controlled Ventilation' },
            { value: '4', label: 'pressure Controlled Ventilation' },
            { value: '5', label: 'pressure Regulated volume Control (PRVC)' },
            { value: '6', label: 'Airway pressure Release Ventilation (APRV)' },
            { value: '7', label: 'pressure Support Ventilation (PSV)' },
            { value: '8', label: 'volume Support Ventilation (VSV)' },
            { value: '9', label: 'High Frequency Oscillatory (HFO)' },
            { value: '10', label: 'Bylevel positive Airway pressure (BiPAP)' },
            { value: '11', label: 'Continuous positive Airway pressure (CPAP)' },
            { value: '12', label: 'Proportional Assist Ventilation (PAV)' },
            { value: '13', label: 'Neurally Adjusted Ventilatory Assist (NAVA)' },
            { value: '20', label: 'Other' },

          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '10': {
        id: '9s',
        name: 'ef_vent_o',
        label: 'Other ventilator, specify',
        type: FormDataDataQuestionTypes.TEXT,
        predecessors: ['ef_vent'], //9
        data: {
          setIfExists: [{
            id: '4.9',
            name: 'ef_vent',
            value: '99'
          }]
        }
      },
      '11': {
        id: '10',
        name: 'ef_ifo2',
        label: 'Highest INSPIRATORY FRACTION OF OXYGEN in the last 24h',
        comments: ['Between 21 and 100'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 21,
            max: 100
          },
          setIfExists: [{
            id: '4.10a',
            name: 'ef_ifo2_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '12': {
        id: '11',
        name: 'ef_resp',
        label: 'Highest RESPIRATORY RATE in the last 24h (Breaths/min)',
        comments: ['Between 2 and 60'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 2,
            max: 60
          },
          setIfExists: [{
            id: '4.11a',
            name: 'ef_resp_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '13': {
        id: '12',
        name: 'ef_tidalvol',
        label: 'Highest tidal volume in the last 24h (ml/Kg of Ideal Body Weight)',
        comments: ['Between 1 and 14'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 1,
            max: 14
          },
          setIfExists: [{
            id: '4.12a',
            name: 'ef_tidalvol_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '14': {
        id: '13',
        name: 'ef_peep',
        label: ' Highest positive end expiratory pressure in the last 24h',
        units: 'cmH2O',
        comments: ['Between 0 and 25'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 0,
            max: 25
          },
          setIfExists: [{
            id: '4.13a',
            name: 'ef_peep_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operator: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '15': {
        id: '14',
        name: 'ef_airplat',
        label: 'Highest airway plateau pressure in the last 24h (cmH2O)',
        units: 'cmH2O',
        comments: ['Between 0 and 50'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 0,
            max: 50
          },
          setIfExists: [{
            id: '4.14a',
            name: 'ef_airplat_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '16': {
        id: '15',
        name: 'ef_artph',
        label: 'Worst arterial pH in the last 24h',
        comments: ['Between 6.5 and 7.6'],
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 6.5,
            max: 7.6
          },
          setIfExists: [{
            id: '4.15a',
            name: 'ef_artph_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '17': {
        id: '16',
        name: 'ef_artppo',
        label: 'Worst arterial PARTIAL pressure OF OXYGEN in the last 24h',
        units: 'mmHg',
        comments: ['Between 20 and 500'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 20,
            max: 500
          },
          setIfExists: [{
            id: '4.16a',
            name: 'ef_artppo_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '18': {
        id: '17',
        name: 'ef_artco2',
        label: 'Worst arterial PARTIAL pressure OF CARBON DIOXIDE in the last 24h',
        units: 'mmHg',
        comments: ['Between10 and 100'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 20,
            max: 500
          },
          setIfExists: [{
            id: '4.17a',
            name: 'ef_artco2_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '19': {
        id: '18',
        name: 'ef_arthco3',
        label: 'Worst arterial HCO3 in the last 24h',
        comments: ['Between 1 and 50'],
        type: FormDataDataQuestionTypes.INTEGER,
        data: {
          validation: {
            min: 20,
            max: 500
          },
          setIfExists: [{
            id: '4.18a',
            name: 'ef_arthco3_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '20': {
        id: '18u',
        name: 'mv_arthco3_u',
        label: 'HCO3 units',
        type: FormDataDataQuestionTypes.RADIO,
        predecessors: ['ef_arthco3'], //18
        data: {
          validation: {
            mustExist: true
          },
          default: '1',
          options: [
            { name: 'mEq/L', value: '1' },
            { name: 'mmol/L', value: '2' }
          ]
        }
      },
      '21': {
        id: '19',
        name: 'ef_artbe',
        label: 'Worst arterial Base excess in the last 24h',
        units: 'mmol/L',
        comments: ['Between -50 and 50'],
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: -50,
            max: 50
          },
          setIfExists: [{
            id: '4.19a',
            name: 'ef_artbe_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '22': {
        id: '20',
        name: 'ef_lactate',
        label: 'Worst Lactate in the last 24h',
        units: 'mmol/L',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.20a',
            name: 'ef_lactate_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '23': {
        id: '21',
        name: 'ef_creatinine',
        label: 'Worst CREATININE in the last 24h',
        units: 'mg/dL',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.21a',
            name: 'ef_creatinine_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '24': {
        id: '22',
        name: 'ef_usecrrt',
        label: 'Use Of Continuous Renal Replacement Therapy',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          },
          {
            name: 'daily_rrt_cmtrt',
            value: '',
            operators: FormDataDataQuestionOperators.AND
          }
        ]
      },
      '25': {
        id: '23',
        name: 'ef_usevaso',
        label: 'Use Of Vasoactive Drugs',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          },

          {
            name: 'daily_inotrope_cmyn',
            value: '',
            operators: FormDataDataQuestionOperators.AND
          }
        ]
      },
      '26': {
        id: '24',
        name: 'ef_vasot1',
        label: 'Type Of Vasoactive Drug 1',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.23',
            name: 'ef_usevaso',
            value: true
          }],
          options: [
            { value: '1', label: 'Dobutamine' },
            { value: '2', label: 'Dopamine' },
            { value: '3', label: 'Enoximone' },
            { value: '4', label: 'Enoximone' },
            { value: '5', label: 'Esmolol' },
            { value: '6', label: 'Levosimendan' },
            { value: '7', label: 'Metaraminol' },
            { value: '8', label: 'Metoprolol' },
            { value: '9', label: 'Milrinone' },
            { value: '10', label: 'Nicardipine' },
            { value: '11', label: 'Nitroglycerin' },
            { value: '12', label: 'Nitroprusside' },
            { value: '13', label: 'Norepinephrine' },
            { value: '14', label: 'Phenylephrine' },
            { value: '15', label: 'Tolazoline' },
            { value: '16', label: 'Vasopressin' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },

          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }

        ]
      },
      '27': {
        id: '25',
        name: 'ef_vasod1',
        label: 'Highest DOSE OF VASOACTIVE DRUG 1 in the last 24h',
        units: 'mcg/Kg/min',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.23',
            name: 'ef_usevaso',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '28': {
        id: '26',
        name: 'ef_vasot2',
        label: 'Type Of Vasoactive Drug 2',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.23',
            name: 'ef_usevaso',
            value: true
          }],
          options: [
            { value: '1', label: 'Dobutamine' },
            { value: '2', label: 'Dopamine' },
            { value: '3', label: 'Enoximone' },
            { value: '4', label: 'Enoximone' },
            { value: '5', label: 'Esmolol' },
            { value: '6', label: 'Levosimendan' },
            { value: '7', label: 'Metaraminol' },
            { value: '8', label: 'Metoprolol' },
            { value: '9', label: 'Milrinone' },
            { value: '10', label: 'Nicardipine' },
            { value: '11', label: 'Nitroglycerin' },
            { value: '12', label: 'Nitroprusside' },
            { value: '13', label: 'Norepinephrine' },
            { value: '14', label: 'Phenylephrine' },
            { value: '15', label: 'Tolazoline' },
            { value: '16', label: 'Vasopressin' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '29': {
        id: '27',
        name: 'ef_vasod2',
        label: 'Highest DOSE OF VASOACTIVE DRUG 2 in the last 24h',
        units: 'mcg/Kg/min',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.23',
            name: 'ef_usevaso',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '30': {
        id: '28',
        name: 'ef_vasot3',
        label: 'Type Of Vasoactive Drug 3',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          setIfExists: [{
            id: '4.23',
            name: 'ef_usevaso',
            value: true
          }],
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'Dobutamine' },
            { value: '2', label: 'Dopamine' },
            { value: '3', label: 'Enoximone' },
            { value: '4', label: 'Enoximone' },
            { value: '5', label: 'Esmolol' },
            { value: '6', label: 'Levosimendan' },
            { value: '7', label: 'Metaraminol' },
            { value: '8', label: 'Metoprolol' },
            { value: '9', label: 'Milrinone' },
            { value: '10', label: 'Nicardipine' },
            { value: '11', label: 'Nitroglycerin' },
            { value: '12', label: 'Nitroprusside' },
            { value: '13', label: 'Norepinephrine' },
            { value: '14', label: 'Phenylephrine' },
            { value: '15', label: 'Tolazoline' },
            { value: '16', label: 'Vasopressin' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '31': {
        id: '29',
        name: 'ef_vasod3',
        label: 'Highest DOSE OF VASOACTIVE DRUG 3 in the last 24h',
        units: 'mcg/Kg/min',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.23',
            name: 'ef_usevaso',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '32': {
        id: '30',
        name: 'ef_cardiac',
        label: 'USE OF CARDIAC ASSIST DEVICES in the last 24h',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '33': {
        id: '31',
        name: 'ef_antibiot',
        label: 'USE OF ANTIBIOTICS in the last 24h',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '34': {
        id: '31a',
        name: 'ef_antib',
        label: 'Antibiotic',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          validation: {
            max: 5
          },
          setIfExists: [{
            id: '4.30',
            name: 'ef_antibiot',
            value: true
          }],
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Amikacin' },
            { value: '2', label: 'Amoxicillin' },
            { value: '3', label: 'Amoxicillin + Clavulanate' },
            { value: '4', label: 'Ampicillin' },
            { value: '5', label: 'Ampicillin + Sulbactam' },
            { value: '6', label: 'Atovaquone' },
            { value: '7', label: 'Azithromycin' },
            { value: '8', label: 'Aztreonam' },
            { value: '9', label: 'Bacampicillin' },
            { value: '10', label: 'Bacitracin' },
            { value: '11', label: 'Capreomycin' },
            { value: '12', label: 'Carbenicillin indanyl sodium' },
            { value: '13', label: 'Cefaclor' },
            { value: '14', label: 'Cefadroxil' },
            { value: '15', label: 'Cefamandole' },
            { value: '16', label: 'Cefazolin' },
            { value: '17', label: 'Cefdinir' },
            { value: '18', label: 'Cefditoren' },
            { value: '19', label: 'Cefepime' },
            { value: '20', label: 'Cefixime' },
            { value: '21', label: 'Cefmetazole' },
            { value: '22', label: 'Cefonicid' },
            { value: '23', label: 'Cefoperazone' },
            { value: '24', label: 'Cefotaxime' },
            { value: '25', label: 'Cefotetan' },
            { value: '26', label: 'Cefoxitin' },
            { value: '27', label: 'Cefpodoxime Proxetil' },
            { value: '28', label: 'Cefprozil' },
            { value: '29', label: 'Ceftazidime' },
            { value: '30', label: 'Ceftibuten' },
            { value: '31', label: 'Ceftizoxime' },
            { value: '32', label: 'Ceftriaxone' },
            { value: '33', label: 'Cefuroxime' },
            { value: '34', label: 'Cephalexin' },
            { value: '35', label: 'Cephalothin' },
            { value: '36', label: 'Cephapirin' },
            { value: '37', label: 'Cephradine' },
            { value: '38', label: 'Chloramphenicol' },
            { value: '39', label: 'Cinoxacin' },
            { value: '40', label: 'Ciprofloxacin' },
            { value: '41', label: 'Clarithromycin' },
            { value: '42', label: 'Clindamycin' },
            { value: '43', label: 'Cloxacillin' },
            { value: '44', label: 'Colistimethate' },
            { value: '45', label: 'Cycloserine' },
            { value: '46', label: 'Daptomycin' },
            { value: '47', label: 'Demeclocycline' },
            { value: '48', label: 'Dicloxacillin' },
            { value: '49', label: 'Dirithromycin' },
            { value: '50', label: 'Doripenem' },
            { value: '51', label: 'Doxycycline' },
            { value: '52', label: 'Enoxacin' },
            { value: '53', label: 'Ertapenem' },
            { value: '54', label: 'Erythromycin' },
            { value: '55', label: 'Fosfomycin' },
            { value: '56', label: 'Gatifloxacin' },
            { value: '57', label: 'Gemifloxacin' },
            { value: '58', label: 'Gentamicin' },
            { value: '59', label: 'Grepafloxacin' },
            { value: '60', label: 'Imipenem/Cilastatin' },
            { value: '61', label: 'Imiquimod' },
            { value: '62', label: 'Kanamycin' },
            { value: '63', label: 'Levofloxacin' },
            { value: '64', label: 'Lincomycin' },
            { value: '65', label: 'Linezolid' },
            { value: '66', label: 'Lomefloxacin' },
            { value: '67', label: 'Loracarbef' },
            { value: '68', label: 'Mafenide' },
            { value: '69', label: 'Meropenem' },
            { value: '70', label: 'Methenamine hippurate' },
            { value: '71', label: 'Methicillin' },
            { value: '72', label: 'Metronidazole' },
            { value: '73', label: 'Mezlocillin' },
            { value: '74', label: 'Minocycline' },
            { value: '75', label: 'Moxifloxacin' },
            { value: '76', label: 'Mupirocin' },
            { value: '77', label: 'Nafcillin' },
            { value: '78', label: 'Nalidixic Acid' },
            { value: '79', label: 'Neomycin' },
            { value: '80', label: 'Netilmicin' },
            { value: '81', label: 'Nitrofurantoin' },
            { value: '82', label: 'Nitrofurazone' },
            { value: '83', label: 'Norfloxacin' },
            { value: '84', label: 'Novobiocin' },
            { value: '85', label: 'Ofloxacin' },
            { value: '86', label: 'Oxacillin' },
            { value: '87', label: 'Oxytetracycline' },
            { value: '88', label: 'Penicillin' },
            { value: '89', label: 'Piperacillin' },
            { value: '90', label: 'Piperacillin + Tazobactam' },
            { value: '91', label: 'Podofilox' },
            { value: '92', label: 'Polymyxin B' },
            { value: '93', label: 'Quinupristin + Dalfopristin' },
            { value: '94', label: 'Retapamulin' },
            { value: '95', label: 'Rifapentine' },
            { value: '96', label: 'Rifaximin' },
            { value: '97', label: 'Saturated Solution of Potassium Iodide (SSKI)' },
            { value: '98', label: 'Sparfloxacin' },
            { value: '99', label: 'Spectinomycin' },
            { value: '100', label: 'Streptomycin' },
            { value: '101', label: 'Sulfadiazine' },
            { value: '102', label: 'Sulfamethoxazole' },
            { value: '103', label: 'Sulfisoxazole' },
            { value: '104', label: 'Sulphur, precipitated in petrolatum' },
            { value: '105', label: 'TCA (trichloroacetic acid), BCA (bichloroacetic acid)' },
            { value: '106', label: 'Teicoplanin' },
            { value: '107', label: 'Telavancin' },
            { value: '108', label: 'Telithromycin' },
            { value: '109', label: 'Terbinafine' },
            { value: '110', label: 'Tetracycline' },
            { value: '111', label: 'Ticarcillin' },
            { value: '112', label: 'Ticarcillin + Clavulanic Acid' },
            { value: '113', label: 'Tigecycline' },
            { value: '114', label: 'Tobramycin' },
            { value: '115', label: 'Trimethoprim' },
            { value: '116', label: 'Trimethoprim + Sulfamethoxazole' },
            { value: '117', label: 'Trovafloxacin' },
            { value: '118', label: 'Vancomycin' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '35': {
        id: '32',
        name: 'ef_hb',
        label: 'Worst Haemoglobin in the last 24h',
        units: 'g/dL',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.32a',
            name: 'ef_hb_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '36': {
        id: '33',
        name: 'ef_wbc',
        label: 'Worst White Blood Cells in the last 24h',
        units: 'cells/microL',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.32a',
            name: 'ef_wbc_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '37': {
        id: '34',
        name: 'ef_wbcunit',
        label: 'White Blood Cells Unit',
        type: FormDataDataQuestionTypes.RADIO,
        data: {
          setIfExists: [{
            id: '4.33a',
            name: 'ef_wbc_yn',
            value: true
          }],
          default: '1',
          options: [
            { name: 'x 10^9 / L', value: '1' },
            { name: 'x 10^3/microL', value: '2' }
          ]
        }
      },
      '38': {
        id: '35',
        name: 'ef_ast',
        label: 'Worst AST/SGOT in the last 24h',
        units: 'U/L',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.35a',
            name: 'ef_ast_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '39': {
        id: '36',
        name: 'ef_alt',
        label: 'Worst ALT/SGPT in the last 24h',
        units: 'U/L',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          setIfExists: [{
            id: '4.36a',
            name: 'ef_alt_yn',
            value: true
          }]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '40': {
        id: '37',
        name: 'ef_anticoag',
        label: 'ANTICOAGULANTS in the last 24h',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '41': {
        id: '38',
        name: 'ef_anticoagt',
        label: 'TYPE OF ANTICOAGULANTS in the last 24h',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Continuous infusion of unfractionated heparin' },
            { value: '2', label: 'Subcutaneous unfractionated heparin only' },
            { value: '3', label: 'Low molecular heparin' },
            { value: '4', label: 'Danaparoid Lepirudin' },
            { value: '5', label: 'Argatroban + Sulbactam' },
            { value: '6', label: 'Hirulog and bivalirudin' },
            { value: '7', label: 'Desirudin' },
            { value: '8', label: 'Nafamostat Mesilate' },
            { value: '9', label: 'Other' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '42': {
        id: '39',
        name: 'ef_rbc',
        label: 'TRANSFUSED PACKED RED BLOOD CELL CONCENTRATE in the last 24 HOURS',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },

          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '43': {
        id: '40',
        name: 'ef_platelets',
        label: 'TRANSFUSED PLATELETS CONCENTRATE in the last 24 HOURS',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '44': {
        id: '41',
        name: 'ef_frzplasma',
        label: 'TRANSFUSED FRESH FROZEN PLASMA in the last 24 HOURS',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '45': {
        id: '42',
        name: 'ef_cryoprecip',
        label: 'TRANSFUSED CRYOPRECIPITATES in the last 24 HOURS',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '46': {
        id: '43',
        name: 'ef_infcomp1',
        label: ' Infection Complication 1',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '47': {
        id: '44',
        name: 'ef_infsrc1',
        label: 'Source Of Infectious Complication 1',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.43',
            name: 'ef_infcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Lungs' },
            { value: '2', label: 'Gastro-Intestinal' },
            { value: '3', label: 'Genito-Urinary' },
            { value: '4', label: 'Skin And Soft Tissue' },
            { value: '5', label: 'Central Nervous System' },
            { value: '6', label: 'Osteoarticular And Bone' },
            { value: '7', label: 'Cardiac' },
            { value: '8', label: 'Bloodstream' },
            { value: '999', label: 'Not Known' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '48': {
        id: '45',
        name: 'ef_infpath1',
        label: 'Causative Pathogen 1',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.43',
            name: 'ef_infcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Acinetobacter baumannii' },
            { value: '2', label: 'Actinomyces' },
            { value: '3', label: 'Aeromonas' },
            { value: '4', label: 'Bacillus anthracis' },
            { value: '5', label: 'Bacillus species' },
            { value: '6', label: 'Bacteroides fragilis' },
            { value: '7', label: 'Bacteroides species' },
            { value: '8', label: 'Bartonella species' },
            { value: '9', label: 'Bordetella species' },
            { value: '10', label: 'Borrelia burgdorferi' },
            { value: '11', label: 'Borrelia species' },
            { value: '12', label: 'Brucella Species' },
            { value: '13', label: 'Burkholderia cepacia' },
            { value: '14', label: 'Burkholderia mallei' },
            { value: '15', label: 'Burkholderia pseudomallei' },
            { value: '16', label: 'Campylobacter and related species' },
            { value: '17', label: 'Campylobacter jejuni' },
            { value: '18', label: 'Capnocytophaga canimorsus' },
            { value: '19', label: 'Chlamydia trachomatis' },
            { value: '20', label: 'Chlamydophila pneumoniae' },
            { value: '21', label: 'Chlamydophila psittaci' },
            { value: '22', label: 'Citrobacter species' },
            { value: '23', label: 'Clostridium botulinum' },
            { value: '24', label: 'Clostridium difficile' },
            { value: '25', label: 'Clostridium species' },
            { value: '26', label: 'Clostridium tetani (Tetanus)' },
            { value: '27', label: 'Corynebacterium diphtheriae' },
            { value: '28', label: 'Coxiella burnetii' },
            { value: '29', label: 'Ehrlichia species' },
            { value: '30', label: 'Eikenella corrodens' },
            { value: '31', label: 'Enterobacter species' },
            { value: '32', label: 'Enterococcus' },
            { value: '33', label: 'Erysipelothrix rhusiopathiae' },
            { value: '34', label: 'Escherichia coli' },
            { value: '35', label: 'Francisella tularensis' },
            { value: '36', label: 'Haemophilus ducreyi (Chancroid)' },
            { value: '37', label: 'Haemophilus influenzae' },
            { value: '38', label: 'Helicobacter cinaedi and related species' },
            { value: '39', label: 'Helicobacter pylori' },
            { value: '40', label: 'Klebsiella granulomatis (Antibiotic Guide)' },
            { value: '41', label: 'Klebsiella species' },
            { value: '42', label: 'ESBL Klebsiella pneumoniae' },
            { value: '43', label: 'Lactobacillus' },
            { value: '44', label: 'Legionella pneumophila' },
            { value: '45', label: 'Legionella species' },
            { value: '46', label: 'Leptospira interrogans' },
            { value: '47', label: 'Listeria monocytogenes' },
            { value: '48', label: 'Lymphogranuloma venereum (LGV)' },
            { value: '49', label: 'Methicillin Resistant Staphylococcus aureus' },
            { value: '50', label: 'Moraxella catarrhalis' },
            { value: '51', label: 'Morganella' },
            { value: '52', label: 'Mycobacterium abscessus' },
            { value: '53', label: 'Mycobacterium avium-complex (MAC, MAI, non-HIV)' },
            { value: '54', label: 'Mycobacterium chelonae' },
            { value: '55', label: 'Mycobacterium fortuitum' },
            { value: '56', label: 'Mycobacterium gordonae' },
            { value: '57', label: 'Mycobacterium kansasii' },
            { value: '58', label: 'Mycobacterium leprae' },
            { value: '59', label: 'Mycobacterium marinum' },
            { value: '60', label: 'Mycobacterium scrofulaceum' },
            { value: '61', label: 'Mycobacterium tuberculosis' },
            { value: '62', label: 'Mycobacterium ulcerans' },
            { value: '63', label: 'Mycobacterium xenopi' },
            { value: '64', label: 'Mycoplasma pneumoniae (Antibiotic Guide)' },
            { value: '65', label: 'Neisseria gonorrhoeae' },
            { value: '66', label: 'Neisseria meningitidis' },
            { value: '67', label: 'Nocardia' },
            { value: '68', label: 'Other atypical mycobacteria' },
            { value: '69', label: 'Pasteurella multocida' },
            { value: '70', label: 'Peptostreptococcus/Peptococcus' },
            { value: '71', label: 'Plesiomonas' },
            { value: '72', label: 'Propionibacterium species' },
            { value: '73', label: 'Proteus species' },
            { value: '74', label: 'Providencia' },
            { value: '75', label: 'Pseudomonas aeruginosa' },
            { value: '76', label: 'Rhodococcus equi' },
            { value: '77', label: 'Rickettsia rickettsii' },
            { value: '78', label: 'Rickettsia species' },
            { value: '79', label: 'Salmonella species' },
            { value: '80', label: 'Serratia species' },
            { value: '81', label: 'Shigella dysenteriae' },
            { value: '82', label: 'Shigella species' },
            { value: '83', label: 'Staphylococci, coagulase negative' },
            { value: '84', label: 'Staphylococcus aureus' },
            { value: '85', label: 'Stenotrophomonas maltophilia' },
            { value: '86', label: 'Streptobacillus moniliformis' },
            { value: '87', label: 'Streptococcus pneumoniae' },
            { value: '88', label: 'Streptococcus pyogenes (Group A)' },
            { value: '89', label: 'Streptococcus species' },
            { value: '90', label: 'Treponema pallidum (syphilis)' },
            { value: '91', label: 'Tropheryma whipplei' },
            { value: '92', label: 'Vancomycin Resistant Enterococcus species' },
            { value: '93', label: 'Vancomycin Resistant Staphylococcus aureus' },
            { value: '94', label: 'Vibrio cholerae' },
            { value: '95', label: 'Vibrio species (noncholera)' },
            { value: '96', label: 'Yersinia pestis' },
            { value: '97', label: 'Yersinia species (non-plague)' },
            { value: '98', label: 'Absidia' },
            { value: '99', label: 'Aspergillus' },
            { value: '100', label: 'Basidiobolomycosis' },
            { value: '101', label: 'Blastomyces dermatitidis' },
            { value: '102', label: 'Candida albicans' },
            { value: '103', label: 'Candida glabrata' },
            { value: '104', label: 'Candida guilliermondii' },
            { value: '105', label: 'Candida krusei' },
            { value: '106', label: 'Candida lusitaniae' },
            { value: '107', label: 'Candida parapsilosis' },
            { value: '108', label: 'Candida species' },
            { value: '109', label: 'Candida tropicalis' },
            { value: '110', label: 'Chromomycosis' },
            { value: '111', label: 'Coccidioides immitis' },
            { value: '112', label: 'Cryptococcus neoformans' },
            { value: '113', label: 'Cunninghamella' },
            { value: '114', label: 'Dermatophytes' },
            { value: '115', label: 'Fusarium' },
            { value: '116', label: 'Histoplasma capsulatum' },
            { value: '117', label: 'Mucor' },
            { value: '118', label: 'Mycetoma' },
            { value: '119', label: 'Pneumocystis carinii' },
            { value: '120', label: 'Pneumocystis jirovecii' },
            { value: '121', label: 'Pseudallescheria boydii' },
            { value: '122', label: 'Rhizomucor' },
            { value: '123', label: 'Rhizopus' },
            { value: '124', label: 'Saksanea' },
            { value: '125', label: 'Sporothrix schenckii' },
            { value: '126', label: 'Zygomycetes' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '49': {
        id: '46',
        name: 'ef_infcomp2',
        label: ' Infection Complication 2',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '50': {
        id: '47',
        name: 'ef_infsrc2',
        label: 'Source Of Infectious Complication 2',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.43',
            name: 'ef_infcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Lungs' },
            { value: '2', label: 'Gastro-Intestinal' },
            { value: '3', label: 'Genito-Urinary' },
            { value: '4', label: 'Skin And Soft Tissue' },
            { value: '5', label: 'Central Nervous System' },
            { value: '6', label: 'Osteoarticular And Bone' },
            { value: '7', label: 'Cardiac' },
            { value: '8', label: 'Bloodstream' },
            { value: '999', label: 'Not Known' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '51': {
        id: '48',
        name: 'ef_infpath2',
        label: 'Causative Pathogen 2',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.43',
            name: 'ef_infcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Acinetobacter baumannii' },
            { value: '2', label: 'Actinomyces' },
            { value: '3', label: 'Aeromonas' },
            { value: '4', label: 'Bacillus anthracis' },
            { value: '5', label: 'Bacillus species' },
            { value: '6', label: 'Bacteroides fragilis' },
            { value: '7', label: 'Bacteroides species' },
            { value: '8', label: 'Bartonella species' },
            { value: '9', label: 'Bordetella species' },
            { value: '10', label: 'Borrelia burgdorferi' },
            { value: '11', label: 'Borrelia species' },
            { value: '12', label: 'Brucella Species' },
            { value: '13', label: 'Burkholderia cepacia' },
            { value: '14', label: 'Burkholderia mallei' },
            { value: '15', label: 'Burkholderia pseudomallei' },
            { value: '16', label: 'Campylobacter and related species' },
            { value: '17', label: 'Campylobacter jejuni' },
            { value: '18', label: 'Capnocytophaga canimorsus' },
            { value: '19', label: 'Chlamydia trachomatis' },
            { value: '20', label: 'Chlamydophila pneumoniae' },
            { value: '21', label: 'Chlamydophila psittaci' },
            { value: '22', label: 'Citrobacter species' },
            { value: '23', label: 'Clostridium botulinum' },
            { value: '24', label: 'Clostridium difficile' },
            { value: '25', label: 'Clostridium species' },
            { value: '26', label: 'Clostridium tetani (Tetanus)' },
            { value: '27', label: 'Corynebacterium diphtheriae' },
            { value: '28', label: 'Coxiella burnetii' },
            { value: '29', label: 'Ehrlichia species' },
            { value: '30', label: 'Eikenella corrodens' },
            { value: '31', label: 'Enterobacter species' },
            { value: '32', label: 'Enterococcus' },
            { value: '33', label: 'Erysipelothrix rhusiopathiae' },
            { value: '34', label: 'Escherichia coli' },
            { value: '35', label: 'Francisella tularensis' },
            { value: '36', label: 'Haemophilus ducreyi (Chancroid)' },
            { value: '37', label: 'Haemophilus influenzae' },
            { value: '38', label: 'Helicobacter cinaedi and related species' },
            { value: '39', label: 'Helicobacter pylori' },
            { value: '40', label: 'Klebsiella granulomatis (Antibiotic Guide)' },
            { value: '41', label: 'Klebsiella species' },
            { value: '42', label: 'ESBL Klebsiella pneumoniae' },
            { value: '43', label: 'Lactobacillus' },
            { value: '44', label: 'Legionella pneumophila' },
            { value: '45', label: 'Legionella species' },
            { value: '46', label: 'Leptospira interrogans' },
            { value: '47', label: 'Listeria monocytogenes' },
            { value: '48', label: 'Lymphogranuloma venereum (LGV)' },
            { value: '49', label: 'Methicillin Resistant Staphylococcus aureus' },
            { value: '50', label: 'Moraxella catarrhalis' },
            { value: '51', label: 'Morganella' },
            { value: '52', label: 'Mycobacterium abscessus' },
            { value: '53', label: 'Mycobacterium avium-complex (MAC, MAI, non-HIV)' },
            { value: '54', label: 'Mycobacterium chelonae' },
            { value: '55', label: 'Mycobacterium fortuitum' },
            { value: '56', label: 'Mycobacterium gordonae' },
            { value: '57', label: 'Mycobacterium kansasii' },
            { value: '58', label: 'Mycobacterium leprae' },
            { value: '59', label: 'Mycobacterium marinum' },
            { value: '60', label: 'Mycobacterium scrofulaceum' },
            { value: '61', label: 'Mycobacterium tuberculosis' },
            { value: '62', label: 'Mycobacterium ulcerans' },
            { value: '63', label: 'Mycobacterium xenopi' },
            { value: '64', label: 'Mycoplasma pneumoniae (Antibiotic Guide)' },
            { value: '65', label: 'Neisseria gonorrhoeae' },
            { value: '66', label: 'Neisseria meningitidis' },
            { value: '67', label: 'Nocardia' },
            { value: '68', label: 'Other atypical mycobacteria' },
            { value: '69', label: 'Pasteurella multocida' },
            { value: '70', label: 'Peptostreptococcus/Peptococcus' },
            { value: '71', label: 'Plesiomonas' },
            { value: '72', label: 'Propionibacterium species' },
            { value: '73', label: 'Proteus species' },
            { value: '74', label: 'Providencia' },
            { value: '75', label: 'Pseudomonas aeruginosa' },
            { value: '76', label: 'Rhodococcus equi' },
            { value: '77', label: 'Rickettsia rickettsii' },
            { value: '78', label: 'Rickettsia species' },
            { value: '79', label: 'Salmonella species' },
            { value: '80', label: 'Serratia species' },
            { value: '81', label: 'Shigella dysenteriae' },
            { value: '82', label: 'Shigella species' },
            { value: '83', label: 'Staphylococci, coagulase negative' },
            { value: '84', label: 'Staphylococcus aureus' },
            { value: '85', label: 'Stenotrophomonas maltophilia' },
            { value: '86', label: 'Streptobacillus moniliformis' },
            { value: '87', label: 'Streptococcus pneumoniae' },
            { value: '88', label: 'Streptococcus pyogenes (Group A)' },
            { value: '89', label: 'Streptococcus species' },
            { value: '90', label: 'Treponema pallidum (syphilis)' },
            { value: '91', label: 'Tropheryma whipplei' },
            { value: '92', label: 'Vancomycin Resistant Enterococcus species' },
            { value: '93', label: 'Vancomycin Resistant Staphylococcus aureus' },
            { value: '94', label: 'Vibrio cholerae' },
            { value: '95', label: 'Vibrio species (noncholera)' },
            { value: '96', label: 'Yersinia pestis' },
            { value: '97', label: 'Yersinia species (non-plague)' },
            { value: '98', label: 'Absidia' },
            { value: '99', label: 'Aspergillus' },
            { value: '100', label: 'Basidiobolomycosis' },
            { value: '101', label: 'Blastomyces dermatitidis' },
            { value: '102', label: 'Candida albicans' },
            { value: '103', label: 'Candida glabrata' },
            { value: '104', label: 'Candida guilliermondii' },
            { value: '105', label: 'Candida krusei' },
            { value: '106', label: 'Candida lusitaniae' },
            { value: '107', label: 'Candida parapsilosis' },
            { value: '108', label: 'Candida species' },
            { value: '109', label: 'Candida tropicalis' },
            { value: '110', label: 'Chromomycosis' },
            { value: '111', label: 'Coccidioides immitis' },
            { value: '112', label: 'Cryptococcus neoformans' },
            { value: '113', label: 'Cunninghamella' },
            { value: '114', label: 'Dermatophytes' },
            { value: '115', label: 'Fusarium' },
            { value: '116', label: 'Histoplasma capsulatum' },
            { value: '117', label: 'Mucor' },
            { value: '118', label: 'Mycetoma' },
            { value: '119', label: 'Pneumocystis carinii' },
            { value: '120', label: 'Pneumocystis jirovecii' },
            { value: '121', label: 'Pseudallescheria boydii' },
            { value: '122', label: 'Rhizomucor' },
            { value: '123', label: 'Rhizopus' },
            { value: '124', label: 'Saksanea' },
            { value: '125', label: 'Sporothrix schenckii' },
            { value: '126', label: 'Zygomycetes' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }

        ]
      },
      '52': {
        id: '49',
        name: 'ef_infcomp3',
        label: ' Infection Complication 3',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '53': {
        id: '50',
        name: 'ef_infsrc3',
        label: 'Source Of Infectious Complication 3',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.43',
            name: 'ef_infcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Lungs' },
            { value: '2', label: 'Gastro-Intestinal' },
            { value: '3', label: 'Genito-Urinary' },
            { value: '4', label: 'Skin And Soft Tissue' },
            { value: '5', label: 'Central Nervous System' },
            { value: '6', label: 'Osteoarticular And Bone' },
            { value: '7', label: 'Cardiac' },
            { value: '8', label: 'Bloodstream' },
            { value: '999', label: 'Not Known' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '54': {
        id: '51',
        name: 'ef_infpath3',
        label: 'Causative Pathogen 3',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.43',
            name: 'ef_infcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Acinetobacter baumannii' },
            { value: '2', label: 'Actinomyces' },
            { value: '3', label: 'Aeromonas' },
            { value: '4', label: 'Bacillus anthracis' },
            { value: '5', label: 'Bacillus species' },
            { value: '6', label: 'Bacteroides fragilis' },
            { value: '7', label: 'Bacteroides species' },
            { value: '8', label: 'Bartonella species' },
            { value: '9', label: 'Bordetella species' },
            { value: '10', label: 'Borrelia burgdorferi' },
            { value: '11', label: 'Borrelia species' },
            { value: '12', label: 'Brucella Species' },
            { value: '13', label: 'Burkholderia cepacia' },
            { value: '14', label: 'Burkholderia mallei' },
            { value: '15', label: 'Burkholderia pseudomallei' },
            { value: '16', label: 'Campylobacter and related species' },
            { value: '17', label: 'Campylobacter jejuni' },
            { value: '18', label: 'Capnocytophaga canimorsus' },
            { value: '19', label: 'Chlamydia trachomatis' },
            { value: '20', label: 'Chlamydophila pneumoniae' },
            { value: '21', label: 'Chlamydophila psittaci' },
            { value: '22', label: 'Citrobacter species' },
            { value: '23', label: 'Clostridium botulinum' },
            { value: '24', label: 'Clostridium difficile' },
            { value: '25', label: 'Clostridium species' },
            { value: '26', label: 'Clostridium tetani (Tetanus)' },
            { value: '27', label: 'Corynebacterium diphtheriae' },
            { value: '28', label: 'Coxiella burnetii' },
            { value: '29', label: 'Ehrlichia species' },
            { value: '30', label: 'Eikenella corrodens' },
            { value: '31', label: 'Enterobacter species' },
            { value: '32', label: 'Enterococcus' },
            { value: '33', label: 'Erysipelothrix rhusiopathiae' },
            { value: '34', label: 'Escherichia coli' },
            { value: '35', label: 'Francisella tularensis' },
            { value: '36', label: 'Haemophilus ducreyi (Chancroid)' },
            { value: '37', label: 'Haemophilus influenzae' },
            { value: '38', label: 'Helicobacter cinaedi and related species' },
            { value: '39', label: 'Helicobacter pylori' },
            { value: '40', label: 'Klebsiella granulomatis (Antibiotic Guide)' },
            { value: '41', label: 'Klebsiella species' },
            { value: '42', label: 'ESBL Klebsiella pneumoniae' },
            { value: '43', label: 'Lactobacillus' },
            { value: '44', label: 'Legionella pneumophila' },
            { value: '45', label: 'Legionella species' },
            { value: '46', label: 'Leptospira interrogans' },
            { value: '47', label: 'Listeria monocytogenes' },
            { value: '48', label: 'Lymphogranuloma venereum (LGV)' },
            { value: '49', label: 'Methicillin Resistant Staphylococcus aureus' },
            { value: '50', label: 'Moraxella catarrhalis' },
            { value: '51', label: 'Morganella' },
            { value: '52', label: 'Mycobacterium abscessus' },
            { value: '53', label: 'Mycobacterium avium-complex (MAC, MAI, non-HIV)' },
            { value: '54', label: 'Mycobacterium chelonae' },
            { value: '55', label: 'Mycobacterium fortuitum' },
            { value: '56', label: 'Mycobacterium gordonae' },
            { value: '57', label: 'Mycobacterium kansasii' },
            { value: '58', label: 'Mycobacterium leprae' },
            { value: '59', label: 'Mycobacterium marinum' },
            { value: '60', label: 'Mycobacterium scrofulaceum' },
            { value: '61', label: 'Mycobacterium tuberculosis' },
            { value: '62', label: 'Mycobacterium ulcerans' },
            { value: '63', label: 'Mycobacterium xenopi' },
            { value: '64', label: 'Mycoplasma pneumoniae (Antibiotic Guide)' },
            { value: '65', label: 'Neisseria gonorrhoeae' },
            { value: '66', label: 'Neisseria meningitidis' },
            { value: '67', label: 'Nocardia' },
            { value: '68', label: 'Other atypical mycobacteria' },
            { value: '69', label: 'Pasteurella multocida' },
            { value: '70', label: 'Peptostreptococcus/Peptococcus' },
            { value: '71', label: 'Plesiomonas' },
            { value: '72', label: 'Propionibacterium species' },
            { value: '73', label: 'Proteus species' },
            { value: '74', label: 'Providencia' },
            { value: '75', label: 'Pseudomonas aeruginosa' },
            { value: '76', label: 'Rhodococcus equi' },
            { value: '77', label: 'Rickettsia rickettsii' },
            { value: '78', label: 'Rickettsia species' },
            { value: '79', label: 'Salmonella species' },
            { value: '80', label: 'Serratia species' },
            { value: '81', label: 'Shigella dysenteriae' },
            { value: '82', label: 'Shigella species' },
            { value: '83', label: 'Staphylococci, coagulase negative' },
            { value: '84', label: 'Staphylococcus aureus' },
            { value: '85', label: 'Stenotrophomonas maltophilia' },
            { value: '86', label: 'Streptobacillus moniliformis' },
            { value: '87', label: 'Streptococcus pneumoniae' },
            { value: '88', label: 'Streptococcus pyogenes (Group A)' },
            { value: '89', label: 'Streptococcus species' },
            { value: '90', label: 'Treponema pallidum (syphilis)' },
            { value: '91', label: 'Tropheryma whipplei' },
            { value: '92', label: 'Vancomycin Resistant Enterococcus species' },
            { value: '93', label: 'Vancomycin Resistant Staphylococcus aureus' },
            { value: '94', label: 'Vibrio cholerae' },
            { value: '95', label: 'Vibrio species (noncholera)' },
            { value: '96', label: 'Yersinia pestis' },
            { value: '97', label: 'Yersinia species (non-plague)' },
            { value: '98', label: 'Absidia' },
            { value: '99', label: 'Aspergillus' },
            { value: '100', label: 'Basidiobolomycosis' },
            { value: '101', label: 'Blastomyces dermatitidis' },
            { value: '102', label: 'Candida albicans' },
            { value: '103', label: 'Candida glabrata' },
            { value: '104', label: 'Candida guilliermondii' },
            { value: '105', label: 'Candida krusei' },
            { value: '106', label: 'Candida lusitaniae' },
            { value: '107', label: 'Candida parapsilosis' },
            { value: '108', label: 'Candida species' },
            { value: '109', label: 'Candida tropicalis' },
            { value: '110', label: 'Chromomycosis' },
            { value: '111', label: 'Coccidioides immitis' },
            { value: '112', label: 'Cryptococcus neoformans' },
            { value: '113', label: 'Cunninghamella' },
            { value: '114', label: 'Dermatophytes' },
            { value: '115', label: 'Fusarium' },
            { value: '116', label: 'Histoplasma capsulatum' },
            { value: '117', label: 'Mucor' },
            { value: '118', label: 'Mycetoma' },
            { value: '119', label: 'Pneumocystis carinii' },
            { value: '120', label: 'Pneumocystis jirovecii' },
            { value: '121', label: 'Pseudallescheria boydii' },
            { value: '122', label: 'Rhizomucor' },
            { value: '123', label: 'Rhizopus' },
            { value: '124', label: 'Saksanea' },
            { value: '125', label: 'Sporothrix schenckii' },
            { value: '126', label: 'Zygomycetes' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '55': {
        id: '52',
        name: 'ef_haemcomp1',
        label: 'Haemorrhagic Complication 1',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '56': {
        id: '53',
        name: 'ef_haemsrc1',
        label: 'Source Of Haemorrhagic Complication 1',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          setIfExists: [{
            id: '4.52',
            name: 'ef_haemcomp1',
            value: true
          }],
          options: [
            { value: '1', label: 'Site of ECMO cannulation' },
            { value: '2', label: 'Lungs' },
            { value: '3', label: 'Gastro-intestinal' },
            { value: '4', label: 'Genito-urinary' },
            { value: '5', label: 'Skin and soft tissue' },
            { value: '6', label: 'Central nervous system' },
            { value: '7', label: 'Osteoarticular and bone' },
            { value: '8', label: 'Cardiac' },
            { value: '999', label: 'Not Known' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },

          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '57': {
        id: '54',
        name: 'ef_haemcomp2',
        label: 'Haemorrhagic Complication 2',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: false,
          setIfExists: [{
            id: '4.52',
            name: 'ef_haemcomp1',
            value: true
          }],
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '58': {
        id: '55',
        name: 'ef_haemcomp2source',
        label: 'Source Of Haemorrhagic Complication 2',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          setIfExists: [{
            id: '4.54',
            name: 'ef_haemcomp2',
            value: true
          }],
          options: [
            { value: '1', label: 'Site of ECMO cannulation' },
            { value: '2', label: 'Lungs' },
            { value: '3', label: 'Gastro-intestinal' },
            { value: '4', label: 'Genito-urinary' },
            { value: '5', label: 'Skin and soft tissue' },
            { value: '6', label: 'Central nervous system' },
            { value: '7', label: 'Osteoarticular and bone' },
            { value: '8', label: 'Cardiac' },
            { value: '999', label: 'Not Known' }
          ]
        },
        predecessorHasValue: [
          {
            name: 'daily_invasive_prtrt',
            value: true
          },
          {
            name: 'daily_ecmo_prtrt',
            value: true,
            operators: FormDataDataQuestionOperators.OR
          }
        ]
      },
      '59': {
        id: '56',
        name: 'ef_othercomp',
        label: 'Other Complication (Please Describe)',
        type: FormDataDataQuestionTypes.TEXT,
      },
      '60': {
        id: '57',
        name: 'ef_ferritin',
        label: 'FERRITIN (ng/mL)',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 1000
          },
          setIfExists: [{
            id: '4.57a',
            name: 'ef_ferritin_yn',
            value: true
          }]
        }
      },
      '62': {
        id: '58',
        name: 'df_ddimer',
        label: 'D-dimer in the last 24 hours',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 15000
          },
          setIfExists: [{
            id: '4.58a',
            name: 'ef_ddimer_yn',
            value: true
          }]
        }
      },
      '63': {
        id: '58b',
        name: 'df_ddimer_u',
        label: 'D-dimer Unit of measurement',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          default: '1',
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'ng/mL' },
            { value: '2', label: 'mcg/mL' }
          ]
        }
      },
      '64': {
        id: '59',
        name: 'ef_cardtroptype',
        label: 'Type of Cardiac troponin (up to 2)',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {          
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Cardiac troponin T' },
            { value: '2', label: 'Cardiac troponin I' },
            { value: '3', label: 'High-sensitivity Troponin T' },
            { value: '4', label: 'High-sensitivity Troponin I' },
          ],
          setIfExists: [{
            id: '4.59a',
            name: 'ef_cardtrop_yn',
            value: '1'
          }]
        }
      },
      '65': {
        id: '59b',
        name: 'ef_cardtropt1',
        label: 'Troponin T',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 150
          }        
        }
      },
      '66': {
        id: '59c',
        name: 'ef_cardtropi',
        label: 'Troponin I',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 150
          },
          setIfExists: [{
            id: '4.59',
            name: 'df_cardtroptype',
            value: '2',
            operators: FormDataDataQuestionOperators.OR
          }]
        }
      },
      '67': {
        id: '59d',
        name: 'ef_cardtropt2',
        label: 'High-sensitivity Troponin T',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 150
          },
          setIfExists: [{
            id: '4.59',
            name: 'df_cardtroptype',
            value: '3',
            operators: FormDataDataQuestionOperators.OR
          }]
        }
      },
      '68': {
        id: '59e',
        name: 'ef_cardtrop_u',
        label: 'Troponin Unit of measurement',
        type: FormDataDataQuestionTypes.RADIO,
        data: {
          default: '1',
          options: [
            { value: '1', name: 'ng/mL' },
            { value: '2', name: 'ng/L' }
          ]
        }
      },
      '69': {
        id: '60',
        name: 'ef_cardbnp',
        label: 'BNP',
        type: FormDataDataQuestionTypes.NUMBER,
        data: {
          validation: {
            min: 0,
            max: 1000
          },
          setIfExists: [{
            id: '4.60a',
            name: 'ef_cardbnp_yn',
            value: '1'
          }]
        }
      },
      '70': {
        id: '61',
        name: 'eot_daily_complete',
        label: 'Is the daily observation complete',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          default: '1',
          type: FormDataDataQuestionDropdownSelect.SINGLE,
          options: [
            { value: '1', label: 'Incomplete' },
            { value: '2', label: 'Unverified' },
            { value: '3', label: 'Complete' }
          ]
        }
      }
    }
  },
  'eot_final': {
    id: '5',
    name: 'eot_final',
    header: 'EOT Final',
    questions: {
      '1': {
        id: '1',
        name: 'f_dateecmo',
        label: 'Date Of ECMO Discontinuation',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          }
        }
      },
      '2': {
        id: '2',
        name: 'f_dateimv',
        label: ' Date Of Invasive Mechanical Ventilation Discontinuation',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DDY'
          }
        }
      },
      '3': {
        id: '3',
        name: 'f_dateicu',
        label: 'Date Of ICU Discharge',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          }
        }
      },
      '4': {
        id: '4',
        name: 'f_datehosp',
        label: 'Date Of Hospital Discharge',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          }
        }
      },
      '5': {
        id: '5',
        name: 'f_datedeath',
        label: 'Date Of Death',
        type: FormDataDataQuestionTypes.DATE,
        data: {
          default: date.toISOString().slice(0, 10),
          validation: {
            min: '2019-12-14',
            format: 'YYYY-MM-DD'
          },
          setIfExists: [{
            id: '5.5a',
            name: 'f_datedeath_yn',
            value: true
          }]
        }
      },
      '6': {
        id: '6',
        name: 'f_deathsite',
        label: 'Site of death',
        type: FormDataDataQuestionTypes.RADIO,
        predecessors: ['f_datedeath'], //
        data: {
          validation: {
            mustExist: true
          },
          default: '1',
          options: [
            { name: 'ICU', value: '1' },
            { name: 'Hospital', value: '2' },
            { name: 'Outside Hospital', value: '3' }
          ]
        }
      },
      '7': {
        id: '7',
        name: 'f_cod',
        label: 'Main cause of death',
        type: FormDataDataQuestionTypes.DROPDOWN,
        data: {
          default: '2',
          type: FormDataDataQuestionDropdownSelect.MULTI,
          options: [
            { value: '1', label: 'Multi-Organ Failure' },
            { value: '2', label: 'Respiratory Failure' },
            { value: '3', label: 'Cardiac Failure' },
            { value: '4', label: 'Liver Failure' },
            { value: '5', label: 'Cardio-vascular accident' },
            { value: '6', label: 'Septic shock' },
            { value: '7', label: 'Haemorrhagic shock' },
            { value: '99', label: 'Other' }
          ]
        }
      },
      '8': {
        id: '7s',
        name: 'f_codspec',
        label: 'Other cause of death, please specify',
        type: FormDataDataQuestionTypes.TEXT,
        data: {
          setIfExists: [{
            id: '5.7',
            name: 'f_cod',
            value: '99'
          }]
        }
      },
      '9': {
        id: '8',
        name: 'f_alive28',
        label: 'Alive At 28 Days post ICU admission?',
        type: FormDataDataQuestionTypes.YESNO,
        data: {
          default: true
        }
      },
      '10': {
        id: '9',
        name: 'f_notes',
        label: 'Final Assessment Notes',
        type: FormDataDataQuestionTypes.TEXT,
      },
      '11': {
        id: '10',
        name: 'f_cutan_yn',
        label: 'At any time post-ICU admission and until ICU discharge, did the patient present new cutaneous manifestations?',
        type: FormDataDataQuestionTypes.RADIO,
        data: {
          validation: {
            mustExist: true
          },
          default: '1',
          options: [
            { name: 'Yes', value: '1' },
            { name: 'No', value: '0' },
            { name: 'Not available', value: '3' }
          ]
        }
      },
      '12': {
        id: '10a',
        name: 'f_cutantype',
        label: 'Type of cutaneous manifestations (up to 3)',
        comments: ['Select maximum of 3'],
        type: FormDataDataQuestionTypes.DROPDOWN,
        predecessors: ['f_cutan_yn'],
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          validation: {
            max: 3
          },
          options: [
            { value: '1', label: 'Bullae' },
            { value: '2', label: 'Macules' },
            { value: '3', label: 'Nodules' },
            { value: '4', label: 'Papules' },
            { value: '5', label: 'Plaques' },
            { value: '6', label: 'Purpura' },
            { value: '7', label: 'Pustules' },
            { value: '9', label: 'Rash' },
            { value: '10', label: 'Scale' },
            { value: '11', label: 'Urticaria' },
            { value: '12', label: 'Vesicles' },
            { value: '13', label: 'Other' },
          ]
        }
      },
      '13': {
        id: '10o',
        name: 'f_cutanspec',
        label: 'Other, please specify',
        type: FormDataDataQuestionTypes.TEXT
      },
      '14': {
        id: '10b',
        name: 'f_cutanreg',
        label: 'Involved regions (up to 3)',
        comments: ['Select maximum of 3'],
        type: FormDataDataQuestionTypes.DROPDOWN,
        predecessors: ['f_cutan_yn'],
        data: {
          type: FormDataDataQuestionDropdownSelect.MULTI,
          validation: {
            max: 3
          },
          options: [
            { value: '1', label: 'Face' },
            { value: '2', label: 'Trunk' },
            { value: '3', label: 'Upper limbs' },
            { value: '4', label: 'Hands' },
            { value: '5', label: 'Lower limbs' },
            { value: '6', label: 'Feet' },
          ]
        }
      }
    }
  }
}

export function formRenderDataReducer(
  formRenderData: FormRenderData = initialState,
  action: FormRenderDataActionTypes
): FormRenderData {
  switch (action.type) {
    case FormRenderDataActions.NO_OP:
      return formRenderData;
      break;

    // clear any user specific state
    case UserActions.SIGNOUT_SUCCESS:
      return {
        ...initialState
      };

    default:
      return formRenderData;
  }
}
