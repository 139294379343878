/* eslint-disable prettier/prettier */
/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

'use strict';

import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { getSubjectIdsSuccessAction } from 'Actions/creator/ui';
import { getSubjectSuccessAction, putSubjectSuccessAction } from 'Actions/creator/formData';
import { UIActions } from 'Actions/definition/ui';
import { FormDataActions } from 'Actions/definition/formData';

import awsConfig from 'Config/aws';

// --- support method

function filterEpic(
    action$,
    state$,
    filterName,
    bodyFunction,
    responseFunction,
    url,
    httpMethod,
    parametersFunction,
    staticParameters) {
    return action$.pipe(
        ofType(filterName),
        debounceTime(250),
        mergeMap(action => {
            const queryString = parametersFunction(action, staticParameters);
            return ajax({
                method: httpMethod,
                url: `${url}${queryString}`,
                headers: {
                    'Authorization': state$.value?.user?.signInUserSession?.accessToken?.jwtToken
                },
                body: bodyFunction(action)
            }).pipe(map(response => responseFunction(action, response))
            )
        }
        )
    )
}

function queryStringFromParams(staticParameters) {
    let queryString = Object.entries(staticParameters).length > 0 ? '?' : '';

    for (const [key, value] of Object.entries(staticParameters)) {
        queryString = queryString.length > 1 ? `${queryString}&${key}=${value}` : `${queryString}${key}=${value}`;
    }

    return queryString;
}

// -- these methods implement the retrieval of a list of subject IDs
//    from APIGW

function getSubjectIdsParametersFunction(action, staticParameters) {
    let queryString = queryStringFromParams(staticParameters);

    queryString = `${queryString}&InstitutionId=${action.institutionId}`;

    return queryString;
}

// this callback is used to populate request body data when needed
function getSubjectIdsBody(action) {
    return '';
}

// unpack the request response data & forward via success action
function getSubjectIdsResponse(action, response) {
    const subjectIds = response.response.Items.map(item => item.SubjectId);
    return getSubjectIdsSuccessAction(subjectIds);
}

/*
 * For UIActions that involve AJAX calls (both PUT & GET), the actions
 * are split into initiate/respond/forward. That is:
 * 
 * GET_{ACTION_NAME} <- this starts AJAX call
 * ... time passes ...
 * in our response handler (above) we unpack the response
 * into either
 * 
 * -> (GET_{ACTION_NAME}_SUCCESS | GET_{ACTION_NAME}_FAILURE)
 * 
 * synthetic actions. These are then used by a reducers to take the
 * action payload (if there is one) and modify the state
 */

export const getSubjectIds = (action$, state$) => {
    return filterEpic(
        action$,
        state$,
        UIActions.GET_SUBJECT_IDS,
        getSubjectIdsBody,
        getSubjectIdsResponse,
        `${awsConfig.api.endPoint}`,
        'GET',
        getSubjectIdsParametersFunction,
        { ProjectionExpression: 'SubjectId' }
    );
}

// -- these methods implement the retrieval of a single subject

// this callback is used to populate request body data when needed
function getSubjectBody(action) {
    return '';
}

// unpack the request response data & forward via success action
function getSubjectResponse(action, response) {
    return getSubjectSuccessAction(response.response?.Items[0]);
}

function getSubjectParametersFunction(action, staticParameters) {
    return `?InstitutionId=${action.institutionId}&SubjectId=${action.subjectId}`;
}

export const getSubject = (action$, state$) => {
    return filterEpic(
        action$,
        state$,
        FormDataActions.GET_SUBJECT,
        getSubjectBody,
        getSubjectResponse,
        `${awsConfig.api.endPoint}`,
        'GET',
        getSubjectParametersFunction,
        {}
    );
}

// -- these methods implement the saving of a single subject

// this callback is used to populate request body data when needed
function putSubjectBody(action) {
    const result = JSON.stringify(action.subject, (key, value) => {
        if (value === "" || value === null || value === undefined) {
            return undefined;
        }
        return value;
    });   
    
    return result;
}

// unpack the request response data & forward via success action
function putSubjectResponse(action, response) {
    return putSubjectSuccessAction(action.subject);
}

function putSubjectParametersFunction(action, staticParameters) {
    return '';
}

export const putSubject = (action$, state$) => {
    return filterEpic(
        action$,
        state$,
        FormDataActions.PUT_SUBJECT,
        putSubjectBody,
        putSubjectResponse,
        `${awsConfig.api.endPoint}`,
        'PUT',
        putSubjectParametersFunction,
        {}
    );
}
